.organigrama {
  .organi {
    background-color: #e6e7ea;
    padding: rem-calc(40 0);
    @media #{$medium-up} {
      //margin-top: rem-calc(60);
    }
    
    .org-chart {
      background-color: #fff;
      text-align: center;
      border: 1px solid $primary-color;
      .org {
        padding: rem-calc(0 10);
      }
      h2 {
        @include light;
        text-transform: uppercase;
        margin: rem-calc(26 0 8);
        color: $primary-color;
        font-size: rem-calc(18);
        @media #{$large-up} {
          font-size: rem-calc(20);
        }
      }
      h3 {
        text-transform: none;
        margin-bottom: 0;
        @include bold;
        font-size: rem-calc(16);
        color: $primary-color;
        @media #{$large-up} {
          font-size: rem-calc(18);
        }
        .first-alc & {
          font-size: rem-calc(20);
        }
      }
      h4 {
        text-transform: none;
        color: $primary-color;
        @include semibold;
        margin-top: rem-calc(19);
        font-size: rem-calc(16);
        @media #{$large-up} {
          font-size: rem-calc(18);
        }
        .first-alc & {
          font-size: rem-calc(20);
          text-transform: uppercase;
          margin-top: rem-calc(25);
        }
      }
      p.junta {
        font-size: rem-calc(14);
        @include light;
        @media #{$large-up} {
          font-size: rem-calc(16);
        }
        .first-alc & {
          font-size: rem-calc(18);
          @include medium;
          margin-bottom: rem-calc(20);
        }
      }
      .partit {
        width: 100%;
        position: absolute;
        bottom: 0;
        left:0;
        p {
          font-size: rem-calc(18);
          @include medium;
          text-transform: uppercase;
          margin-bottom: 0;
          padding: rem-calc(10 0 8);
        }
        &.erc {
          background-color: #ffb232;
          p {
            color: #000;
          }
        }
        &.cup {
          background-color: #feed20;
          p {
            color: #000;
          }
        }
        &.pdcat {
          background-color: #03428b;
          p {
            color: #fff;
          }
        }
        &.icv {
          background-color: #69a943;
          p {
            color: #fff;
          }
        }
      }
    }
    .pis-partit {
      margin-top: rem-calc(20);
      background-color: #fff;
      h5 {
        @include light;
        text-align: center;
        padding-top: rem-calc(10);
        color: $primary-color;
        font-size: rem-calc(20);
        border-bottom: 1px solid $primary-color;
        padding-bottom: rem-calc(8);
        background-color: #F2F3F4;
      }
    }
    .first-alc {
      margin-bottom: rem-calc(30) !important;
      .partit {
        position: relative;
      }
    }
    .membre {
      &:before {
        @media #{$medium-up} {
          content: "";
          display: block;
          width: 75%;
          height: 30px;
          border-top: 4px solid $primary-color;
          margin: 0 auto;
          top: 0px;
        }
      }
      &:after {
        @media #{$medium-up} {
          content: "";
          display: block;
          position: absolute;
          height: 33px;
          width: 0px;
          border-left: 4px solid $primary-color;
          margin-left: 47.5%;
          top: -30px;
          //z-index: -1;
        }
        @media #{$large-up} {
          margin-left: 48.38%;
        }
      }
      .org-chart {
        position: relative;
        padding-bottom: rem-calc(50);
        &:after {
          @media #{$medium-up} {
            content: "";
            position: absolute;
            display: block;
            width: 10px;
            height: 30px;
            border-left: 4px solid $primary-color;
            left: 50%;
            top: -30px;
          }
        }
      }
    }
    .no-membre {
      text-align: center;
      margin: auto;
      position: relative;
      margin-top: rem-calc(10);
      li {
        display: inline-table;
        float: none;
        .org-chart {
          //padding: rem-calc(0 10);
          border: 0;
          @media #{$medium-up} {
            min-height: rem-calc(200);
          }
          h2 {
            margin-top: rem-calc(10);
          }
        }
      }
      
    }
    .erc1 {
      &:before {
        content: 'ERC';
        background-color: #ffb232;
        display: block;
        color: #000;
        padding: rem-calc(10 0 8);
        position: absolute;
        width: 49.1%;
        z-index: 1;
        top: -25px;
        left: 25%;
        margin-left: 5px;
      }
    }
    .cup1 {
      &:before {
        content: 'CUP';
        background-color: #feed20;
        display: block;
        color: #000;
        padding: rem-calc(10 0 8);
        position: absolute;
        width: 49.1%;
        z-index: 1;
        top: -25px;
        left: 25%;
        margin-left: 5px;
      }
    }
    .pdecat1 {
      &:before {
        content: 'PDeCAT';
        background-color: #03428b;
        display: block;
        color: #fff;
        padding: rem-calc(10 0 8);
        position: absolute;
        width: 23.7%;
        z-index: 1;
        top: -25px;
        left: 37.7%;
        margin-left: 5px;
      }
    }
  }
}




