.section {
	//padding: vr(1) 0;
	box-sizing: border-box;
	.row {
		.column,
		.columns {
			>*:last-child:not(hr) {
				margin-bottom: 0;
			}
		}
	}
	@media #{$medium-up} {
		//padding: vr(1.5) 0;
	}
	.actualitat & {
		@media #{$medium-up} {
			padding: vr(2) 0;
		}
	}
	&--quick-links{
		h2{
			@include light;
			text-transform: uppercase;
		}
		a{
			outline: 0;
			border-bottom: solid 1px transparent;
			&:focus{
				border-color: $secondary-color;
			}
		}
	}
	&--img-servicios {
			position:relative;
			top: 30px;
		@media #{$medium-up} {
			background-image: url('img/img-servicios.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			height: 650px;
			top: -60px;
			z-index: -1;
			padding-top: rem-calc(150);
			}
		@media #{$large-up} {
				padding-top: rem-calc(200);
			}
		.cartera {
			background-color: rgba(35, 87, 121, 0.8);
			padding: rem-calc(25 25 15);
			text-align: center;
			@media #{$large-up} {
				padding: rem-calc(25 25 0);
			}
			h1 {
				color: #fff;
				text-transform: uppercase;
				@include bold;
				font-size: rem-calc(23);
				margin: rem-calc(0 0 10);
				@media #{$large-up} {
					font-size: rem-calc(25);
					margin: rem-calc(0 0 24);
				}
			}
			p {
				@include medium;
				font-size: rem-calc(15);
				line-height: 21px;
				color: #fff;
				@media #{$large-up} {
					font-size: rem-calc(16);
				}
			}
		}
		.aclar {
			text-align: center;
			background-color: $bg-color;
			padding: rem-calc(25 20);
			letter-spacing: 0;
			@media #{$medium-up} {
				background-color: rgba(255, 255, 255, 0.85);
			}
			@media #{$large-up} {
				padding: rem-calc(25 30);
			}
			h2 {
				font-size: rem-calc(20);
				color: #4a4a4a;
				line-height: rem-calc(23);
				@media #{$large-up} {
					font-size: rem-calc(25);
					line-height: rem-calc(28);
				}
			}
			h3 {
				text-transform: none;
				@include medium;
				font-size: rem-calc(18);
				margin: rem-calc(17 0 15);
				@media #{$large-up} {
					font-size: rem-calc(20);
				}
			}
			p {
				font-size: rem-calc(16);
				overflow-wrap: break-word;
				@include medium;
				@media #{$large-up} {
					font-size: rem-calc(18);
				}
				a {
					@include undolink;
				}
				&.telf {
					color: #4a4a4a;
					span {
						display: block;
						font-size: rem-calc(23);
						@include medium;

					}
				}
			}
		}
	}
	&--img-com {
			position:relative;
			//top: 30px;
			height: auto;
		@media #{$medium-up} {
			background-image: url('img/img-comunicacion.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			height: 500px;
			top: -60px;
			z-index: -1;
			padding-top: rem-calc(150);
			}
		@media #{$large-up} {
				padding-top: rem-calc(200);
			}
		.bg-com {
			background-color: rgba(35, 87, 121, 0.8);
			padding: rem-calc(25 25 15);
			text-align: center;
			height: auto;
			width: 100%;
			@media #{$medium-up} {
				position: absolute;
				bottom: 0;
			}
			@media #{$large-up} {
				padding: rem-calc(25 25 0);
			}
			h1 {
				color: #fff;
				text-transform: uppercase;
				@include bold;
				font-size: rem-calc(23);
				margin: rem-calc(0 0 10);
				@media #{$large-up} {
					font-size: rem-calc(35);
					margin: rem-calc(0 0 24);
				}
			}
			p {
				@include medium;
				font-size: rem-calc(15);
				line-height: 21px;
				color: #fff;
				padding-bottom: rem-calc(15);
				@media #{$large-up} {
					font-size: rem-calc(16);
				}
			}
		}
	}
	&--oferta {
		background-color: $bg-color;
		padding-bottom: rem-calc(50);
		.ress {
			border-bottom: 1px solid $primary-color;
			padding: rem-calc(50 0 18);
			display: none;
			@media #{$medium-up} {
					display: block;
				}
			ul {
				margin: 0;
			}
			li {
				display: inline-block;
				margin-bottom: 0;
				color: $primary-color;
				@include medium;
				font-size: rem-calc(16);
				svg {
					margin-right: rem-calc(10);
				}
				&:before {
					content: none;
				}
				&.first {
					padding-left: 0;
				}
			}
		}
		li {
				svg {
					margin-right: rem-calc(10);
				}
				&:before {
					content: none;
				}
				&.oberta {
					svg {
						fill: #76AC36;
					}
				}
				&.tancada {
					svg {
						fill: #d0091b;
					}
				}
				&.resolta {
					svg {
						fill: #006494;
					}
				}
				&.deserta {
					svg {
						fill: #000;
					}
				}
			}
		.ofertes {
			margin-top: rem-calc(30);
			a {
				@include undolink;
				div.box-of {
					background-color: #fff;
					padding: rem-calc(20);
					min-height: rem-calc(250);
					h2 {
						font-size: rem-calc(20);
						color: $primary-color;
						letter-spacing: 0px;
						@include semibold;
						line-height: normal;
						margin-bottom: rem-calc(50);
						.oferta-privada & {
							margin-bottom: rem-calc(10);
						}
					}
					p {
						font-size: rem-calc(16);
						@include semibold;
						margin-bottom: 0;
						&.data-termini {
							color: $secondary-color;
							@include bold;
						}
						&.estat {
							@include medium;
							margin-top: rem-calc(15);
						}
						&.num {
							@include light;
							span {
								display: block;
								@include semibold;
							}
						}
					}	
					.dades {
						position:absolute;
						bottom: 35px;
					}
				}
				&:hover {
					text-shadow: none;
					text-decoration: none;
					box-shadow: none;
					.box-of {
						background-color: rgba(255, 255, 255, 0.6);
					}
				}
			}
		}
	}
	
	&--actualitat {
		position: relative;
		h2 {
			@include light;
			font-size: rem-calc(25);
			color: $primary-color;
			border-bottom: 1px solid $primary-color;
			text-transform: uppercase;
			letter-spacing: 1.1px;
			padding-top: rem-calc(30);
			padding-bottom: rem-calc(5);
			@media #{$medium-up} {
				padding-top: rem-calc(50);
			}
		}
		time {
			font-size: rem-calc(14);
			@include light;
			letter-spacing: .6px;
		}
		h3 {
			@include bold;
			font-size: rem-calc(23);
			text-transform: none;
			line-height: normal;
			margin-bottom: rem-calc(10);
			a {
				@include bold;
				font-size: rem-calc(20);
				text-decoration: none;
				color: $primary-color;
				@include undolink;

			}
		}
		p {
			margin-bottom: rem-calc(10);
		}
		.info {
			color: $primary-color;
			@include bold;
			font-size: rem-calc(16);
			@include undolink;
		}
		.nots {
			padding-bottom: rem-calc(50);
			img {
				display: block;
			}
			div:first-child {
				//padding: rem-calc(15 15);
				
				margin-bottom: rem-calc(15);
				@media #{$medium-up} {
					
					margin-bottom: 0;
				}

			}

		}
		.ac-nots {
			border-bottom: 1px solid $primary-color;
			position: relative;
		}
		.mesnot {
			background-color: $primary-color;
			border-radius: 50px;
			color: #fff;
			font-size: rem-calc(35);
			@include medium;
			position: absolute;
			padding: rem-calc(0 15);
			left: 45%;
    		bottom: -25px;
    		@include undolink;
    		&:hover {
    			text-decoration: none;
    		}
		}
		.mesag {
			background-color: $primary-color;
			border-radius: 50px;
			color: #fff;
			font-size: rem-calc(35);
			@include medium;
			position: absolute;
			padding: rem-calc(0 15);
			left: 45%;
    		bottom: -25px;
    		@include undolink;
    		&:hover {
    			text-decoration: none;
    		}
		}
		.agenda {
			background-color: rgba(56, 66, 84, 0.2);
					span.dia {
						font-size: rem-calc(18);
						@include bold;
						letter-spacing: .9px;
						display: block;
						text-align: left;
						color: $primary-color;
						@media #{$medium-up} {
							font-size: rem-calc(20);
						}
						@media #{$large-up} {
							text-align: center;
						}
					}
					span.mes {
						display: block;
						letter-spacing: .9px;
						@include medium;
						font-size: rem-calc(16);
						margin-top: rem-calc(0);
						text-align: left;
						color: $primary-color;
						@media #{$medium-up} {
							font-size: rem-calc(16);
						}
						@media #{$large-up} {
							text-align: center;
						}
					}
					h3 {
						line-height: 20px;
						@media #{$medium-up} {
								line-height: 22px;
						}
						a {
							@include undolink;
							@include semibold;
							letter-spacing: .6px;
							font-size: rem-calc(18);
							color: $primary-color;
							@media #{$medium-up} {
								font-size: rem-calc(20);
							}

						}
					}
				p {
					font-size: rem-calc(16);
					@include light;
					color: $primary-color;
					letter-spacing: .77px;
					&.loc {
						&:before {
							content: '';
							background-image: url('img/ico-loc.svg');
							width: rem-calc(18);
							height: rem-calc(25);
							display: inline-block;
							margin-right: 10px;
						}
					}
					&.hora {
						&:before {
							content: '';
							background-image: url('img/ico-clock.svg');
							width: rem-calc(20);
							height: rem-calc(20);
							display: inline-block;
							margin-right: 10px;
						}
					}
				}
			.agend {
				padding-bottom: rem-calc(15);
				margin-bottom: rem-calc(15);
				@media #{$large-up} {
					padding-bottom: rem-calc(32);
					margin-bottom: rem-calc(28);
				}
			}

		}
	}
	&--coneix-img, &--not-img {
		
		
		@media #{$medium-up} {
			height: 350px;
			//background-image: url('img/img-coneix.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			position: relative;
			top: -57px;
			z-index: -10;
		}
		@media #{$large-up} {
			height: 500px;
		}
		.shape {
			@media #{$medium-up} {
				background-image: url('img/back-shape.svg'); 
				background-repeat: no-repeat;
				width: 682px;
      			height: 156px;
      			bottom: 0;
      			position: absolute;
      			z-index: -5;
      		}	
		}
		h1 {
					@include semibold;
					color: $primary-color;
					font-size: rem-calc(30);
					padding-bottom: 0;
					margin-top: rem-calc(30);
					text-align: center;
					z-index: 10;
					@media #{$medium-up} {
						margin: rem-calc(0);
						margin-left: 5%;
						color: #fff;
						text-align: left;
						font-size: rem-calc(35);
						padding-bottom: rem-calc(20);
						position: absolute;
    					bottom: 5px;
					}
					@media #{$large-up} {
						margin-left: 5%;
						
					}
					@media #{$xlarge-up} {
					margin-left: 35%;
						
					}
				}
	}
	&--interior-img {
		@media #{$medium-up} {
			height: 300px;
			//background-image: url('img/img-consistori.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
			position: relative;
		}
		@media #{$large-up} {
			height: 400px;
		}
		h1 {
			text-align: center;
			text-transform: uppercase;
			color: $primary-color;
			font-size: rem-calc(28);
			margin-top: rem-calc(20);
			margin-bottom: rem-calc(20)!important;

			@media #{$medium-up} {
				margin-top: rem-calc(230);
				text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
				font-size: rem-calc(30);
				text-align: right;
				color: #fff;
			}
			@media #{$large-up} {
				margin-top: rem-calc(320);
				font-size: rem-calc(35);
			}
		}

	}
	&--interior-img-no {
		.sub-caixes-4 & {
			background-color: #e6e7ea;
		}
		h1 {
			text-align: center;
			text-transform: uppercase;
			color: $primary-color;
			font-size: rem-calc(28);
			margin-bottom: rem-calc(15)!important;
			padding-top: rem-calc(15);
			margin-top:0!important;
			.sub-caixes-4 & {
				margin-top:0!important;
				margin-bottom: 0!important;
			}
			@media #{$medium-up} {
				margin-bottom: rem-calc(30)!important;
				padding-top: rem-calc(35);
				font-size: rem-calc(30);
			}
			@media #{$large-up} {
				font-size: rem-calc(35);
			}
		}
	}
	&--negocis-map {
		position: relative;
		@media #{$medium-up} {
			//background-image: url('img/img-maps.jpg');
			//background-repeat: no-repeat;
			//background-size: cover;
			//background-position: center center;
		}
		@media #{$medium-up} {
			.fitxa-negoci & {
				height: 600px;
			}
		}
		.map-negocis {
			height: 500px;
		}
		div#gmap {
		    width: 100%;
		    height: 500px;
		    text-align: center;
		    font-size: .75em;
		    line-height: 120%;
		    padding: 0;
		    overflow: hidden;
			top: -108px;
		}
		div#gmap-fitxa {
		    width: 100%;
		    height: 600px;
		    text-align: center;
		    font-size: .75em;
		    line-height: 120%;
		    padding: 0;
		    overflow: hidden;
		    top: 0px;
		    //z-index: -1;
		    position: absolute!important;
		}
		h1 {
			text-align: center;
			text-transform: uppercase;
			color: #fff;
			font-size: rem-calc(30);
			background-color: rgba(56, 66, 84, 0.62);
			margin-top: 0;
			height: auto;
			padding-top: rem-calc(15);
			z-index: 1;
    		position: relative;
			@media #{$small-only} {
				padding-bottom: rem-calc(15);
				margin-bottom: rem-calc(15);
			}
			@media #{$medium-up} {
				height: 72px;
			}
			@media #{$large-up} {
				font-size: rem-calc(35);
			}
		}
		.dades-negoci {
			@media #{$small-only} {
				border-bottom: 1px solid $primary-color;
				float:none;
				margin: 0 auto;
				margin-bottom: rem-calc(15);

			}
			padding: rem-calc(20 40);
			background-color: rgba(255, 255, 255, 0.85);
			text-align: center;
			max-width: rem-calc(380);
			float: right;
			@media #{$large-up} {
				font-size: rem-calc(35);
			}
			p {
				font-size: rem-calc(16);
				@include medium;
				margin-bottom: rem-calc(10);
				&.telf {
					font-size: rem-calc(18);
				}
			}
		}

	}
	&--not-img {
		background-image: url('img/img-not.jpg');
		height: 340px;
		h1 {
			margin-top: rem-calc(220);
		}
	}
	&--accord {
		margin-top: rem-calc(60);
		.basica & {
			margin-top: 0;
		}
		.negocis & {
			margin-top: -50px;
			.accordion {
				@media #{$small-only} {
            		margin-top: rem-calc(30);
          		}
			}
		}
		.entitats & {
			margin-top: 0;
		}
		a {
			@include undolink;
			h2 {
				display: inline-block;
				@include semibold;
				font-size: rem-calc(16);
				letter-spacing: .93px;
				color: $primary-color;
				max-width: 230px;
				padding-left: rem-calc(15);
				margin-bottom: 0;
				line-height: 19px;
				.basica & {
						max-width: 80%;
						font-size: rem-calc(18);
						@include bold;
						letter-spacing: 0px;
						padding-top: rem-calc(15);
						line-height: 22px;
						text-transform: none;
					}
				@media #{$large-up} {
					max-width: 210px;
					.basica & {
						max-width: 80%;
					}
				}
				@media #{$xlarge-up} {
					max-width: 230px;
					.basica & {
						max-width: 80%;
					}
				}
			}
			&:hover {
				//background-color: darken(#e6e7ea, 5%)!important;
			}
		}
		.accordion .accordion-navigation>a, .accordion dd>a { 
			background-color: #fff;
			border-bottom: 1px solid $primary-color;
			min-height: rem-calc(97);
			.basica & {
				background-color: $bg-color;
				border-bottom: none;
				margin-bottom: rem-calc(15);
				min-height: rem-calc(84);
			}
			.negocis & {
				position: relative;
    			padding-top: 35px;
    			padding-bottom: 10px;
    			min-height: rem-calc(105);
				img {
					margin-bottom: 0 !important;
					position:absolute;
				}
				h2 {
					margin-left: rem-calc(45);
				}
			}
			&:hover {
				background-color: $bg-color;
				text-decoration: none;
			}
		}
		li {
			&:before {
				content: none;
			}
			span {
				display: inline-block;
				background-image: url('img/ico-plus.svg');
				background-repeat: no-repeat;
				width: 22px;
				height: 22px;
				float: right;
				margin-top: 15px;
			}
			&.active {
				span {
					background-image: url('img/ico-minus.svg');
					width: 16px;
					height: 2px;
					margin-top: 25px;
				}
			}
		}
		.accordion .accordion-navigation>.content.active, .accordion dd>.content.active {
			background-color: $bg-color;
			.basica & {
				background-color: #fff;
				padding-top: 0;
			}
			ul {
				margin: 0;
				li {
					margin-left: 0;
					border-bottom: 1px solid rgba(56, 66, 84, 0.47);
					&:before {
						content: none;
					}
					p {
						display: inline-block;
						max-width: 240px;
						font-size: rem-calc(15);
						color: $primary-color;
						margin: rem-calc(8 0 10);
						@media #{$large-up} {
							max-width: 215px;
						}
						@media #{$xlarge-up} {
							max-width: 240px;
						}
					}
					.opc {
						display: inline-block;
						float:right;
						margin: rem-calc(8 0 10);
						a {
							margin-right: rem-calc(3);
							&:hover {
								opacity: .6;
							}
						}
					}
					&:last-child {
						border-bottom: none;
					}
					a {
						&:hover {
							//background-color: transparent!important;
						}
					}
				}
				&.box-list {
					li {
					  border-bottom: 2px solid $primary-color !important;
					  &:before {
					      content: '';
					      background-image: url('img/ico-fletxa.svg');
					      position: absolute;
					      display: block;
					      width: 29px;
					      height: 20px;
					      top: 75%;
					      left: 80%;
					      background-color: transparent;
					    }
					}
				}
			}
		}
	}
	&--box-img{
		a {
			@include undolink;
			&:hover {
				text-decoration: none;
			}
		}
		.boximg {
			margin: rem-calc(0 5 5);
			position: relative; 

			@media #{$medium-up} {
				margin: rem-calc(0 5 5);
			}
			@media #{$large-up} {
				margin: rem-calc(0 16 15);
			}
			a {
				img {
					margin-bottom: 0;
				}
			}
		}
		.caption {
			background-color: rgba(52, 192, 159, 0.85);
			height: auto;
			width: 100%;
			position: absolute;
			bottom: 0;
			padding: rem-calc(18);
			h2 {
				@include bold;
				font-size: rem-calc(14);
				letter-spacing: .6px;
				color: #fff;
				margin-bottom: 0;
				@media #{$medium-up} {
					font-size: rem-calc(16);
					letter-spacing: .6px;
					text-transform: uppercase;
				}
				@media #{$large-up} {
					font-size: rem-calc(18);
				}
				@media #{$large-up} {
					font-size: rem-calc(20);
				}
			}
		}
	}
	&--box-ico {
		.boxico {
			border: 2px solid $primary-color;
			padding: rem-calc(15);
			border-radius: 0px 25px 0px 25px;
			margin: rem-calc(5 0);
			position: relative;
			@media #{$medium-up} {
				padding: rem-calc(25);
				margin: rem-calc(10);
			}
			@media #{$large-up} {
				padding: rem-calc(35);
				margin: rem-calc(15);
			}
		a {
			@include undolink;
			height: auto;
			display: block;
			h2 {
				@include medium;
				font-size: rem-calc(16);
				color: $primary-color;
				text-transform: none;
				letter-spacing: 1px;
				line-height: 20px;
				padding-top: rem-calc(5);
				@media #{$medium-up} {
					font-size: rem-calc(20);
					letter-spacing: 1px;
					line-height: 23px;
					padding-top: rem-calc(18);
				}
				@media #{$large-up} {
					font-size: rem-calc(23);
					line-height: 25px;
				}
			}
			svg {
				fill: $primary-color;
			}
			.icomes {
				position: absolute;
				bottom: 20px;
				right: 20px;
			}
			&:hover {
				text-decoration: none;
				
			}
		}
		&:hover {
				background-color: $secondary-color;
				border: 2px solid $secondary-color;	
				svg {
					fill: #fff;
				}
				h2 {
					color: #fff;
				}		
			}
		}

	}
	&--consistori {
		@media #{$medium-up} {
			padding: rem-calc(55 0);
		}
		.img-alc {
			position: relative;
			height: auto;
			img {
				margin-bottom: 0;
				display: block;
				margin: 0 auto;
			}
			@media #{$large-up} {
				height: 314px;
			}
		}
		.alc {
			margin-bottom: rem-calc(25)!important;
		}
		.caption-dades {
			background-color: rgba(56, 66, 84, 0.86);
			text-align: center;
			width: 100%;
			position: absolute;
			bottom: 0;
			height: 86px;
			h2 {
				@include light;
				color: #fff;
				font-size: rem-calc(18);
				text-transform: uppercase;
				letter-spacing: 1.5px;
				margin: rem-calc(15 0 0);
				@media #{$medium-up} {
					font-size: rem-calc(20);
				}
			}
			h3 {
				text-transform: none;
				color: #fff;
				font-size: rem-calc(18);
				letter-spacing: 0;
				@include bold;
				@media #{$medium-up} {
					font-size: rem-calc(20);
				}
			}
		}
		.dades {
			border: 1px solid $primary-color;
			text-align: center;
			padding: rem-calc(22 0);
			height: rem-calc(195);
			p {
				margin-bottom: rem-calc(15);
			}
			.grup {
				@include bold;
				font-size: rem-calc(16);
				color: $primary-color;
				@media #{$medium-up} {
					font-size: rem-calc(18);
				}
				span {
					@include medium;
				}
			}
			.area {
				text-transform: uppercase;
				font-size: rem-calc(18);
				@include bold;
				color: $primary-color;
				@media #{$medium-up} {
					font-size: rem-calc(20);
				}
			}
			svg {
				margin-top: rem-calc(20);
			}
			a {
				@include undolink;
				display: block;
				font-size: rem-calc(16);
				@media #{$medium-up} {
					font-size: rem-calc(18);
				}
			}
		}
		.cv {
			background-color: $primary-color;
			padding: rem-calc(10 0);
			a {
				@include undolink;
				color: #fff;
				font-size: rem-calc(15);
				@include light;
				text-align: center;
				margin-bottom: 0;
				display: block;
				&:before {
					@media #{$medium-up} {
						content: '';
						background-image: url('img/ico-down.svg');
						background-repeat: no-repeat;
						display: inline-block;
						width: 24px;
						height: 25px;
						margin-right: rem-calc(20);
					}
				}
				&:hover {
					text-decoration: underline;
				} 
			}
		}
		.regidor {
			margin-bottom: 30px;
			img {
				display: none;
				@media #{$medium-up} {
					float:left;
					margin-right: rem-calc(20);
					display: block;
					position: relative;
					z-index: 1;
				}
			}
			.dades-reg {
				border: 1px solid $primary-color;
				padding: rem-calc(20);
				height: 215px;
				text-align: center;
				@media #{$medium-up} {
					text-align: left;
				}
				h2 {
					@include light;
					color: $primary-color;
					font-size: rem-calc(18);
					text-transform: uppercase;
					letter-spacing: 1.5px;
					margin-bottom: rem-calc(5);
					@media #{$medium-up} {
						font-size: rem-calc(20);
					}
				}
				h3 {
					text-transform: none;
					color: $primary-color;
					font-size: rem-calc(16);
					letter-spacing: 0;
					@include bold;
					margin-bottom: rem-calc(5);
					@media #{$medium-up} {
						font-size: rem-calc(18);
					}
				}
				p {
					margin-bottom: rem-calc(5);
				}
				.grup {
					@include bold;
					font-size: rem-calc(16);
					color: $primary-color;
					span {
						@include medium;
					}
				}
				.area {
					@include bold;
					font-size: rem-calc(16);
					color: $primary-color;
				}
				a {
					@include undolink;
					font-size: rem-calc(14);
					@include medium;
					@media #{$large-up} {
						font-size: rem-calc(12);
					}
					@media #{$xlarge-up} {
						font-size: rem-calc(14);
					}
				}
			}
			.cv {
				padding: 0;
				height: 50px;
				position: relative;
				a {
					text-align: center;
					display: block;
					line-height: 16px;
					padding-top: 10px;
					@media #{$medium-up} {
						text-align: left;
						padding-top: 5px;
					}
					&:before {
						position: relative;
						top: 5px;
					}
				}
			}
		}
	}

	&--bg {
		background-color: #e9eef1;
		text-align: center;
		padding: rem-calc(60 0);
		margin-top: rem-calc(60);
		.innovacion & {
			margin-top: rem-calc(30);
		}
		h2 {
			@include light;
			color: $primary-color;
			letter-spacing: 2.6px;
			font-size: rem-calc(30);
			text-transform: uppercase;
		}
		p {
			color: $primary-color;
			font-size: rem-calc(18);
			line-height: 23px;
			@include medium;
		}

		.itemas {
			li {
				&:before {
						content: none !important;

				}
			}
		}
	}
	&--docs {
		padding: rem-calc(40 0);
		h2 {
			color: $primary-color;
			@include light;
			font-size: rem-calc(30);
			letter-spacing: 2.6px;
			text-transform: uppercase;
		}
		
		.box-docs {
			  margin-left: 0;
			  li {
			  	margin-bottom: rem-calc(15);
			    a {
			      @include undolink;
			      color: $primary-color;
			      display: block;
			      @include medium;
			      font-size: rem-calc(18);
			      @media #{$large-up} {
			      	font-size: rem-calc(20);
			      }
			      div {
				    border: 1px solid $primary-color;
				    padding: rem-calc(20);
				    position: relative;
				    margin-right: rem-calc(15);
				    min-height: rem-calc(160);
				    &:before {
				      content: '';
				      background-image: url('img/ico-mes.svg');
				      position: absolute;
				      display: block;
				      width: 22px;
				      height: 21px;
				      top: 80%;
				      right: 15px;
				      background-color: transparent;
				    }
			       }
			      h2 {
					color: $primary-color;
					@include light;
					font-size: rem-calc(20);
					letter-spacing: 1px;
					.docus & {
						text-transform: none;
						letter-spacing: normal;
					}
					}
			      &:hover {
			      	text-decoration: none;
			      	div {
			      		background-color: $primary-color;
			      		&:before {
			      		  content: '';
					      background-image: url('img/ico-mes-blanc.svg');
					      position: absolute;
					      display: block;
					      width: 22px;
					      height: 21px;
					      top: 80%;
					      right: 15px;
					      background-color: transparent;
					    }
			      		h2 {
			      			color: #fff;
			      		}
			      	}
			      }
			    }
			  
			}
		}
		.docs-desc {
			li {
				background-color: #e6e7ea;
				padding: rem-calc(25 20 15);
				margin-bottom: rem-calc(10);
				.sub-1-col & {
					background-color: #fff;
					border: 1px solid $primary-color;
				}
				a {
					&:hover {
						text-decoration: none;
					}
				}
				&:hover {
					background-color: darken(#e6e7ea,10%);
				}
			}
		}

	}
	
    &--text {
    	margin-top: rem-calc(10);
    	@media #{$medium-up} {
    		margin-top: rem-calc(40);
    	}
    	.esporga &, .organigrama & {
    		background-color: #e6e7ea;
    		margin-top: 0;
    	}
    	.organigrama & {
    		padding: rem-calc(30 0);
    	}
    	.acord & {
    		padding-bottom: rem-calc(30);
    	}
    	.negocis & {
    		margin-top: rem-calc(80);
    	}
    	.contacte & {
    		margin-top: rem-calc(-15);
    		background-color: #e6e7ea;
    		@media #{$medium-up} {
    			margin-top: rem-calc(-108);
    		}
    	}
    	h1 {
    		.telfs &, .oferta-fitxa &, .esporga & {
    			@include semibold;
    			font-size: rem-calc(30);
    			letter-spacing: 1.05px;
    			color: $primary-color;
    			margin-top: 0;
    			position: relative;
    			@media #{$large-up} {
    				font-size: rem-calc(35);
    			}
    			&:before {
    				@media #{$large-up} {
	    				content: '';
	    				background-image: url('img/ico-phone.svg');
	    				width: 40px;
	    				height: 39px;
	    				display: block;
	    				position: absolute;
	    				left: 90px;
	    			}
    			}
    		}
    		.oferta-fitxa &, .esporga & {
    			&:before {
    				content: none;
    			}
    		}
    		.esporga & {
    			padding-top: rem-calc(35);
    			font-size: rem-calc(30);
    		}
    		.entitats & {
    			text-align: left;
    			color: $primary-color;
    			margin-top: 0;
    		}
    	}
    	h2 {
    		text-align: center;
    		text-transform: uppercase;
    		color: $primary-color;
    		@include medium;
    		.basica &, #nocookies & {
    			text-align: left;
    			margin-top: rem-calc(20);
    		}
    	}
    	p {
    		font-size: rem-calc(16);
    		color: #4a4a4a;
    		line-height: 23px;
    		text-align: center;
    		.basica &, .entitats &, #nocookies & {
    			text-align: left;
    			line-height: normal;
    		}
    		.esporga & {
    			color: $primary-color;
    			@include light;
    		}
    		@media #{$large-up} {
    			font-size: rem-calc(18);
    			line-height: 27px;
    			.basica &, .oferta-fitxa &, .esporga &, #nocookies & {
	    			font-size: rem-calc(16);
	    			line-height: rem-calc(23);
	    		}
    		}
    		a {
    			font-size: rem-calc(16);
    			@media #{$medium-up} {
	    			font-size: rem-calc(18);
	    			.basica &, #nocookies & {
		    			font-size: rem-calc(16);
		    		}
	    		}
    		}
    	}
    	img {
    		display: block;
    		margin: 0 auto;
    		margin-bottom: rem-calc(20);
    	}
    	.dades-oferta {
    		border: 2px solid $primary-color;
    		padding: rem-calc(30);
    		margin-left: 0;
    		li {
    			display: block;
    			text-transform: uppercase;
    			@include medium;
    			border-bottom: 1px solid $primary-color;
    			font-size: rem-calc(14);
    			text-align: center;
    			padding: rem-calc(10 0);
    			@media #{$medium-up} {
    				margin-right: 16px;
    				padding: 0;
    				padding-left: 8px;
    				display: inline-block;
    				border-left: 1px solid $primary-color;
    				border-bottom: none;
    				text-align: left;

    			}
    			@media #{$large-up} {
    				margin-right: 50px;
    				padding-left: 20px;
    				font-size: rem-calc(16);
    			}
    			@media #{$xlarge-up} {
    				margin-right: 60px;
    				padding-left: 25px;
    			}
    			&.last {
    				border-bottom: none;
    			}
				svg {
					margin-right: rem-calc(10);
				}
				&:before {
					content: none;
				}
				&.oberta {
					svg {
						fill: #76AC36;
					}
				}
				&.tancada {
					svg {
						fill: #d0091b;
					}
				}
				&.resolta {
					svg {
						fill: #006494;
					}
				}
				&.deserta {
					svg {
						fill: #000;
					}
				}
				span {
					display: block;
					@include bold;
					text-align: center;
					text-transform: none;
					font-size: rem-calc(14);
					@media #{$medium-up} {
	    				text-align: left;
	    			}
					@media #{$large-up} {
	    				font-size: rem-calc(16);
	    			}
					&.data-term {
						color: $secondary-color;
					}
				}
				&.estat {
					text-align: center;
					border-left: none;
					svg {
						margin: 0 auto;
					}
					span {
						text-transform: uppercase;
						@include medium;
						text-align: center;
						max-width: rem-calc(100);
					}
				}
			}
    	}
    	.dades-entitats {
    		background-color: rgba(56,66,84,.13);
    		padding: rem-calc(30 20);
    		a {
    			@include undolink;
    			font-size: rem-calc(16);
    		}
    	}
    }
    &--observacions {
    	background-color: $bg-color;
    	padding: rem-calc(30 0 50);
    	margin-top: rem-calc(40);
    	h2 {
    		@include light;
    		font-size: rem-calc(22);
    		color: $primary-color;
    		text-transform: uppercase;
    		margin-bottom: rem-calc(15);
    	}
    	p {
    		font-size: rem-calc(16);
    		@include medium;
    	}
    }
    &--recursos {
    	padding: rem-calc(30 0 0);
    	h2 {
    		@include light;
    		font-size: rem-calc(22);
    		color: $primary-color;
    		text-transform: uppercase;
    		margin-bottom: rem-calc(15);
    		border-bottom: 1px solid $primary-color;
    		padding-bottom: rem-calc(15);
    	}
    	
    }
    &--dest {
    	background-color: $bg-color;
    	margin-top: rem-calc(40);
    	padding: rem-calc(60 0);
    	position: relative;
    	&:before {
    		content: '';
    		background-image: url('img/ico-cometes.svg');
    		background-repeat: no-repeat;
    		display: block;
    		position: absolute;
    		width: 209px;
    		height: 118px;
    		top: 0;
    		left: 15%;
    	}
    	p {
    		@include mediumitalic;
    		font-size: rem-calc(20);
    		line-height: 27px;
    		@media #{$medium-up} {
    			font-size: rem-calc(23);
    			line-height: 30px;
    		}
    		@media #{$large-up} {
    			font-size: rem-calc(26);
    			line-height: 38px;
    		}
    		a {
    			@include undolink;
    			color: $primary-color;
    			@include mediumitalic;
	    		font-size: rem-calc(20);
	    		line-height: 27px;
	    		text-decoration: underline;
	    		@media #{$medium-up} {
	    			font-size: rem-calc(23);
	    			line-height: 30px;
	    		}
	    		@media #{$large-up} {
	    			font-size: rem-calc(26);
	    			line-height: 38px;
	    		}
	    		&:hover {
	    			text-decoration: none;
	    		}
    		}
    	}
    }
    &--xxss {
    	margin-top: rem-calc(40);
    	h2 {
    		@include light;
    		font-size: rem-calc(23);
    		letter-spacing: 2.5px;
    		text-align: center;
    		border-bottom: 1px solid $primary-color;
    		color: $primary-color;
    		text-transform: uppercase;
    		padding-bottom: rem-calc(5);
    	}
    	.xarxes {
	        list-style: none;
	        text-align: center;
	        li {
	          display: inline-block;
	          padding: rem-calc(0 10);
	          &:before {
	          	content: none;
	          }
	          a {
	            @include undolink;
	            &:hover {
	            	opacity: .6;
	            }
	          }
        	}
      	}
    }
    &--gray {
		background-color: #f7f7f7;
    }
    &--but_seu {
    	background-color: $primary-color;
    	height: 600px;
    	text-align: center;
    	@media #{$medium-up} {
    		height: 530px;
    	}
    	@media #{$large-up} {
    			height: 474px;
    		}
    	svg {
    		margin-top: rem-calc(30);
    	}
    	h2 {
    			text-transform: uppercase;
    			color: #fff;
    			font-size: rem-calc(25);
    			letter-spacing: .75px;
    			@include semibold;
    			padding-top: rem-calc(20);
    		}
    		p {
    			color: #fff;
    			font-size: rem-calc(16);
    			@include light;
    		}
    	.butlleti {
    		text-align: center;
    		color: #fff;
    		
    		form {
    			label {
    				display: none;
    			}
    			input[type="text"] {
    				border-radius: 0px;
    				border: none;
    			}
    			.checkbox-unit {
    				label {
    					display: block;
    					font-size: rem-calc(14);
    					@include light;
    					letter-spacing: 0px;
    					a {
    						@include undolink;
    						font-size: rem-calc(14);
    					}
    				}
    			}
    			input[type="checkbox"] {
				    -webkit-appearance: checkbox;
				    appearance: checkbox;
				  }
    		}
    	}
    	.seu {
    		background-image: url('img/back-ajcorbera.jpg');
    		background-repeat: no-repeat;
    		background-size: cover;
    		height: 450px;
    		@media #{$medium-up} {
	    		height: 530px;
	    	}
    		@media #{$large-up} {
    			height: 474px;
    		}
    		h2 {
    			margin-top: rem-calc(82);
    		}
    		h3 {
    			text-transform: none;
    			font-size: rem-calc(25);
    			@include light;
    			color: #fff;
    			@media #{$medium-up} {
		    		width: 350px;
    				margin: 0 auto;
		    	}
    		}
    		p {
    			margin-top: rem-calc(20);
    			margin-bottom: rem-calc(30);
    			@media #{$medium-up} {
		    		width: 350px;
    				margin: 0 auto;
    				margin-bottom: rem-calc(40);
		    	}
    		}
    	}
    }
    .home-item__column-wide {
		  @include grid-column(12, $collapse: true);
		  @media screen and (min-width: 55em) {
		    @include grid-column($columns: 6, $collapse: true);
		  }
		  @media #{$medium-up} {
		    @include grid-column($columns: 6, $collapse: true);
		  }
		  .row {
		  	@media #{$medium-up} {
		  		max-width: rem-calc(570);
		  	} 
		  }
		}
		.butlleti {
			.row {
				float:right;
			}
		}
		.seu {
			.row {
				float:left;
			}
		}
    &--int {
    	padding: rem-calc(70 0 30);
    	text-align: center;
    	.tramits & {
    		padding: rem-calc(0 0 30);
    	}
    	h2 {
    		text-transform: uppercase;
    		@include light;
    		font-size: rem-calc(30);
    		letter-spacing: .9px;
    		margin-top: rem-calc(30);
    		@media #{$medium-up} {
		  		margin-top: 0;
		  	}
    	}
    	a {
    		p {
    			margin-top: rem-calc(15);
    		}

    		&:hover {
    			text-decoration: none;
    		}
    	}
    }
    &--news, &--news-img-no{
    	background-color: $bg-color;
    	padding: rem-calc(20 0 30);
    	@media #{$medium-up} {
    		margin-top: -57px;
    	}
    	@media #{$large-up} {
		  		padding: rem-calc(40 0 60);
		 }
    	.noticies & {
    		@media #{$medium-up} {
    			padding-top: rem-calc(100);
    		}
    	}
    	.box-news {
    			background-color: #fff;
    			margin-top: rem-calc(20);
    			
    			text-align: center;
    			@media #{$medium-up} {
    				text-align: left;
    				margin-bottom: rem-calc(30)!important;
    			}
    			.noticies & {
    					margin-bottom: 0 !important;
    				}
    			li {
    				.noticies & {
    					margin-bottom: rem-calc(20);
    				}
    			}
    			time {
    				font-size: rem-calc(13);
    				@include light;
    				color: $primary-color;
    				padding: rem-calc(0 15);
    				display: block;
    				padding-top: rem-calc(15);
    				@media #{$large-up} {
    					font-size: rem-calc(14);
    					padding: rem-calc(0 20);
    					padding-top: rem-calc(15);
    				}
    			}
    			h3 {
    				margin-bottom: rem-calc(8);
    				margin-top: rem-calc(5);
	    				 a {
	    				 	@include undolink;
				    		@include bold;
				    		text-transform: none;
				    		max-width: 450px;
				    		text-align: left;
				    		display: inline-block;
				    		color: #000;
				    		font-size: rem-calc(16);
				    		line-height: 20px;
				    		letter-spacing: 0;
				    		padding: rem-calc(0 15);
				    		color: $primary-color;
				    		@media #{$small-only} {
				    			text-align: center;
				    			max-width: none;
				    			margin-top: rem-calc(15);
				    			margin-left: 0;
				    		}
				    		@media #{$large-up} {
							    font-size: rem-calc(20);
							  	line-height: rem-calc(23);
							    color: $primary-color;
							    padding: rem-calc(0 20);
							  }
				    	}
				    	&:hover {
				    		h3 {
				    			text-decoration: underline
				    		}
				    	}
			    	}
			    	p {
			    		font-size: rem-calc(14);
    					@include light;
    					color: $primary-color;
    					padding: rem-calc(0 15);
    					@media #{$large-up} {
    						padding: rem-calc(0 20);
    						font-size: rem-calc(16);
    					}
			    	}
			    	.mesinfo {
			    		@include undolink;
			    		display: block;
			    		color: $primary-color;
			    		padding: rem-calc(0 15 15);
    					@media #{$large-up} {
    						padding: rem-calc(0 20 20);
    					}
			    	}
			    	img {
			    		display: block;
			    		margin: 0 auto;
			    	}

    	}
	    
	}
	&--news-img-no{
    		margin-top: 0!important;
	}
	&--agenda {
		@media #{$small-only} {
			padding: rem-calc(20 0);
		}
		.agenda & {
			background-color: $bg-color;
			padding: rem-calc(40 0);
		}
		.prim-ag {
			background-color: $secondary-color;
			color: #fff;
			padding: rem-calc(20);
			margin-bottom: rem-calc(20) !important;
			
					span.dia {
						font-size: rem-calc(20);
						@include bold;
						letter-spacing: 0;
						display: block;
						text-align: center;
						padding-bottom: rem-calc(5);
						border-bottom: 1px solid #fff;
						margin-bottom: rem-calc(15) !important;
						display: block;
						@media #{$large-up} {
    							font-size: rem-calc(28);
    							border: none;
								padding-bottom: 0;
								margin-bottom: 0;
    						}
					}
					span.mes {
						display: block;
						letter-spacing: 0;
						@include bold;
						font-size: rem-calc(20);
						margin-top: rem-calc(-20);
						text-transform: none;
						text-align: center;
						padding-bottom: rem-calc(5);
						border-bottom: 1px solid #fff;
						margin-bottom: rem-calc(15) !important;
						display: block;
						@media #{$large-up} {
    							font-size: rem-calc(25);
    							border: none;
								padding-bottom: 0;
								margin-bottom: 0;
    						}
					}
				h3 {
						line-height: normal;
						text-transform: uppercase;
						a {
							@include undolink;
							@include light;
							letter-spacing: 0;
							font-size: rem-calc(25);
							color: #fff;
							@media #{$large-up} {
    							font-size: rem-calc(30);
    						}

						}
					}
				p {
					font-size: rem-calc(16);
					@include medium;
					color: #fff;
					letter-spacing: 0px;
					margin-bottom: rem-calc(8);
					&.loc {
						@include light;
						&:before {
							content: '';
							background-image: url('img/ico-loc-ag.svg');
							width: rem-calc(18);
							height: rem-calc(25);
							display: inline-block;
							margin-right: 10px;
						}
					}
					&.hora {
						@include light;
						&:before {
							content: '';
							background-image: url('img/ico-clock-ag.svg');
							width: rem-calc(23);
							height: rem-calc(23);
							display: inline-block;
							margin-right: 10px;
						}
					}
				}
				.mesinfo {
						@include undolink;
						margin-top: rem-calc(15);
						display: block;
			    		color: #fff;
			    		@include bold;
			    	}

		}
		.box-agenda {
			border: 2px solid $primary-color;
			padding: rem-calc(20);
			margin-top: rem-calc(20);
			@media #{$medium-up} {
    			margin-bottom: rem-calc(30)!important;
    		}
    		.agenda & {
    			background-color: #fff;
    			border: none;
    			//margin: 0 !important;
    		}
	
					span.dia {
						font-size: rem-calc(23);
						@include bold;
						letter-spacing: .9px;
						display: block;
						text-align: center;
						padding-bottom: rem-calc(5);
						border-bottom: 1px solid $primary-color;
						margin-bottom: rem-calc(15) !important;
						display: block;
						@media #{$large-up} {
							border: none;
							padding-bottom: 0;
							margin-bottom: 0;
						}
					}
					span.mes {
						display: block;
						letter-spacing: .9px;
						@include medium;
						font-size: rem-calc(25);
						margin-top: rem-calc(-10);
						text-align: center;
						padding-bottom: rem-calc(5);
						border-bottom: 1px solid $primary-color;
						margin-bottom: rem-calc(15) !important;
						display: block;
						@media #{$large-up} {
							border: none;
							padding-bottom: 0;
							margin-bottom: 0;
						}
					}
		
					h3 {
						line-height: 22px;
						text-transform: none;
						a {
							@include undolink;
							@include semibold;
							letter-spacing: .6px;
							font-size: rem-calc(18);
							color: $primary-color;
							@media #{$large-up} {
								font-size: rem-calc(20);
							}

						}
					}
				p {
					font-size: rem-calc(16);
					@include light;
					color: $primary-color;
					letter-spacing: .77px;
					margin-bottom: rem-calc(8);
					&.loc {
						&:before {
							content: '';
							background-image: url('img/ico-loc.svg');
							width: rem-calc(18);
							height: rem-calc(25);
							display: inline-block;
							margin-right: 10px;
						}
					}
					&.hora {
						&:before {
							content: '';
							background-image: url('img/ico-clock.svg');
							width: rem-calc(20);
							height: rem-calc(20);
							display: inline-block;
							margin-right: 10px;
						}
					}
				}
				.mesinfo {
						@include undolink;
						margin-top: rem-calc(15);
						display: block;
			    		color: $primary-color;
			    	}
		}
		.calendar {
			@media #{$small-only} {
				display: none;
			}
			table.cal {
				background-color: #fff;
				caption {
					margin-bottom: 0;
					padding: rem-calc(10 0);
					background: #fff;
					color: $primary-color;
					@include light;
					font-size: rem-calc(20);
					text-transform: uppercase;
					letter-spacing: 3.5px;
				}
				.arrow.previous {
					top: 16px;
					left: 20px;
					a {
						border-width: 5px 8px 5px 0;
					}
				}
				.arrow.next {
					top: 16px;
					right: 20px;
					a {
						border-width: 5px 0 5px 8px;
					}
				}
				tbody {
					td {
						padding: .8rem 1.5rem;
						line-height: 1.5;
						font-size: rem-calc(18);
						color: $primary-color;
						@include medium;
					}
					a {
						@include undolink;
						&:hover {
							text-decoration: none;
						}
					}
				}
				tr {
					&:nth-of-type(even) {
						background: #fff;
					}
				}
			}

		}

	}
	&--bans {
		@media #{$small-only} {
			padding: rem-calc(20 0);
		}
		background-color: rgba(248, 193, 0, 0.15);
		text-align: center;
		.bans {
			margin-top: rem-calc(20);
    		@media #{$medium-up} {
    			margin-bottom: rem-calc(30)!important;
    		}
		}
		a {
			@include undolink;
			&:hover {
				text-decoration: none;
			}
			
			p {
				font-size: rem-calc(18);
				letter-spacing: .77px;
				color: $primary-color;
				@include light;
			}
			.ico-down {
				margin: 0 auto;
				margin-top: rem-calc(20);
				padding: rem-calc(18 40);
				border-top: 2px solid $primary-color;
				width: 130px;
			}
		}

	}
	&--revista, &--whats {
		@media #{$small-only} {
			padding: rem-calc(20 0);
		}
		background-color: $secondary-color;
		padding-bottom: rem-calc(30);
		.revista & {
			background-color: $bg-color;
			padding-top: rem-calc(20);
		}
		h2 {
			color: #fff;
		}
		.revista {
			margin-top: rem-calc(20);
    		margin-bottom: rem-calc(30)!important;
			li {
				position: relative;
				.rev {
					width: rem-calc(200);
					height: rem-calc(294);
					margin: 0 auto;
					position: relative;
				}
				a {
					@include undolink;
					p.av {
						color: #fff;
						background-color: rgba(56, 66, 84, 0.85);
						position: absolute;
						font-size: rem-calc(15);
						padding: rem-calc(7);
						padding-left: rem-calc(15);
						bottom: 0;
						@include medium;
						width: 100%;
						/*&:before {
							content: '';
							background-image: url('img/ico-down.svg');
							display: inline-block;
							width: 24px;
							height: 25px;
							position: absolute;
							left: 20px;
							top: 15px;
						}*/
					}
					&:hover {
						text-decoration: none;
						text-shadow: none;
						p {
							background-color: $primary-color;
						}
					}
				}
			}
		}
	}
	 &--whats {
	 	svg {
	 		display: block;
	 		margin: 0 auto;
	 		margin-bottom: rem-calc(25);
	 	}
	 	h2 {
	 		margin-bottom: rem-calc(30);
	 	}
	 }
	&--butlleti {
		@media #{$small-only} {
			padding: rem-calc(20 0);
		}
    		text-align: center;
    		color: #fff;
    		background-color: $primary-color;
    		height: auto;
    		@media #{$medium-up} {
					height: 480px;
				}
    		h2, p {
				color: #fff;
			}
			h2 {
				margin-top: rem-calc(20);
			}
			.but {
				position: relative;
				@media #{$medium-up} {
					top: -64px;
				}
			}
    		form {
    			label {
    				display: none;
    			}
    			input[type="text"] {
    				border-radius: 0px;
    				border: none;
    			}
    			.checkbox-unit {
    				label {
    					display: block;
    					font-size: rem-calc(14);
    					@include light;
    					letter-spacing: 0px;
    					a {
    						@include undolink;
    						font-size: rem-calc(14);
    					}
    				}
    			}
    			input[type="checkbox"] {
				    -webkit-appearance: checkbox;
				    appearance: checkbox;
				  }
    		}

	}
	&--fitxa-news, &--fitxa-docs {
		text-align: left;
		h1 {
			font-size: rem-calc(30);
			letter-spacing: .9px;
			@include semibold;
			text-align: left;
			color: $primary-color;
			margin-bottom: rem-calc(12);
		}
		.data-not, .data-ag {
			color: $secondary-color;
			font-size: rem-calc(18);
			@include semibold;
			letter-spacing: .54px;
			margin-bottom: rem-calc(10);
		}
		.not-col {
			padding-bottom: rem-calc(40);
			.intro {
				font-size: rem-calc(20);
				margin-top: rem-calc(15);
				@include lightitalic;
			}
		}
	}
	&--fitxa-docs {
		h2.doc-rel {
			font-size: 1.375rem;
    		@include light;
    		letter-spacing: 0;
    		text-transform: uppercase;
    		border-bottom: 1px solid #384254;
    		padding-bottom: .625rem;
    		margin-bottom: 1.25rem;
    		color: $primary-color;
		}
		.docs {
			li {
				background-color: #e6e7ea;
				padding: rem-calc(15 15 3);
			}
		}
	}
	&--boxes{
		background-color: $bg-color;
		padding: rem-calc(50 0);
		@media #{$medium-up} {
			padding: rem-calc(100 0);
			.tramits & {
				padding: rem-calc(50 0);
			}
		}
		h2 {
    		text-transform: uppercase;
    		@include light;
    		font-size: rem-calc(30);
    		letter-spacing: .9px;
    		margin-top: rem-calc(0);
    		text-align: center;
    		@media #{$medium-up} {
		  		margin-top: 0;
		  	}
    	}
		.sub-caixes-4 &, .sub-caixes & {
			@media #{$medium-up} {
				margin-top: -57px;
			}
		}
		li {
			.sub-caixes-4 & {
				margin-bottom: rem-calc(15);
    		}
		.box{
			position: relative;
			text-align: center;
			background-color: $primary-color;
			color:#fff;
			min-height: 200px;
			overflow: hidden;
			.sub-caixes &, .sub-caixes-4 & {
    				padding:rem-calc(20);
    				display: flex;
  					justify-content: center;
  					flex-direction: column;
    			}
			svg, img {
				margin: 0 auto;
				margin-top: rem-calc(30);
			}
			h2{
				text-transform: uppercase;
				@include semibold;
    			color:#fff;
    			font-size: rem-calc(20);
    			letter-spacing: .6px;
    			margin: 0 auto;
    			line-height: 25px;
    			margin-top: rem-calc(20);
    			max-width: rem-calc(140);
    			.sub-caixes &, .sub-caixes-4 & {
    				max-width: 100%;
    				margin-top: 0;
    			}
    		}
    		p {
			    margin: 1em 2em 0;
			    padding: 1em;
			    border: 1px solid #fff;
			    opacity: 0;
			    color:#fff;
			    @include typi('small');
			    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
			    transition: opacity 0.35s, transform 0.35s;
			    -webkit-transform: translate3d(0,20px,0) scale(1.1);
			    transform: translate3d(0,40px,0) scale(1.1);
			}
			a{
			    display: block;
			    width: 100%;
			    height: 100%;
			    -webkit-backface-visibility: hidden;
	    		backface-visibility: hidden;
	    		@include undolink;
	    		color:#fff;
	    		.sub-caixes &, .sub-caixes-4 & {
    				height: auto;
    			}
	    		//background:rgba(#000,.5);
	    		&:hover{
	    			//background:rgba(#000,.5);
	    			text-decoration: none;
	    			h2{
	    				-webkit-transform: translate3d(0,0,0);
    					transform: translate3d(0,0,0);
	    			}
	    			p{
	    				opacity: 1;
					    -webkit-transform: translate3d(0,0,0) scale(1);
					    transform: translate3d(0,0,0) scale(1);
	    			}
	    		}
    		}

			}
			&:nth-child(1) {
				.box {
					background: $tertiary-color;
					.sub-caixes-4 & { 
					    	background-color: #fff;
						    border: 3px solid $tertiary-color;
						    a {
						    	h2 {
						    		color: $primary-color;
						    	}
						    }
					    }
	    			}
    			}
    		&:nth-child(2) {
    			.box {
					    background-color: $secondary-color;
					    .sub-caixes-4 & { 
					    	background-color: #fff;
						    border: 3px solid $secondary-color;
						    a {
						    	h2 {
						    		color: $primary-color;
						    	}
						    }
					    }
					}
    		}
    		&:nth-child(3) {
    			.box {
					    background-color: $primary-color;
					    .sub-caixes-4 & { 
					    	background-color: #fff;
						    border: 3px solid $primary-color;
						    a {
						    	h2 {
						    		color: $primary-color;
						    	}
						    }
					    }
				}
    		}
    		&:nth-child(4) {
    			.box {
					    background-color: #fff;
					    border: 3px solid $tertiary-color;
					    a {
					    	h2 {
					    		color: $primary-color;
					    	}
					    }
					    .sub-caixes-4 & { 
					    	 background-color: #fff;
							    border: 3px solid $tertiary-color;
							    a {
							    	h2 {
							    		color: $primary-color;
							    	}
							    }
					    }
				}
    		}
    		&:nth-child(5) {
    			.box {
					    background-color: #fff;
					    border: 3px solid $secondary-color;
					    a {
					    	h2 {
					    		color: $primary-color;
					    	}
					    }
					     .sub-caixes-4 & { 
					    	 background-color: #fff;
							    border: 3px solid $secondary-color;
							    a {
							    	h2 {
							    		color: $primary-color;
							    	}
							    }
					    }
				}
    		}
    		&:nth-child(6) {
    			.box {
					    background-color: #fff;
					    border: 3px solid $primary-color;
					    a {
					    	h2 {
					    		color: $primary-color;
					    		max-width: rem-calc(200);
					    	}
					    }
					    .sub-caixes-4 & { 
					    	 background-color: #fff;
							    border: 3px solid $primary-color;
							    a {
							    	h2 {
							    		color: $primary-color;
							    	}
							    }
					    }
				}
    		}
    		&:nth-child(7) {
				.box {
					background: $tertiary-color;
						 .sub-caixes-4 & { 
					    	 background-color: #fff;
							    border: 3px solid $tertiary-color;
							    a {
							    	h2 {
							    		color: $primary-color;
							    	}
							    }
					    }
	    			}
    			}
    		&:nth-child(8) {
    			.box {
					    background-color: $secondary-color;
					    .sub-caixes-4 & { 
					    	background-color: #fff;
						    border: 3px solid $secondary-color;
						    a {
						    	h2 {
						    		color: $primary-color;
						    	}
						    }
					    }
					}
    		}
    		&:nth-child(9) {
    			.box {
					    background-color: $primary-color;
					    .sub-caixes-4 & { 
					    	background-color: #fff;
						    border: 3px solid $primary-color;
						    a {
						    	h2 {
						    		color: $primary-color;
						    	}
						    }
					    }
				}
    		}
    		&:nth-child(10) {
    			.box {
					    background-color: #fff;
					    border: 3px solid $tertiary-color;
					    a {
					    	h2 {
					    		color: $primary-color;
					    	}
					    }
					    .sub-caixes-4 & { 
					    	 background-color: #fff;
							    border: 3px solid $tertiary-color;
							    a {
							    	h2 {
							    		color: $primary-color;
							    	}
							    }
					    }
				}
    		}
    		&:nth-child(11) {
    			.box {
					    background-color: #fff;
					    border: 3px solid $secondary-color;
					    a {
					    	h2 {
					    		color: $primary-color;
					    	}
					    }
					    .sub-caixes-4 & { 
					    	 background-color: #fff;
							    border: 3px solid $secondary-color;
							    a {
							    	h2 {
							    		color: $primary-color;
							    	}
							    }
					    }
				}
    		}
    		&:nth-child(12) {
    			.box {
					    background-color: #fff;
					    border: 3px solid $primary-color;
					    a {
					    	h2 {
					    		color: $primary-color;
					    		max-width: rem-calc(200);
					    	}
					    }
					    .sub-caixes-4 & { 
					    	 background-color: #fff;
							    border: 3px solid $primary-color;
							    a {
							    	h2 {
							    		color: $primary-color;
							    	}
							    }
					    }
				}
    		}
    		&:nth-child(13) {
				.box {
					background: $tertiary-color;
					.sub-caixes-4 & { 
					    	background-color: #fff;
						    border: 3px solid $tertiary-color;
						    a {
						    	h2 {
						    		color: $primary-color;
						    	}
						    }
					    }
	    			}
    			}
    		&:nth-child(14) {
    			.box {
					    background-color: $secondary-color;
					    .sub-caixes-4 & { 
					    	background-color: #fff;
						    border: 3px solid $secondary-color;
						    a {
						    	h2 {
						    		color: $primary-color;
						    	}
						    }
					    }
					}
    		}
    		&:nth-child(15) {
    			.box {
					    background-color: $primary-color;
					    .sub-caixes-4 & { 
					    	background-color: #fff;
						    border: 3px solid $primary-color;
						    a {
						    	h2 {
						    		color: $primary-color;
						    	}
						    }
					    }
				}
    		}
    		&:nth-child(16) {
    			.box {
					    background-color: #fff;
					    border: 3px solid $tertiary-color;
					    a {
					    	h2 {
					    		color: $primary-color;
					    	}
					    }
					    .sub-caixes-4 & { 
					    	 background-color: #fff;
							    border: 3px solid $tertiary-color;
							    a {
							    	h2 {
							    		color: $primary-color;
							    	}
							    }
					    }
				}
    		}
		}

		.box-simple{
			a{
				height: 175px;
				background-color: $primary-color;
				position: relative;
				//border: 1px solid $secondary-color;
				@include undolink;
				width: 100%;
				display: block;
				color: #fff;
				@include light;
				@include typi(big);
				@include single-transition();
				text-align: center;
				padding: rem-calc(20);
				h2 {
					color: #fff;
					@include medium;
					//@include typi(big);
					@include single-transition();
					text-transform: uppercase;
					font-size: rem-calc(18);
				}
				&:hover{
					text-decoration: none;
					background-color: darken($primary-color,10%);

				}
				.wrapper-image{
					display: block;
					top:50%;
					left:50%;
					transform:translate(-50%,-50%);
					position: absolute;
					text-transform: uppercase;
					line-height: 25px;
					.image{
						height: 65px;
						position: relative;
						display: block;
						margin-bottom:vr(.75);
						width:100%;
					}
					img{
						position: absolute;
						max-width:75px;
						top:50%;
						left:50%;
						margin: 0;
						transform:translate(-50%,-50%);
					}

				}
			}
			&.box-simple--b{
				li{

					a{
						height: auto;
						padding: vr(2);
						display: block;
						background-color: #5a804c;
						&:hover{
							background-color: darken(#5a804c,10%);
						}
					}
				}
				@media #{$medium-up} {
					&.medium-block-grid-2{
						li{
							padding:0 vr(2);
						}
					}
				}

			}
		}
	}
	&--boxes2{
		padding: rem-calc(50 0);
		background-color: $primary-color;
		.usuaris & {
			margin-top: -50px;
			padding-top: 0;
		}
		h2 {
			text-transform: uppercase;
			font-size: 1.875rem;
			@include light;
			text-align: center;
			color: #fff;
		}
		p {
			text-align: center;
		}
		ul {
			margin-top: rem-calc(40);
		}
		li {
		.box {
			position: relative;
			text-align: center;
			color:#fff;
			min-height: 193px;
			overflow: hidden;
			border: solid 1px #fff;
			h3{
				text-transform: uppercase;
				@include bold;
				padding:0 1em;
				margin-top: 5%;
    			-webkit-transition: -webkit-transform 0.35s;
    			transition: transform 0.35s;
   	 			-webkit-transform: translate3d(0,40px,0);
    			transform: translate3d(0,60px,0);
    			color:#fff;
    			@media #{$medium-up} {
					margin-top: 5%;
				}

    		}
    		a{
				position: absolute;
			    top: 0;
			    left: 0;
			    display: block;
			    width: 100%;
			    height: 100%;
			    -webkit-backface-visibility: hidden;
	    		backface-visibility: hidden;
	    		@include undolink;
	    		color:#fff;
	    		&:hover{
	    			background:rgba($primary-color,.1);
	    			text-decoration: none;
	    			h3 {
	    				color: #fff;
	    			}
	    		}
    		}
		}
		.item{
			position: relative;
			text-align: center;
			background-color: $primary-color;
			color:#fff;
			max-height: 193px;
			overflow: hidden;
			&:before {
				content: "";
			    display: block;
			    position: absolute;
			    top: 0;
			    bottom: 0;
			    left: 0;
			    right: 0;
			}
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				margin: 0;
			}
			h3{
				text-transform: uppercase;
				@include bold;
				padding:0 1em;
				margin-top: 7%;
    			-webkit-transition: -webkit-transform 0.35s;
    			transition: transform 0.35s;
   	 			-webkit-transform: translate3d(0,40px,0);
    			transform: translate3d(0,60px,0);
    			color:#fff;

    		}
			a{
				position: absolute;
			    top: 0;
			    left: 0;
			    display: block;
			    width: 100%;
			    height: 100%;
			    -webkit-backface-visibility: hidden;
	    		backface-visibility: hidden;
	    		@include undolink;
	    		color:#fff;
	    		//background:rgba(#000,.5);
	    		&:hover{
	    			background:rgba(#000,.5);
	    			text-decoration: none;
	    		}
    		}

			}
			&:nth-child(1) {
				.item {
	    			&:before {
					    background: rgba(0, 137, 194, 1);
				    	mix-blend-mode: multiply;
						}
	    			}
    			}
    		&:nth-child(2) {
    			.item {
	    			&:before {
					    background: rgba(245, 166, 35, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(3) {
    			.item {
	    			&:before {
					    background: rgba(196, 55, 64, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(4) {
    			.item {
	    			&:before {
					    background: rgba(162, 212, 109, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(5) {
				.item {
	    			&:before {
					    background: rgba(0, 82, 140, 1);
				    	mix-blend-mode: multiply;
						}
	    			}
    			}
    		&:nth-child(6) {
    			.item {
	    			&:before {
					    background: rgba(98, 171, 196, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(7) {
    			.item {
	    			&:before {
					    background: rgba(245, 166, 35, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(8) {
    			.item {
	    			&:before {
					    background: rgba(196, 55, 64, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(9) {
				.item {
	    			&:before {
					    background: rgba(162, 212, 109, 1);
				    	mix-blend-mode: multiply;
						}
	    			}
    			}
    		&:nth-child(10) {
    			.item {
	    			&:before {
					    background: rgba(0, 137, 194, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(11) {
    			.item {
	    			&:before {
					    background: rgba(245, 166, 35, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(12) {
    			.item {
	    			&:before {
					    background: rgba(196, 55, 64, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
		}
		.box-simple{
			a{
				height: 175px;
				background-color: $primary-color;
				position: relative;
				@include undolink;
				width: 100%;
				display: block;
				color: #fff;
				@include bold;
				@include typi(big);
				@include single-transition();
				text-align: center;
				&:hover{
					text-decoration: none;
					background-color: darken($primary-color,10%);

				}
				span{
					display: block;
					top:50%;
					left:50%;
					transform:translate(-50%,-50%);
					position: absolute;
					text-transform: uppercase;

					svg{
						display: block;
						margin: 0 auto vr(1);
					}

				}
			}
			&.box-simple--b{
				li{
					padding:0 vr(2);
					a{
						height: auto;
						padding: vr(2);
						display: block;
						background-color: #5a804c;
						&:hover{
							background-color: darken(#5a804c,10%);
						}
					}
				}

			}
		}
	}
	&--boxes3{
		padding: rem-calc(50 0);
		background-color: #e6e7ea;
		.sub-caixes-4 & {
			@media #{$medium-up} {
				margin-top: rem-calc(-57);
			}
		}
		h2 {
			text-transform: uppercase;
			font-size: rem-calc(35);
			@include light;
			text-align: center;
		}
		p {
			text-align: center;
		}
		li {
			margin-bottom: rem-calc(10);
		.item-noimg {
			position: relative;
			text-align: right;
			color:$primary-color;
			min-height: 150px;
			overflow: hidden;
			border: solid 1px $primary-color;
			padding:rem-calc(15);
			background-color: #fff;
			h3{
				text-transform: uppercase;
				@include bold;
    			-webkit-transition: -webkit-transform 0.35s;
    			transition: transform 0.35s;
   	 			-webkit-transform: translate3d(0,40px,0);
    			transform: translate3d(0,60px,0);
    			color:$primary-color;
    			//position: absolute;
    			//bottom: 15px;
    			padding-right: rem-calc(15);
    			padding-left: rem-calc(15);
    			

    		}
    		a{
				position: absolute;
			    top: 0;
			    left: 0;
			    display: block;
			    width: 100%;
			    height: 100%;
			    -webkit-backface-visibility: hidden;
	    		backface-visibility: hidden;
	    		@include undolink;
	    		color:#fff;
	    		&:hover{
	    			background:rgba($primary-color,1);
	    			text-decoration: none;
	    			h3 {
	    				color: #fff;
	    			}
	    		}
    		}
		}
		.item{
			position: relative;
			text-align: right;
			background-color: $primary-color;
			color:#fff;
			height: 150px;
			overflow: hidden;
			&:before {
				content: "";
			    display: block;
			    position: absolute;
			    top: 0;
			    bottom: 0;
			    left: 0;
			    right: 0;
			}
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				margin: 0;
			}
			h3{
				text-transform: uppercase;
				@include bold;
				padding:0 1em;
    			-webkit-transition: -webkit-transform 0.35s;
    			transition: transform 0.35s;
   	 			-webkit-transform: translate3d(0,40px,0);
    			transform: translate3d(0,60px,0);
    			color:#fff;
    			padding-right: rem-calc(15);
    			padding-left: rem-calc(15);

    		}
			a{
				position: absolute;
			    top: 0;
			    left: 0;
			    display: block;
			    width: 100%;
			    height: 100%;
			    -webkit-backface-visibility: hidden;
	    		backface-visibility: hidden;
	    		@include undolink;
	    		color:#fff;
	    		&:hover{
	    			background:rgba(#000,.5);
	    			text-decoration: none;
	    		}
    		}

			}
			&:nth-child(1) {
				.item {
	    			&:before {
					    background: rgba(248, 193, 0, 1);
				    	mix-blend-mode: multiply;
						}
	    			}
    			}
    		&:nth-child(2) {
    			.item {
	    			&:before {
					    background: rgba(52, 192, 159, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(3) {
    			.item {
	    			&:before {
					    background: rgba(56, 66, 84, .7);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(4) {
    			.item {
	    			&:before {
					    background: rgba(248, 193, 0, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(5) {
				.item {
	    			&:before {
					    background: rgba(52, 192, 159, 1);
				    	mix-blend-mode: multiply;
						}
	    			}
    			}
    		&:nth-child(6) {
    			.item {
	    			&:before {
					    background: rgba(56, 66, 84, .7);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(7) {
    			.item {
	    			&:before {
					    background: rgba(248, 193, 0, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(8) {
    			.item {
	    			&:before {
					    background: rgba(52, 192, 159, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(9) {
				.item {
	    			&:before {
					    background: rgba(56, 66, 84, .7);
				    	mix-blend-mode: multiply;
						}
	    			}
    			}
    		&:nth-child(10) {
    			.item {
	    			&:before {
					    background: rgba(248, 193, 0, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(11) {
    			.item {
	    			&:before {
					    background: rgba(52, 192, 159, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(12) {
    			.item {
	    			&:before {
					    background: rgba(56, 66, 84, .7);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(13) {
    			.item {
	    			&:before {
					    background: rgba(248, 193, 0, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(14) {
    			.item {
	    			&:before {
					    background: rgba(52, 192, 159, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(15) {
    			.item {
	    			&:before {
					    background: rgba(56, 66, 84, .7);
					    mix-blend-mode: multiply;
					}
				}
    		}
    		&:nth-child(16) {
    			.item {
	    			&:before {
					    background: rgba(248, 193, 0, 1);
					    mix-blend-mode: multiply;
					}
				}
    		}
		}
		.box-simple{
			a{
				height: 175px;
				background-color: $primary-color;
				position: relative;
				@include undolink;
				width: 100%;
				display: block;
				color: #fff;
				@include bold;
				@include typi(big);
				@include single-transition();
				text-align: center;
				&:hover{
					text-decoration: none;
					background-color: darken($primary-color,10%);

				}
				span{
					display: block;
					top:50%;
					left:50%;
					transform:translate(-50%,-50%);
					position: absolute;
					text-transform: uppercase;

					svg{
						display: block;
						margin: 0 auto vr(1);
					}

				}
			}
			&.box-simple--b{
				li{
					padding:0 vr(2);
					a{
						height: auto;
						padding: vr(2);
						display: block;
						background-color: #5a804c;
						&:hover{
							background-color: darken(#5a804c,10%);
						}
					}
				}

			}
		}
	}
	&--numbers{
		h2{
			text-align: center;
			@include light;
			text-transform: uppercase;
			margin-bottom: vr(2);
		}
		ul{
			li{
				@include medium;
				text-align: center;
				strong,b{
					@include light;
					font-size: rem-calc(30);
					display: block;
					@media #{$medium-up} {
						font-size: rem-calc(50);
					}
				}
				svg{
					display: block;

				}
			}
		}
		&.bg{
			background-color: $secondary-color;
			margin-bottom: vr(1.5);
			h2,li,p,a{
				color: #fff;

			}
		}
	}
	&--options{
		background-color: $bg-color;
		margin: vr(1) 0 vr(2);
		&.collapse{
			margin-bottom: 0;
		}
		.img{
		display: block;
		width:82px;
		height: 82px;
		margin-bottom: vr(.75);
		border-radius: 100%;
		background-color: #fff;
		position: relative;
			img{
			    display: block;
			    position: absolute;
			    top:50%;
				left: 50%;
				transform: translate(-50%,-50%);
			}
		}
		a{
			@include undolink;
			color: $secondary-color;
			text-decoration: underline;
		}
		p{
			margin-bottom: 0;
		}
		h3 {
			text-transform: none;
			a {
				text-transform: none;
				text-decoration: none;
				color: #000 !important;
			}
		}
		.multiple-centre & {
			margin: 0;
			padding:vr(1) 0;
			background-color: #fff;
			.img{
				background-color: $bg-color;
			}

		}
		@media #{$medium-up} {
			ul{
				li{
					padding-bottom: 0;
				}
			}
		}
		&.b{
			li{
				padding: 0 vr(2);
				margin-bottom: vr(2);
				@media #{$medium-up} {
					margin-bottom: 0;
				}
				h3{
					text-transform: none;
					margin-bottom: vr(.25);
				}
				p{
					@include typi('small');
				}
			}
		}

	}
	&--acnews{
		-webkit-font-smoothing: antialiased;
  		-moz-osx-font-smoothing: grayscale;
		padding-top: 0!important;
		h3{
			@include typi('big');
			text-transform: none;
			line-height: 1.25!important;
			margin-bottom: vr(.75);
			a{
				color: $body-font-color;
				@include undolink;
			}
		}
		.categoria{
			position: relative;
			margin-bottom: vr(.75);
			padding-bottom: vr(.75);
			&:after{
				content: '';
				width: 220px;
				height: 1px;
				background-color: #fff;
				position: absolute;
				bottom: 0;
				left: 50%;
				margin-left: -110px;
			}
			a{
				@include undolink;
				&:hover{
					text-decoration: none;
				}
			}
		}
		.data{
			@include bold;
			margin-bottom: vr(.75);
			@include typi('small');
		}
		.bg{
			background: $primary-color;
			color: #fff;
			margin: vr(1.5) 0;
			padding: vr(1.5) 0;
			p.descripcio{
				margin: 0;
			}
			h3{
				font-size: rem-calc(32);
				line-height: 1.2;
			}
			h3,p.categoria{
				a{
					color: #fff;
				}
			}
		}
		.altres-noticies{
			margin-bottom: vr(1.5);
			.categoria{
				position: relative;
				margin-bottom: vr(.5);
				padding-bottom: vr(.5);
				@include typi('small');
				&:after{
					background-color: $link-color;
					width: 100%;
					bottom: 0;
					left: 0;
					margin-left: 0;
				}
			}
			@media #{$small-only} {
				.medium-6:last-child{
					.categoria{
					 	margin-top: vr(2);
					 }
				}
			}
			.data{
				@include bold;
				margin-bottom: vr(.75);
				@include typi('small');
				color: $secondary-color;
			}
			img{
				margin-bottom: vr(.75)!important;
			}
		}
	}
	&--acagenda{
		background-color: $bg-color;
		padding-bottom: 0!important;
		-webkit-font-smoothing: antialiased;
  		-moz-osx-font-smoothing: grayscale;
  		.agenda & {
  			h1 {
  				margin-bottom: rem-calc(40) !important;
  			}
  		}
		h2{
			margin-bottom: vr(1.5)!important;
		}
		a{
			@include undolink;
		}
		h3{
			font-size: rem-calc(24);
			text-transform: none;
			line-height: 1.25;
			a{
				color: $body-font-color;
			}
		}
		p{
			margin-bottom: vr(.35);
		}
		.date-box{
			@include bold;
			font-size: rem-calc(34);
			color: $body-font-color;
			text-align: center;
			@media #{$medium-up} {
				text-align: left;
			}
			span{
				@include medium;
				font-size: rem-calc(22);
				text-transform: uppercase;
				padding-left: rem-calc(10);
				display: inline-block;
				@media #{$medium-up} {
					display: block;
					padding-left: 0;
				}
			}

		}
		.events-item {

    		padding: rem-calc(20);
    		border-bottom: 1px $primary-color solid;
    		@media #{$medium-up} {
    			min-height: 350px;
    			border-bottom: none;
    		}
    		@media #{$large-up} {
				padding: 0;
			}
    	}
    	.columns+.columns:last-child {
    		float: left;
    	}
	}
	&--acdestacats{
		padding-bottom: 0;
		.campanyes{
			margin: 0;
			margin-bottom: rem-calc(30);
			li{
				margin-bottom: rem-calc(20);
				padding-bottom: 0;
				a{
					display: block;
					background-color: $primary-color;
					margin-bottom: 0;
					color: #fff;
					@include undolink;
					padding: vr(1.5);
					@include single-transition();
					border: solid 2px #fff;
					@media #{$medium-up} {
					min-height: 330px;
					padding: vr(2);

					}
					&:hover{
						text-decoration: none;
						background-image: none;
						background-color: #fff;
						color: $body-font-color;
						border-color: $body-font-color;
						}
					}
					&:nth-child(1){
					a {
						background: #c05633;
						background-image: linear-gradient(-180deg, #FEC067 0%, #F77144 100%);
						&:hover{
						text-decoration: none;
						background-image: none;
						background-color: #fff;
						color: $body-font-color;
						border-color: $body-font-color;
						}
					}
					}
					&:nth-child(2){
						a {
							background: #C43740;
							&:hover{
							text-decoration: none;
							background-image: none;
							background-color: #fff;
							color: $body-font-color;
							border-color: $body-font-color;
							}
						}
					}
					&:nth-child(3){
						a {
						background: #60803f;
						background-image: linear-gradient(-180deg, #A2D46D 0%, #60803f 100%);
							&:hover{
							text-decoration: none;
							background-image: none;
							background-color: #fff;
							color: $body-font-color;
							border-color: $body-font-color;
							}
						}
					}
					&:nth-child(4){
						a {
							background: #414141;
							&:hover{
							text-decoration: none;
							background-image: none;
							background-color: #fff;
							color: $body-font-color;
							border-color: $body-font-color;
							}
						}
					}
					&:nth-child(5){
						a {
						background: #60803f;
						background-image: linear-gradient(-180deg, #A2D46D 0%, #60803f 100%);
							&:hover{
							text-decoration: none;
							background-image: none;
							background-color: #fff;
							color: $body-font-color;
							border-color: $body-font-color;
							}
						}
					}
					&:nth-child(6){
					a {
						background: #c05633;
						background-image: linear-gradient(-180deg, #FEC067 0%, #F77144 100%);
						&:hover{
						text-decoration: none;
						background-image: none;
						background-color: #fff;
						color: $body-font-color;
						border-color: $body-font-color;
						}
					}
					}
					&:nth-child(7){
						a {
							background: #C43740;
							&:hover{
							text-decoration: none;
							background-image: none;
							background-color: #fff;
							color: $body-font-color;
							border-color: $body-font-color;
							}
						}
					}
					&:nth-child(8){
						a {
						background: #60803f;
						background-image: linear-gradient(-180deg, #A2D46D 0%, #60803f 100%);
							&:hover{
							text-decoration: none;
							background-image: none;
							background-color: #fff;
							color: $body-font-color;
							border-color: $body-font-color;
							}
						}
					}
					&:nth-child(9){
						a {
							background: #414141;
							&:hover{
							text-decoration: none;
							background-image: none;
							background-color: #fff;
							color: $body-font-color;
							border-color: $body-font-color;
							}
						}
					}
					&:nth-child(10){
						a {
						background: #60803f;
						background-image: linear-gradient(-180deg, #A2D46D 0%, #60803f 100%);
							&:hover{
							text-decoration: none;
							background-image: none;
							background-color: #fff;
							color: $body-font-color;
							border-color: $body-font-color;
							}
						}
					}
					&:nth-child(11){
					a {
						background: #c05633;
						background-image: linear-gradient(-180deg, #FEC067 0%, #F77144 100%);
						&:hover{
						text-decoration: none;
						background-image: none;
						background-color: #fff;
						color: $body-font-color;
						border-color: $body-font-color;
						}
					}
					}
					&:nth-child(12){
						a {
							background: #C43740;
							&:hover{
							text-decoration: none;
							background-image: none;
							background-color: #fff;
							color: $body-font-color;
							border-color: $body-font-color;
							}
						}
					}
				}



			h3{
				font-size: rem-calc(24);
				text-transform:none;
			}
			p{
				margin-bottom: 0;
			}
		}

	}
	&--newsletter{
		margin-top: vr(1.5);
		background-color: #278194;
		color: #fff;
		a{
			@include undolink;
			color: #fff;
			text-decoration: underline;
		}
		.btn{
			background-color: #00678F;
			color: #fff;
			border-color:  #00678F;
			min-width: 150px;
			&:hover{
				background-color: #222;
			}
		}
		label.checkbox{
			text-transform: none!important;
			position: relative;
			padding-left: vr(1);

  			@include medium;
			input{
				position: absolute;
				left: 0;
				top: 2px;
				font-size: rem-calc(25);
			}
		}


		p{
			-webkit-font-smoothing: antialiased;
  			-moz-osx-font-smoothing: grayscale;
		}
	}
}


.users-list-links{
	@include undolist;
	//padding-left: vr(1.5);
	position: relative;
	@media #{$medium-up} {
		//min-height:294px;
	}
	&:before {
      content: '';
      display: block;
      margin-bottom: vr(.5);
      font-size: 1rem;
      position: absolute;
      left: 0;
      padding: 5px 5px 15px;
      top: 0;
    }
    
	li{
		font-size:rem-calc(15);
		padding-bottom: 0;
		margin-bottom: vr(.6);
		float: none;

		a{
			@include undolink;
			@include medium;
			position: relative;
			padding: 0 0 3px;
			max-width: 250px;
			display: inline-block;
			&:after{
				display: block;
				position: absolute;
			    top: 100%;
			    left: 0;
			    width: 100%;
			    height: 1px;
			    background: darken($primary-color,15%);
			    content: '';
			    opacity: 0;
			    -webkit-transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
			    //-moz-transition: height 0.3s, opacity 0.3s, -moz-transform 0.3s;
			    transition: height 0.3s, opacity 0.3s, transform 0.3s;
			    -webkit-transform: translateY(-5px);
			    //-moz-transform: translateY(-5px);
			    transform: translateY(-5px);
			}
			&:hover{
				text-decoration: none;
				color:darken($primary-color,15%);
				&:after{
					//height: 2px;
				    opacity: 1;
				    -webkit-transform: translateY(0px);
				    //-moz-transform: translateY(0px);
				    transform: translateY(0px);
				}
			}

		}
	}
	@media #{$medium-up} {
		&.medium-block-grid-2{
			li{
				float: left;
			}
		}
	}
	.site-menu & {
		//min-height: 360px;
		li{
			font-size:rem-calc(16);

			&.destacat-submenu{
				display: none;
			}
		}
	}

}

.equip{
	margin: vr(1.5) 0;
	ul{
		@include block-grid(1);
		@media #{$medium-up} {
			@include block-grid(2);
		}

		li{
			position: relative;
			&:before{
				content: none;
			}
			&:after{
				content:'';
				background-color:  #979797;
				height: 1px;
				width:100%;
				width: calc(100% - 25px);
				bottom: .75rem;
				display: block;
				position: absolute;
				@media #{$medium-up} {
					width: calc(100% - 25px);
				}
			}
		}
	}
}
//aside
.aside{
  background-color:$bg-color;
  position: relative;
  padding: vr(1.5) 15px;
  height: 100%;
  margin-top: rem-calc(30);
  .noticies-fitxa & {
  	@media #{$medium-up} {
  		margin: rem-calc(0);
  	}
  }
  @media #{$large-up} {
  	margin: rem-calc(0);
  }
  &:after {
  	@media #{$large-up} {
	  	content: '';
	    display: block;
	    height: 100%;
	    width: 1000%;
	    position: absolute;
	    top: 0;
	    right: -1000%;
	    background-color: $bg-color;
	}
  }
  @media #{$medium-up} {
    //margin:vr(-2) -15px 0;
    padding: rem-calc(35);
  }
  @media #{$xlarge-up} {
    //margin-left: vr(1);
  }
  a{
    @include undolink;
    color: $secondary-color;
    position: relative;
  }
  .more-info {
  	background-color: #fff;
  	padding: rem-calc(30 20 10);
  	margin-bottom: rem-calc(30);
  	word-wrap: break-word;
  	h3 {
  		@include bold;
  		font-size: rem-calc(18);
  		color: #000;
  		letter-spacing: 0;
  		text-align: center;
  		margin-bottom: rem-calc(20);
  	}
  }
  h2 {
  	font-size: rem-calc(22);
  	@include light;
  	letter-spacing: 0;
  	text-transform: uppercase;
  	border-bottom: 1px solid $primary-color;
  	padding-bottom: rem-calc(10);
  	margin-bottom: rem-calc(20);

  }
  p {
					font-size: rem-calc(16);
					@include medium;
					color: $primary-color;
					letter-spacing: 0px;
					margin-bottom: rem-calc(8);
					&.loc {
						@include light;
						&:before {
							content: '';
							background-image: url('img/ico-loc.svg');
							width: rem-calc(18);
							height: rem-calc(25);
							display: inline-block;
							margin-right: 10px;
						}
					}
					&.hora {
						@include light;
						&:before {
							content: '';
							background-image: url('img/ico-clock.svg');
							width: rem-calc(20);
							height: rem-calc(20);
							display: inline-block;
							margin-right: 10px;
						}
					}
				}
  .docs, .share {
  	h3 {
  		@include light;
  		letter-spacing: 0;
  		font-size: rem-calc(18);
  		color: #000;
  		border-bottom: 1px solid $primary-color;
  		padding-bottom: rem-calc(10);
  		text-align: left;
  	}
  }
  .docs {
  	ul {
  		li {
  			position: relative;
  			padding-left: rem-calc(35);
  			margin-bottom: rem-calc(20);
  			&:before {
  				content: none;
  			}
  			&.link {
  				&:before {
  					content: '';
  					background-image: url('img/ico-link-not.svg');
  					display: inline-block;
  					width: 25px;
  					height: 26px;
  					background-color: transparent;
  					position: absolute;
  					top: 0;
  				}
  			}
  			&.doc {
  				&:before {
  					content: '';
  					background-image: url('img/ico-docs-not.svg');
  					display: inline-block;
  					width: 21px;
  					height: 25px;
  					background-color: transparent;
  					position: absolute;
  					top: 0;
  				}
  				a {
  					color: $primary-color;
  					span {
  						@include light;
  					}
  				}
  			}
  		}
  	}
  }

}

.seccions{
	.intro{
		margin-bottom: vr(2.5);
	}
	section{
		padding: vr(2.5) 0;
		margin: 0;
		a{
			@include undolink;
		}
		&:nth-child(even) {
			background-color: $bg-color;
			.icona{
				border-color: #fff;
			}
		}
		p{
			&.boto.big a{
				font-size:rem-calc(18);
				background-color: $primary-color;
				color: #fff;
				border-color: $primary-color;
			}

		}
		@media #{$medium-up} {
			h2{
				font-size:rem-calc(36);
				@include medium;
			}
			p{
				font-size:rem-calc(18);
				&.big{
					font-size:rem-calc(24);
				}
			}

			ul{
				li{
					font-size:rem-calc(18);

				}
			}
		}
		.equal{
			padding-top: 67px;
			padding-left: vr(3);
		}
		.icona{
			opacity:.5;
			width:50px;
			height: 50px;
			padding-top:8px;
			border-radius:100%;
			border:solid 5px #000;
			text-align:center;
			margin-bottom: vr(.3);
			svg{
				max-width: 25px;
				max-height: 25px;
			}
			@media #{$medium-up} {
				margin-bottom: vr(1);
				width:100px;
				height: 100px;
				padding-top:25px;
				svg{
					max-width: 50px;
					max-height: 50px;
				}

			}

		}
		ul.list-links-btn{
			margin: vr(1) 0 0;
			li{
				font-size:rem-calc(14);
				margin-bottom: 0;
			}
		}
	}
}
@media #{$medium-up} {
	.js {
		.enter-effect{
			//opacity: 0;
			visibility: hidden;
		}
		.anim{
			.enter-effect--1 {
				visibility: visible;
				opacity: 0;
			    animation: 0.8s ease 0s 1 normal forwards anim-up-fadeIn;
			    will-change: transform, opacity;
			    animation-fill-mode: both;
			    animation-delay: .3s;
			}
			.enter-effect--2 {
				visibility: visible;
				opacity: 0;
			    animation: 0.8s ease 0s 1 normal forwards anim-up-fadeIn;
			    will-change: transform, opacity;
			    animation-fill-mode: both;
			    animation-delay: .6s;
			}
		}

	}
}
@keyframes anim-up-fadeIn{
	0%
	{opacity:0;transform:translateY(50px)
	}
	100%
	{opacity:1;transform:translateY(0px)}
}
@keyframes anim-down-fadeIn{
    0%{
        opacity:0;
        transform:translateY(-50px)
    }
    100%{
        opacity:1;
        transform:translateY(0px)
    }
}
.urgencies {
	.bg--2 {
		padding: rem-calc(30);
	}
}
.colabora {
	.submenu-colabora {
		background-color: #677f0e;
		height: rem-calc(95);
		ul {
			list-style: none;
			padding-top: rem-calc(37);
			width: fit-content;
			margin: 0 -1em;
			@media only screen and (min-width: 360px){
				margin: 0 auto;
			}
			li {
				display: inline-block;
				padding-left: .5em;

				@media only screen and (min-width: 360px){
					padding-left: 1.2em;
					&:first-child{
						padding-left: 0;
					}
				}
				@media #{$medium-up} {
					padding-left: 2em;
				}
				&:before {
					content: none;
				}
				a {
					color: #fff;
					text-transform: uppercase;
					font-size: rem-calc(14);
					@include bold;
					text-shadow: none;
					box-shadow: none;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
.bg-green {
	background-color: rgba(200, 236, 66, 0.5);
	text-align: center;
	padding:rem-calc(40 0 30);
	h2 {
		@include light;
		font-size: rem-calc(35);
		margin-bottom: rem-calc(20);
		padding-bottom: rem-calc(5);
		position: relative;
		border-bottom: solid 1px #97a955;
	}
	h3 {
		@include bold;
		font-size: rem-calc(16);
		margin: rem-calc(30 0 0);
	}
	p {
		font-size: rem-calc(16);
	}

}
.campanyes {
	.destacat {
		background: #c05633;
		background-image: linear-gradient(-180deg, #FEC067 0%, #F77144 100%);
	}
	.data {
		font-size: rem-calc(18);
	}
	.intro {
		@include bold;
		text-transform: uppercase;
		font-size: rem-calc(23);
	}
	.destacat {
		color: #fff;
	}
	.presentacio {
		padding-bottom: rem-calc(30);
		text-align: left;
		font-size: rem-calc(16);
		p, ul, h2, h3, h4 {
			text-align: left;
		}
	}
	.text-complementari {
		padding: rem-calc(40 0);
		font-size: rem-calc(16);
		p, ul, h2, h3, h4 {
			text-align: left;
		}
	}
	.activitats {
		padding-top: rem-calc(40);
		padding-bottom: rem-calc(40);
		color: #000;
		background-color: rgba(254, 192, 103, 0.4);
		font-size: rem-calc(16);
		h2, h3, h4 {
			text-align: left;
			color: #000;
		}
		p, ul {
			text-align: left;
			color: #000;
		}
		a {
			@include undolink;
			color: #c05633;
			@include bold;
		}
		ul {
			li {
				&:before {
					content: '';
					background-color: #c05633;
				}
			}
		}
	}
	.rel-docs {
		padding-top: rem-calc(30);
	}
}
