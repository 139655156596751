@include typi-init;
body {
  background: $body-bg;
  color: $body-font-color;
  font-family: $body-font-family;
}

// headings
.main-content h1 {
  @include bold;
  font-size: rem-calc(35);
  margin-bottom: vr(1.5);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0;
  margin-top:  rem-calc(45);
  line-height: 40px;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .basic & {
    margin-top:  rem-calc(15);
  }
  .estructura & {
    margin-top: rem-calc(15);
    margin-bottom: rem-calc(25);
  }
  .noticias-ficha & {
    text-align: left;
    font-size: rem-calc(25);
    text-transform: none;
    margin-top: rem-calc(40);
    margin-bottom: rem-calc(20);
    line-height: rem-calc(30);
    @media #{$medium-up} {
      margin-top: 0;
      font-size: rem-calc(30);
      line-height: rem-calc(35);
    }
  }
  .donaciones & {
    @media #{$small-only} {
      margin-top: rem-calc(10);
    }
  }

  &.b{
    text-align: left;
    padding-bottom: vr(1);
    position: relative;
    text-transform: none;
    @media #{$medium-up} {
      margin-top: 0;
    }

    &:after{
      content: '';
      width:100px;
      height: 6px;
      position: absolute;
      left: 0;
      bottom: 0;

      background-color: $primary-color;
    }
  }
  &.c{
    padding:0;
    &:after{
      content: none;
    }
  }
  span{
    display: block;
    font-size: rem-calc(20);
  }
  .fitxa-news & {
    text-transform: none;
    @media #{$medium-up} {
      font-size:rem-calc(40);
    }
  }
}
h2 {
  margin-top: vr(1.5);
  margin-bottom: vr(1);
  font-size: rem-calc(23);
  letter-spacing: 1px;
  color: $primary-color;
  @include bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:first-of-type {
    margin-top: 0;
  }
  .basica & {
    text-transform: uppercase;
    text-align: left;
    @include bold;
    font-size: rem-calc(23);
    letter-spacing: normal;

  }
}
.main-content h3,
.t3 {
  @include bold;
  text-transform: uppercase;
  margin-top: vr(1.5);
  margin-bottom: vr(1);
  &:first-of-type {
    margin-top: 0;
  }
  .basic & {
    @include light;
    font-size: rem-calc(20);
    letter-spacing: 0.89px;
    color: #000;
  }
}
.main-content h4,
.t4 {
  @include typi("t4");
  @include light;
  text-transform: uppercase;
  margin-top: vr(1.5);
  margin-bottom: vr(0.5);
  .basic & {
    color: $primary-color;
    font-size: rem-calc(20);
    @include bold;
    text-transform: none;
  }
}

// mides textos
.smallest {
  @include typi("smallest");
}
.smaller {
  @include typi("smaller");
}
.small {
  @include typi("small");
}
.normal {
  @include typi("normal");
}
.big {
  @include typi("big");
  @include light;
}
.bigger {
  @include typi("bigger");
}
.biggest {
  @include typi("biggest");
}

p,
ol,
ul,
dl,
img,
figure {
  margin: 0 0 vr(1);
}

// dl
.dl-inline {
  width: 100%;
  overflow: hidden;
  dt {
    @include bold;
    float: left;
    clear: left;
    margin-right: vr(0.25);
  }
  dd {
    margin: 0;
  }
}
.dl-stacked {
  dt {
    @include bold;
    margin-top: vr(0.5);
    &:first-child {
      margin-top: 0;
    }
  }
  dd {
    margin-left: 0;
  }
  span {
    display: block;
  }
  p,
  ul,
  li {
    margin-bottom: 0;
  }
}
a {
  font-size: rem-calc(16);
  @include bold;
  color: $secondary-color;
  text-decoration: none;
}
// llistes
ol {
  padding-left: 0;
  list-style-position: outside;
  margin-left: vr(1.5);
  li {
    @include light;
    padding-left: vr(.7);
    position: relative;
    margin-bottom: vr(0.25);
    @include light;
    font-size: rem-calc(16);
  }
}
// level 1
main ul {
  list-style: none;
  margin-left: rem-calc(15);
  li {
    padding-left: vr(.7);
    position: relative;
    margin-bottom: vr(0.25);
    @include light;
    font-size: rem-calc(16);
    &:before {
      content: "";
      display: block;
      width: em-calc(7);
      height: em-calc(7);
      left: 0;
      top: 0.5em;
      position: absolute;
      border-radius: 50px;
      background-color: $primary-color;
      .activitats & {
        background-color: #fff;
      }
    }
    // level 2
    ul {
      @include typi("small");
      margin-top: vr(0.5);
      margin-bottom: vr(0.5);
      li {
        margin-bottom: vr(0.25);
        padding-left: vr(0.5);
        @include mediumitalic;
        &:before {
          content: "";
          display: block;
          width: em-calc(6);
          height: em-calc(6);
          left: 0;
          top: 0.5em;
          position: absolute;
          background-color: $secondary-color;
        }
        // level 3
        ul {
          @include typi("smaller");
          li {
            padding-left: vr(0.5);
            &:before {
              width: 5px;
              height: 5px;
              top: 0.4em;
            }
          }
        }
      }
    }
  }
}
p {
  font-size: rem-calc(16);
  @include light;
  line-height: rem-calc(23);
  color: $primary-color;
  margin-bottom: rem-calc(10);
  &.destac {
    font-size: rem-calc(22);
    @include semibolditalic;
    line-height: 26px;
    color: $primary-color;
    padding: rem-calc(20 0);
    margin-bottom: 0;
  }
  &.down {
    background-color: #e9eef1;
    padding: rem-calc(10);
    position: relative;
    &:before {
      content: '';
      background-image: url('img/ico-down.svg');
      display: inline-block;
      width: 21px;
      height: 25px;
      position: absolute;
      left: 10px;
    }
    a {
      color: $primary-color;
      padding: rem-calc(5);
      margin-left: rem-calc(25);
      margin-top: rem-calc(3);
      @include undolink;
      span {
        @include light;
      }
    }
  }
}
// llistat amb klanders circulars
main ul.circles {
  li {
    padding-left: vr(0.75);
    &:before {
      width: 7px;
      height: 7px;
      left: 0;
      top: 0.5em;
      position: absolute;
      background-color: $secondary-color;
      border-radius: 50%;
    }
  }
}
// llistat sense klanders
ul.simple {
  @include undolist;
}
// llistat stripped
.stripped-list {
  li {
    padding: vr(0.5) vr(1);
    &:nth-child(even) {
      background-color: #eaeaea;
    }
    &:before {
      display: none;
    }
    .col-wrap & {
      @media #{$medium-up} {
        margin: 0 vr(-1);
      }
    }
  }
}

::selection {
  color: #fff;
  background-color: $secondary-color;
}
main a::selection {
  text-shadow: none;
}

strong,
b {
  @include bold;
}
em,
i {
  @include mediumitalic;
}
em strong,
em b,
i strong,
i b,
strong em,
strong i,
b em,
b i {
  @include bolditalic;
}
abbr[title] {
  text-decoration: none;
  border-bottom: 0;
}

hr {
  height: 0;
  border: 1px solid $light;
  border-width: 1px 0 0;
  margin: vr(1.5) auto;
}

// links underline
a {
  @include link(
    $link-color,
    $shadow-color: #fff,
    $underline-color: $link-color,
    $underline-color-hover: $link-color
  );
  -webkit-font-smoothing: antialiased;
}
.lt-ie9 a {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
a[href^="tel"] {
  color: inherit;
  text-decoration: none;
}

// focus styles
:focus {
  outline: 3px solid $focus;
}

.dl-link {
  &:before {
    content: "";
    display: inline-block;
    vertical-align: baseline;
    margin-right: 8px;
  }
}
.lead {
  @include typi("big");
  margin-bottom: vr(2);
  &:before,
  &:after {
    content: "";
    display: block;
    width: vr(5);
    height: 1px;
    background-color: #979797;
    margin: 0 auto;
  }
  &:before {
    margin-bottom: vr(1);
  }
  &:after {
    margin-top: vr(1);
  }
}

// figure
figure {
  text-align: center;
  .quienes-somos & {
    text-align: left;
  }
  img {
    margin-bottom: 0;
  }
}
.figure-bg {
  @media #{$medium-up} {
    margin: vr(3) 0;
    // background-color: $tertiary-color;
  }
}
.figure-wrapper {
  @media #{$medium-up} {
    background-color: #fff;
  }
}
figcaption {
  font-size: rem-calc(14);
  @include lightitalic;
  text-align: center;
  padding: 5px 0 0 0;
  position: relative;
  .u-wider & {
    text-align: center;
    padding-left: $column-gutter;
    padding-right: $column-gutter;
    &:before {
      content: "";
      display: inline-block;
      position: static;
      left: auto;
      top: auto;
      margin-right: 5px;
      vertical-align: text-bottom;
    }
  }
}

// paràgraf destacat
.dest {
  @include typi("big");
  @include light;
  border: 1px solid #000;
  border-width: 1px 0;
  padding-top: vr(1);
  padding-bottom: vr(1);
}

// baixar arxiu destacat
.dl {
  background-color: #f4f4f4;
  padding: vr(0.75) vr(0.5);
  position: relative;
  border-bottom: 1px solid #979797;
  padding-left: rem-calc(60);
  &:before {
    content: "";
    position: absolute;
    margin-right: vr(0.5);
    // // @include sprite(icon-vincle-destacat);
    left: 15px;
    top: 15px;
  }
  a {
    border: 0;
    &:hover {
      text-decoration: underline;
    }
  }
}

// video embed
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  margin: 0 0 vr(0.75);
  height: 0;
  overflow: hidden;
  max-width: 100%;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

// descarregar arxiu
.arxiu {
  display: inline-block;
  @include typi("small");
  // @include bold;
  margin-bottom: vr(0.75);
  border: 0;
  &:hover {
    text-decoration: underline;
  }
  &:before {
    content: "";
    vertical-align: middle;
    margin-right: 8px;
  }
  // &--pdf {
  //   &:before {
  //     // // @include sprite(icon-bases-pdf);
  //   }
  // }
}

// llistat amb columnes
.cols-list {
  list-style: none;
  @media only screen and (min-width: 30em) {
    column-count: 2;
  }
}

// llistat simple, sense klander
.simple-list {
  margin-left: 0;
  li {
    padding-left: 0;
    &:before {
      display: none;
    }
  }
}

// llistat destacat
.dest-list {
  @include medium;
  li {
    padding-left: vr(1);
    &:before {
      // @include sprite('ico-llista');
      vertical-align: middle;
      left: 0;
      top: 0.45em;
      position: absolute;
      background-color: transparent;
    }
  }
}

// llistat amb filets
.bordered {
  margin-bottom: vr(2);
  li {
    border-bottom: 1px solid $light;
    margin-bottom: vr(0.5);
    padding-bottom: vr(0.5);
    // padding-left: 0;
    // &:before {
    //   display: none;
    // }
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
  a {
    &:hover {
      border-bottom: 1px solid;
    }
  }
}
.bordered-2 {
  @include typi("big");
  li {
    // border-bottom: 1px solid $light;
    margin-bottom: vr(0.5);
    padding-bottom: vr(0.5);
    padding-left: 0;
    &:before {
      display: none;
      padding-left: 0;
    }
  }
}

// llistat enllaços
.rel-links {
  margin-bottom: vr(1.5);
  border-bottom: 2px solid $primary-color;
  .aside & {
    margin-bottom: vr(1);
  }
}
// llistat caixetes
.box-list {
  margin-left: 0;
  li {
    display: inline-flex;
    border: 2px solid $primary-color;
    padding: rem-calc(20);
    min-height: rem-calc(150);
    max-width: rem-calc(260);
    position: relative;
    margin-right: rem-calc(15);
    &:before {
      content: '';
      background-image: url('img/ico-fletxa.svg');
      position: absolute;
      display: block;
      width: 29px;
      height: 20px;
      top: 75%;
      left: 80%;
      background-color: transparent;
    }
    a {
      @include undolink;
      color: $primary-color;
      display: block;
      @include medium;
      font-size: rem-calc(20);
    }
  }
}

.telf-list {
  margin-left: 0;
  li {
    text-transform: uppercase;
    @include medium;
    font-size: rem-calc(16);
    border-bottom: 1px solid rgba(56, 66, 84, 0.2);
    padding: rem-calc(7 0);
    color: $primary-color;
    @media #{$large-up} {
      font-size: rem-calc(18);
    }
    &:before {
      content: none;
    }
    a {
      display: block;
      @include semibold;
      @include undolink;
      color: $primary-color;
      @media #{$medium-up} {
        float: right;
      }
    }
    &:last-child {
      border-bottom: none;
    }
  }
}
.docs {
        margin-left: 0;
        li {
          padding-left: 0;
          &:before {
            content: none;
          }
          a {
            @include undolink;
            color: $primary-color;
            display: block;
          }
          span {
            display: block;
            font-size: rem-calc(14);
          }
          p {
            position: relative;
            margin-left: rem-calc(30);
            margin-bottom: rem-calc(10);
            .sub-1-col & {
              margin-left: 0 !important;
              &:after {
                content: '';
                background-image: url('img/ico-plus.svg');
                background-repeat: no-repeat;
                width: 22px;
                height: 22px;
                float: right;
                margin-top: rem-calc(-19);
              }
            }
          }
          &.pdf, &.doc {
            p {
              &:before {
              content: '';
              background-image: url('img/ico-down-blau.svg');
              background-repeat: no-repeat;
              width: 19px;
              height: 20px;
              display: block;
              position: absolute;
              left: -30px;
              top: 0px;
            }
            }
          }
          &.link {
            p {
              &:before {
              content: '';
              background-image: url('img/ico-link.svg');
              background-repeat: no-repeat;
              width: 20px;
              height: 20px;
              display: block;
              position: absolute;
              left: -30px;
              top: 0px;
            }
            }
          }
        }
      }
// llista horitzontal
.inline-list {
  // text-align: center;
  //font-size: 0;
  li {
    font-size: 1em;
    display: inline-block;
    margin: 0 vr(0.25) 0;
    vertical-align: middle;
    padding-left: 0;
    &:before {
      display: none;
    }
  }
  &--img {
    margin-left: 0;
    li {
      margin: 0 vr(0.5) vr(0.7);
      &.first {
        margin-left: 0;
      }
      &.last {
        margin-right: 0;
      }
    }
    // margin-bottom: 0;
    a {
      @include undolink;
      border: 0;
      opacity: 1;
      transition: opacity 0.2s;
      &:hover {
        border: 0 !important;
        opacity: 0.8;
        .tramits & {
          opacity: 1;
        }
      }
    }
    img {
      margin-bottom: 0;
      max-height: 180px;
    }
  }
  &--share {
    @media #{$medium-up} {
      li {
        margin-right: vr(0.4);
      }
    }
    @media #{$large-up} {
      li {
        margin-right: vr(0.5);
      }
    }
  }

  &--left {
    text-align: left;
    li {
      margin: 0 vr(1.5) 0 0;
    }
  }
}
.pfoto {
  @include lightitalic;
  font-size: rem-calc(15)!important;
  position: relative;
  top: -20px;
  margin-bottom: 20px;
  text-align: center !important;
}
// table
.table-container {
  width: 100%;
  overflow-y: auto;
  _overflow: auto;
  margin: 0 0 1em;
}
.table-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
  height: 14px;
}

.table-container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid #fff;
  background-color: rgba(0, 0, 0, 0.3);
}
table {
  margin-bottom: vr(1.5);
  text-align: center;
  width: 100%;
  background-color: #fff;
  overflow: auto;
  overflow-y: hidden;
  overflow-x: auto;
  width: 100% !important;
  //display: block;
  border-collapse: collapse;
  &.cal{
    display: table;
  }
  th,
  td {
    vertical-align: top;
  }
  thead {
    th {
      @include typi("small");
      // @include bold;
      padding: vr(0.5) vr(1);
      background-color: #f4f4e4;
      text-transform: uppercase;
    }
  }
  tbody {
    th,
    td {
      padding: vr(0.5) vr(1);
      border-bottom: 1px solid $light;
    }
    th {
      text-align: left;
    }
  }
}
th.subtitle {
  @include typi("small");
  padding: vr(0.5) vr(1);
  text-transform: uppercase;
  text-align: left;
  color: $primary-color;
  border-bottom: 1px solid $light;
}

// Table fitxa
.fitxa table {
  th,
  td {
    border-bottom: 0;
    padding: vr(0.5) vr(0.75);
  }
  th {
    background-color: #dadada;
  }
  tr:nth-child(even) {
    th {
      background-color: #e5e5e5;
    }
  }
  tr:nth-child(odd) {
    td {
      background-color: #f3f3f3;
    }
  }
  em {
    display: block;
    @include typi("small");
  }
}

// block-grid
[class*="block-grid"] > li {
  &:before {
    display: none;
  }
}

// intro text
.intro-text {
  color: #000;
  font-size: rem-calc(20);
  margin:0 0 vr(1.5);
  border-bottom: solid 1px $primary-color;
  padding-bottom: vr(1.5);
  @media #{$medium-up} {
    //margin-bottom: vr(3);
  }

}
.actualitat{
  h2{
    text-align: center;
    text-transform: uppercase;
    color: $primary-color;
    @include semibold;
    font-size: rem-calc(30);
    letter-spacing: .9px;
    margin-bottom: rem-calc(5);
     @media #{$medium-up} {
      margin-bottom: rem-calc(15);
    }
  }
}


// text més gran
// .big-txt,
.big-txt p {
  @include typi("big");
}

p.borders {
  @include medium;
  border: 1px solid #979797;
  border-width: 1px 0;
  padding: vr(0.5) 0;
}

.pullout {
  color: $primary-color;
  @include bold;
  @include typi("big");
  width: vr(10);
  &--right {
    @media #{$medium-up} {
      margin-right: vr(-5);
      margin-left: vr(1.5);
      float: right;
    }
  }
  &--left {
    @media #{$medium-up} {
      margin-left: vr(-5);
      margin-right: vr(1.5);
      float: left;
    }
  }
}
.content-central{
  p + h2, ul + h2{
    padding-top: vr(.75);
  }
}
.aside{
  h2{
    @include typi(normal);
    @include medium;
    margin-bottom: vr(.5);
  }
}
.tel{
  position: relative;
  padding-left: vr(1);
  //font-size: rem-calc(20);
  margin-bottom: vr(.5);

  strong{
    font-size: rem-calc(20);
    line-height: 1.2;

  }
  &:before{
    content: '';
    display: block;
    @include sprite('ico-tel');
    position: absolute;
    top: 0;
    left: 0;
  }
}

