.table-conv {
  position:relative;
  top: rem-calc(-25);
  table {
    thead {
      th {
        background-color: #366b8f;
        color: #fff;
        @include medium;
        padding: rem-calc(10);
        text-align: center;
        border-right: 1px solid $primary-color;
        &:first-child {
          text-align: left;
        }
      }
    }
    tbody {
      tr:nth-of-type(even) {
          background: transparent;
      }
      td {
        text-align: center;
        padding-top: rem-calc(15);
        border-bottom: 1px solid rgba(151, 151, 151, 0.55);
        border-right:  1px solid rgba(151, 151, 151, 0.55);
        &:first-child {
          text-align: left;
        }
        a {
          @include undolink;
          color: #000;
          text-decoration: none;
          font-size: rem-calc(16);
          @include medium;
          &:hover {
            text-decoration: underline;
          }
        }
        &.open {
          svg {
            fill: #5BAB39; 
          }
        }
        &.close {
          svg {
            fill: #db4b26; 
          }
        }
        &.select {
          background-color: rgba(210, 129, 39, 0.2);
        }
      }
    }
  }
}