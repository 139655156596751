// mixins

// Triangle
// --------
// We use this to create isosceles triangles
// - $triangle-size       - Used to set border-size. No default, set a px or em size.
// - $triangle-color      - Used to set border-color which makes up triangle. No default
// - $triangle-direction  - Used to determine which direction triangle points.
//                          Options: top, bottom, left, right
@mixin triangle($triangle-size, $triangle-color, $triangle-direction) {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: inset $triangle-size;
  @if $triangle-direction == top {
    border-color: $triangle-color transparent transparent transparent;
    border-top-style: solid;
  }
  @if $triangle-direction == bottom {
    border-color: transparent transparent $triangle-color transparent;
    border-bottom-style: solid;
  }
  @if $triangle-direction == left {
    border-color: transparent transparent transparent $triangle-color;
    border-left-style: solid;
  }
  @if $triangle-direction == right {
    border-color: transparent $triangle-color transparent transparent;
    border-right-style: solid;
  }
}

// visually hidden
@mixin visually-hidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

// underline on hover
@mixin hover {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

// treure klanders de llista
@mixin undolist {
  margin-left: 0;
  li {
    padding-left: 0;
    // margin-bottom: 0;
    &:before {
      display: none;
    }
  }
}

// fonts
@mixin bold {
  font-family: 'Montserrat', $font-family-sans-serif;
  font-weight: 700;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@mixin bolditalic {
  font-family: 'Montserrat', $font-family-sans-serif;
  font-weight: 700;
  font-style: italic;
}
@mixin mediumitalic {
  font-family: 'Montserrat', $font-family-sans-serif;
  font-weight: 500;
  font-style: italic;
}
@mixin medium {
  font-family: 'Montserrat', $font-family-sans-serif;
  font-weight: 500;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@mixin light {
  font-family: 'Montserrat', $font-family-sans-serif;
  font-weight: 300;
  font-style: normal;
}
@mixin lightitalic {
  font-family: 'Montserrat', $font-family-sans-serif;
  font-weight: 300;
  font-style: italic;
}
@mixin semibold {
  font-family: 'Montserrat', $font-family-sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin semibolditalic {
  font-family: 'Montserrat', $font-family-sans-serif;
  font-weight: 400;
  font-style: italic;
}

// Clearfix
// --------
@mixin cf {
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
}

// links underline
// ---------------
@mixin link(
  $link-color,
  $shadow-color,
  $underline-color,
  $underline-color-hover
) {
  color: $link-color;
  text-decoration: none;
  text-shadow: 1px 1px 0 $shadow-color, -1px 1px 0 $shadow-color,
    2px 0 0 $shadow-color, -2px 0 0 $shadow-color;
  box-shadow: inset 0 -1px 0 0 lighten($underline-color, 10%);
  transition: box-shadow 0.1s;
  &:hover {
    box-shadow: inset 0 -2px 0 0 lighten($underline-color-hover, 5%);
  }
}

// underline on hover
// ------------------
@mixin link-h(
  $link-color,
  $shadow-color,
  $underline-color,
  $underline-color-hover
) {
  color: $link-color;
  text-decoration: none;
  transition: box-shadow 0.1s;
  text-shadow: none;
  box-shadow: none;
  &:hover {
    text-shadow: 1px 1px 0 $shadow-color, -1px 1px 0 $shadow-color,
      2px 0 0 $shadow-color, -2px 0 0 $shadow-color;
    box-shadow: inset 0 -1px 0 0 lighten($underline-color-hover, 5%);
  }
}

// undo link underline
// -------------------
@mixin undolink {
  text-decoration: none;
  text-shadow: none;
  box-shadow: none;
  // transition: border-bottom-color .1s;
  // border-bottom: 1px solid transparent;
  &:hover {
    // border-bottom-color: inherit;
    text-decoration: underline;
    box-shadow: none;
  }
}
@mixin undolink-2 {
  text-decoration: none;
  text-shadow: none;
  box-shadow: none;
  transition: border-bottom-color 0.1s;
  border-bottom: 1px solid transparent;
  &:hover {
    border-bottom-color: inherit;
  }
}
