// Search mobile
.s-mobile {
  max-width: vr(10);
  margin: 0 auto vr(1.5);
  position: relative;
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
  }
  label,
  legend {
    @include visually-hidden;
  }
  input {
    padding: vr(.5) 0 vr(.5) 0;
    width: 100%;
    background: $primary-color;
    box-sizing: border-box;
    outline: 0;
    border-bottom:solid 1px #fff;
    color: #fff;
    
    &:focus{
      background: $primary-color;
    }
    &[type="search"] {
      appearance: none;
    }
  }
  ::-webkit-input-placeholder { /* Chrome */
  color: #fff;
  transition: opacity 250ms ease-in-out;
  }
  :focus::-webkit-input-placeholder {
    opacity: 0.5;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #fff;
    transition: opacity 250ms ease-in-out;
  }
  :focus:-ms-input-placeholder {
    opacity: 0.5;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #fff;
    opacity: 1;
    transition: opacity 250ms ease-in-out;
  }
  :focus::-moz-placeholder {
    opacity: 0.5;
  }
  :-moz-placeholder { /* Firefox 4 - 18 */
    color: #fff;
    opacity: 1;
    transition: opacity 250ms ease-in-out;
  }
  :focus:-moz-placeholder {
    opacity: 0.5;
  }
  button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0;
    padding: 0;
    text-align: center;
    background-color: transparent;
    img {
      margin: 0;
      width: rem-calc(24);
      height: rem-calc(22);
    }
  }
}

// Search desktop
.s-desktop {
  //display: none;
  margin-bottom: 0;
  @media #{$medium-up} {
    display: inline-block;
    vertical-align: middle;
    float: right;
    margin-left: vr(.5);
    .buscador & {
      float: none;
      text-align: center;
    }
  }
  .sticky-header & {
    display: none;
  }
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    //background-color: $primary-color;
  }
  legend,
  label {
    @include visually-hidden;
  }
  input[type="search"] {
    appearance: none;
  }
}
.s-publ {
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
  }
  legend, label {
    @include visually-hidden;
  }
}


[role="search"] label {
  display: inline-block;
  overflow: hidden;
  margin: 0;
  float: right;
  cursor: pointer;
  text-indent: -1000px;
  width: 0;
  height: 27px;
}

[role="search"] label img {
  margin: 5px;
  vertical-align: baseline;
}



[role="search"] input[type="search"] {
  border: none;
  background-color: rgba(56, 66, 84, 0.12);
  margin: 0;
  padding: rem-calc(0 10);
  width: 14em;
  height: 37px;
  transition: all .3s ease-in;
  box-sizing: border-box;
  position: absolute !important;
  top: 47px;
  right: 10px;
  z-index: 2;
  position: relative;
  @media #{$large-up} {
     top: 55px;

    }
  @include light;
  .buscador & {
    height: 40px;
    width: 300px;
    max-width: 300px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #979797;
    padding-left: rem-calc(16);
    display: inline-block;
    position: relative;
    top: 0;
    left: 10px;
    @media #{$large-up} {
       width: 360px;
       max-width: 360px;

    }
   
    @media #{$small-only} {
      max-width: 200px;
      display: block;
      left: 0;
    }
  }
}
select {
  height: 40px;
  width: 300px;
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #979797;
  display: inline-block;
  position: relative;
  padding-left: rem-calc(16);
  @media #{$large-up} {
    width: 360px;
  }
  @media #{$small-only} {
    max-width: 200px;
    margin-bottom: rem-calc(15);
  }
}
.buscador {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.85);
  padding: rem-calc(30);
  position: absolute;
  width: 100%;
  bottom: 0;
  @media #{$small-only} {
    position: relative;
  }
  h2 {
    color: #4a4a4a;
    font-size: rem-calc(23);
    text-transform: uppercase;
    @include bold;
  }
}
[role="search"] input[type="search"]:focus {
  outline: none;
  //background-color: #fff; /* Needed for non-JS */
}

input::-webkit-input-placeholder {
  color: #757575; /* a11y FTW! */
}

input:-ms-input-placeholder {
  color: #757575; /* a11y FTW! */
}

input::-moz-placeholder {
  color: #757575; /* a11y FTW! */
  opacity: 1; /* https://www.quirksmode.org/blog/archives/2015/12/styling_placeho.html */
}

[role="search"] button {
  margin: 0;
  border: none;
  padding: 5px;
  background: transparent;
    box-sizing: content-box;
  cursor: pointer;
  // transition: all .2s ease-in;
  float: right;
}

[role="search"] button:hover,
[role="search"] button:focus {
  outline: none;
}


[role="search"] button img {
  display: block;
  margin-bottom: 0;
  .buscador & {
    padding-left: rem-calc(7);
  }
  @media #{$large-up} {
    // height: auto;
  }
}



// @media all and (min-width: 24em) {

  
  [role="search"] label {
    text-indent: 0;
    width: auto;
  }

  [role="search"] input[type="search"]:focus ~ label,
  [role="search"].notempty label {
    //text-indent: -1000px;
    width: rem-calc(360);
  }

  [role="search"] input[type="search"] {
    width: rem-calc(360);
    padding: rem-calc(0 10);
  }
  
  [role="search"] button {
    width: auto;
    transition: opacity .3s ease-in;
    position: absolute;
    top: 50px;
    opacity: 1;
    height: 20px;
    right: 15px;
    @media #{$large-up} {
      top: 59px;
    }
  }
  

/* Llista resultats */
.list.cercador{
  article{
    border-bottom: 1px solid #ddd;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    ol {
      margin-left: 0;
      margin: 0 0 vr(.25);
      li{
        margin-left: 0;
        font-size: rem-calc(13);
        a{
          color: $body-font-color;
          @include undolink;

        }
        &:after {
          content: '>';
          padding-left: 3px;
        }
      }
    }
    h2{
      @include light;
      margin-bottom: vr(.35);
      a{
        text-decoration: none;
        border: none;
        @include undolink;
      }
    }
  }
}