body {
	overflow-x: hidden;
}
@media #{$small-only} {
	.main-content,
	.main-header,.main-footer  {
		.column,
		.columns {
			padding-left: $mobile-padding;
			padding-right: $mobile-padding;
		}
	}
	.row .row {
	  margin-left: -$mobile-padding !important;
	  margin-right: -$mobile-padding !important;
	}
}
// @media #{$large-up} {
// 	.sticky-header main {
// 		padding-top: rem-calc(175);
// 	}
// }
// .row.narrow {
// 	@media #{$small-only} {
// 		margin-left: -$mobile-padding*.5 !important;
// 		margin-right: -$mobile-padding*.5 !important;
// 	}
// 	@media #{$medium-up} {
// 		margin-left: -$mobile-padding*.25 !important;
// 		margin-right: -$mobile-padding*.25 !important;
// 	}
// 	.column,
// 	.columns {
// 		padding-left: $column-gutter*.5;
// 		padding-right: $column-gutter*.5;
// 		@media #{$medium-up} {
// 			padding-left: $column-gutter*.25;
// 			padding-right: $column-gutter*.25;
// 		}
// 	}
// }
.column,
.columns,
.row {
	&:focus {
		outline: none;
	}
}
@media #{$medium-up} {
	.collapse{
		.content-central{
			margin-bottom: vr(3);
		}
	}
}

.sidenav{

  .main-content > .row{
    position: relative;
    .sidenav{
      background: #f4f5f6;
      display: block;
      @media #{$medium-up} {
        position: relative;
        top: vr(-1.5);
        left: 0;
        max-width: 300px;
        display: block;
      }
      ul{
      	@include undolist;
      	//border-right: solid 1px #f7f7f7;
      	padding: vr(1);
      	li{
      		//@include typi('small');
      		margin-top: vr(1);
      		padding-bottom:  vr(1);
      		border-bottom: solid 1px #E0E0E0;
          a{
            color: $body-font-color;
            @include medium;
          }
      		&:last-child{
      			border: none;
      		}
          ul.dropdown {
            display: none;
            border-right: none;
            padding: rem-calc(0 10 0);
            margin-bottom: 0;
            li {
              margin-top: vr(.3);
              padding-bottom:  vr(.3);
              border-bottom: none;
              &:last-child{
                margin-bottom: 0;
              }
              a {
                color: $body-font-color;
                @include medium;
              }
              ul{
                padding: 0 vr(.25) 0 vr(1);
                display: none;
                a{
                  @include medium;
                }
              }
              &.active{
                ul{
                  display: block;
                }
              }
            }
          }
      		&.current, &.active {
            > a:before{
              content: '';
              background: #E0E0E0;
              position: absolute;
              height: 100%;
              left:-1.5rem;
              top:-.75rem;
              width: 4px;
              display: block;
            }
            ul.dropdown {
              display: block;
              li {
                &.active {
                  a.active {
                    color: $secondary-color;
                    &:before{
                      content: none;
                    }
                  }
                }
              }
            }
      		}
      		a{
      			@include undolink;
      		}

      	}
      }

    }
    @media #{$medium-up} {
	    .content-central{
	    	padding-left: 250px;
	    }
	}
  }
}
@media #{$large-up} {
  .main-content{
    .columns.text-central{
      padding-right: vr(2.5);
    }
  }
}
// .cols-2 {
// 	@media #{$medium-up} {
// 		column-count: 2;
// 		p {
// 			overflow: hidden; /* fix for Firefox */
// 			break-inside: avoid-column;
// 			-webkit-column-break-inside: avoid;
// 		}
// 	}
// }