.img-top {
  background-color: $secondary-color;
  position: relative;
  margin-bottom: vr(2);
  min-height: 230px;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  background-position: 50% 50%;
  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0,0,0,.1);
  }
  @media #{$medium-up} {
         background-position: 50% 30%;

  }
  @media only screen and (min-width: 1250px){
      background-size: 100%;
      //background-attachment: fixed;
      background-position: 0 0;
      .centre &, .nosaltres & {
        background-attachment: inherit;

      }
      .serveis &{
        background-position: 0 50%;
      }
      &--39{
        background-position: 0 60%;
      }
      &--43{
        background-position: 0 50%;
      }
      &--49{
        background-position: 0 20%;
      }
      &--50{
        background-position: 0 12%;
      }
      &--51{
        background-position: 0 12%;
      }
      &--54{
        background-position: 0 52%;
      }
      &--55{
        background-position: 0 38%;
      }
      &--56{
        background-position: 0 30%;
      }
      &--58{
        background-position: 0 38%;
      }
      &--59{
        background-position: 0 38%;
      }
      &--70{
        background-position: 0 33%;
      }
      &--71{
        background-position: 0 33%;
      }
      &--72{
        background-position: 0 50%;
      }
      &--73{
        background-position: 0 33%;
      }
      &--74{
        background-position: 0 33%;
      }
      &--137{
         background-position: 0 15%;
      }
      &--133{
        background-position: 0 15%;
      }
      &--134{
        background-position: 0 25%;
      }
      &--138{
        background-position: 0 15%;
      }
      &--141{
        background-position: 0 38%;
      }
      &--142{
        background-position: 0 38%;
      }
      &--145{
        background-position: 0 25%;
      }
      &--151{
        background-position: 0 10%;
      }
      &--144{
        background-position: 0 30%;
      }
      &--162{
        background-position: 0 0;
      }
      &--286{
        background-position: 0 25%;
      }
      &--serveis1{
        background-position: 0 30%;
      }
      &--serveis5{
        background-position: 0 30%;
      }
      &--serveis6{
        background-position: 0 15%;
      }
      &--serveis8{
        background-position: 0 50%;
      }
      &--serveis9{
        background-position: 0 30%;
      }
      &--serveis10{
        background-position: 0 30%;
      }
      &--serveis11{
        background-position: 0 65%;
      }
      &--serveis15{
        background-position: 0 20%;
      }
      &--serveis16{
        background-position: 0 40%;
      }
      &--serveis17{
        background-position: 0 50%;
      }
      &--serveis18{
        background-position: 0 50%;
      }
      &--serveis19{
        background-position: 0 50%;
      }
      &--serveis20{
        background-position: 0 62%;
      }
      &--serveis21{
        background-position: 0 50%;
      }
      &--serveis22{
        background-position: 0 55%;
      }
      &--serveis25{
        background-position: 0 80%;
      }
      &--serveis26{
        background-position: 0 30%;
      }
      &--serveis27{
        background-position: 0 50%;
      }
      &--serveis28{
        background-position: 0 50%;
      }
      &--serveis33{
        background-position: 0 40%;
      }
      &--serveis34{
        background-position: 0 40%;
      }
      &--serveis37{
        background-position: 0 20%;
      }
      &--serveis38{
        background-position: 0 50%;
      }
      &--serveis41{
        background-position: 0 25%;
      }
       &--serveis42{
        background-position: 0 40%;
      }


  }
  /*@media only screen and (min-width: 1365px){
      background-size: 115%;
      background-position: initial;
  }*/
  @media only screen and (min-width: 1700px){
    &.he-d-ingressar{
      background-position: 0 60%;
    }
    &.estic-embarassada {
      background-position: 50% -247px;
    }
  }
  .centre.portada-centre &, .nosaltres & {
      min-height: 100px;
      overflow: hidden;
      background-color: #fff;
      margin-bottom: vr(1);
      @media #{$small-only} {
        background: none!important;
      }

      .caption{
        background: #fff;
        padding:vr(.5) 0 0;
        color: #000;
        @include medium;
      }
  }
  .colabora & {
    min-height: rem-calc(400);
    position: relative;
    margin-bottom: 0;
    background-color: darken(#677f0e,10%);
    img{
      margin: 0;
    }
    .caption {
      min-height: 200px;

      padding: 0 0 vr(1);
      width: 100%;
      @media #{$medium-up} {
        background-color: rgba(0, 0, 0, .6);
        position: absolute;
        bottom: 0;
        padding: 0;
      }

      h1 {
        font-size: rem-calc(40);
        @include light;
        text-transform: none;
        text-align: left;
        position: relative;
        padding-left: rem-calc(35);
        line-height: 1.1;
        left:0;
        top:0;
        transform:none;
        margin-bottom: vr(1);
        @media #{$large-up} {
          position: absolute;
          padding-left: rem-calc(50);
          //top:0;
        }
        &:before {
          content: '';
          background-image: url('img/ico-cor.svg');
          height: 28px;
          width: 31px;
          display: block;
          position: relative;
          top: 38px;
          left: -40px;
        }
      }
      p {
        font-size: rem-calc(18);
        color: #fff;
        margin-top: 0;
        @media #{$medium-up} {
          column-count: 2;
          column-gap: 6%;
          font-size: rem-calc(16);
        }
        @media #{$large-up} {
          padding-top: rem-calc(30);
        }
      }
    }
  }
  .colabora.colabora-form & {
    min-height: rem-calc(200);
    @media #{$medium-up} {
      min-height: rem-calc(400);
    }
  }
  .multiple-centre.colabora & {
    @media #{$large-up} {
      min-height: rem-calc(625);
    }
  }
  /*@media only screen and (min-width: 740px){
     min-height: 150px;
  }*/
  @media #{$medium-up} {
    margin-top: vr(-1.5);
    min-height: 350px;
    //background-attachment: fixed;
    &.noimg{
      min-height: 200px;
    }
    .centre.portada-centre &, .nosaltres & {
      min-height: 560px;
      overflow: hidden;
      .caption{
        background: $secondary-color;
        background: rgba(0,82,140,.8);
        color: #fff;
        position: absolute;
        min-height: 560px;
        padding:vr(2);
        @media #{$large-up} {
          font-size: rem-calc(18);
          padding:vr(3) vr(2) vr(1.5);
          max-width: 450px;
        }
        @media #{$xlarge-up} {
          max-width: 450px;
          right: 0;
        }
        &.leftp{
          @media #{$xlarge-up} {
            max-width: 650px;
            left: 0;
          }
        }
        .programacio-visites{
          margin-top: vr(1);
          p{
            margin-bottom: 0;
            @include typi('small');
            span{
              display: block;
              font-size:rem-calc(18);
            }
          }
        }
        ul{
          @include undolist;
          margin: 0;
          li{
            line-height: 1.3;
            padding:0;
            margin: 0;
            display: none;
            &:first-child{
              display: block;
            }
          }
        }
      }
    }

  }
  @media #{$large-up} {
    min-height: 350px;
  }
  @media only screen and (min-width: 1200px){
    min-height: 400px;
  }
  @media only screen and (min-width: 1440px){
    min-height: 475px;
  }

  .nosaltres & {
    p {
      text-align: center;
    }
    .caption {
      @media #{$xlarge-up} {
          max-width: 450px;
          right: 0;
        }
    }
  }
  h1 {
    margin-bottom: 0;
    margin-top: 0;
    position: absolute;
    top:50%;
    left: 50%;
    transform:translate(-50%,-50%);
    color: #fff;
    @include typi('biggest');
    @include bold;
    max-width: 900px;
    width: 100%;
    padding: 0 15px;
    .nosaltres & {
      font-size: rem-calc(40);
      font-weight: normal;
      line-height: 1;
      top: -70px;
      @media #{$medium-up} {
        position: relative;
        top: auto;
        font-size: rem-calc(60);
      }
    }
  }
  h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: rem-calc(30);
    @include light;
    line-height: 1.2;
  }
}
.slider {
  //background-size: cover;
  position: relative;
  opacity: 0;
  @include single-transition();

  overflow: hidden;
  @media #{$medium-up} {
    //margin-top: vr(-1.5);

  }
  @media #{$large-up} {
   // max-height: 650px;
   // max-height: calc(100vh - 326px);
  }
  &.slick-initialized{
    opacity: 1;
  }
  img{
    //width: 100%;
  }
}
