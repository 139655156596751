.main-header {
  padding-top: vr(.5);
  height: auto;
  @media #{$medium-up} {
    //margin-bottom: vr(1.5);
    padding-top: vr(1);
  }
  &__tools {
      position: absolute;
      top: 0;
      right: 85px;
    @media #{$medium-up} {
      right: 20px;
    }
    @media #{$xlarge-up} {
      right: 0;
    }
    > div {
      display: inline-block;
      vertical-align: top;
    }
    .btns {
      position: relative;
      display: none;
      @media #{$medium-up} {
        display: block;
        
      }
      @media #{$large-up} {
        margin-top: rem-calc(10);
      }
      
      .xarxes {
        list-style: none;
        float: right;

        li {
          display: inline-block;
          padding: rem-calc(0 10);
          a {
            @include undolink;
            img {
              margin-bottom: rem-calc(10);
            }
          }
        }
      }
    }
    .search{
      padding-left: vr(1);
      display: none;
      @media #{$medium-up} {
        display: block;
      }
    }
    .sticky-header & {
      display: none !important;
    }
  }
}
.centre{
  .main-header {
    .gradient{
      opacity: 0.43;
      background-image: linear-gradient(-180deg, #007EB5 0%, #F5FAFC 70%, rgba(255,255,255,0.00) 100%);
      width: 100%;
      height: 50px;
      position: absolute;
      top: 0;
      @media #{$medium-up} {
        height: 100px;
      }
    }

  }
}

.site-title {
  text-align: center;
  font-size: 1rem;
  padding-bottom: vr(.25);
  margin-top: rem-calc(10);
  .home & {
    width: 260px;
    @media #{$large-up} {
      width: 321px;
    }
  }
  @media #{$medium-up} {
    //margin: 0 0 vr(1);
    padding-bottom: 0;
    float: left;
    margin-top: 0;
  }
  .sticky-header & {
    display: none;
  }
  a {
    @include undolink;
    display: block;
  }
  img {
    @media #{$small-only} {
      margin-bottom: rem-calc(10);
    }
    .home & {
      @media #{$small-only} {
        margin-bottom: rem-calc(10);
      }
    }
    @media #{$medium-only} {
      margin: rem-calc(5 0 30);
      .home & {
        margin: rem-calc(0 0 23);
      }
    }
  }
}
.logo-sticky {
  display: none;
  .sticky-header & {
    display: block;
    float: left;
    // margin-right: vr(1);
    margin-bottom: 0;
  }
}

.mobile-tools{
  padding-bottom: vr(.5);
  background-color: $primary-color;
  padding-top: rem-calc(30);
  text-align: center;
  @media #{$medium-up} {
    display: none;
  }
  .xarxes {
        list-style: none;
        li {
          display: inline-block;
          padding: rem-calc(0 10);
          a {
            @include undolink;
            &:hover {
              opacity: .6;
            }
          }
        }
      }
  .btns{
    text-align: center;
    margin-bottom: 0;
    .btn{
      color:#fff;
      min-width: 160px;
    }
    #traductor-mobile--btn[aria-expanded="false"]{
        border-color: #fff!important;
    }
  }
  .lang {
        display: inline-block;
        margin-left: 0;
        list-style: none;
        li {
          display: inline-block;
          margin-left: rem-calc(5);
          color: #fff;
          a {
            text-decoration: none;
            font-size: rem-calc(16);
            @include medium;
            box-shadow: none;
            text-shadow: none;
            color: #fff;
            &.active {
              color: $secondary-color;
            }
          }
        }
      }
}
.top-nav {
  background-color: $secondary-color;
  height: rem-calc(4);
}