.sub-nav {
  text-align: center;
  margin-bottom: vr(2);
  background-color: $area1-color;
  height: rem-calc(60);
  .convocatorias & {
    margin-bottom: rem-calc(15);
  }
  .innovacion & {
    background-color: rgba(255, 255, 255, 0.76);
  }
  @media #{$small-only} {
      height: auto;
    }
   @media #{$large-up} {
    background-color: rgba(219, 75, 38, 0.76);
  }
  .convocatorias & {
    background-color: transparent;
  }
  ul {
    @include undolist;
    text-transform: uppercase;
    @media #{$small-only} {
      text-align: center;
      padding-top: rem-calc(15);
      padding-bottom: rem-calc(15);
    }
  }
  li {
    display: inline-block;
    @media #{$small-only} {
      display: block;
      padding: 0;
    }
    padding: 0 vr(2);
    &:before {
      content: none !important;
    }
    &.active {
      a {
        border-bottom: 2px solid #fff;
        .convocatorias & {
          border-bottom: 1px solid #000;
          border-top: 1px solid #000;
        }
        .innovacion & {
          border-bottom: 2px solid #009b8b;
        }
      }
    }
  }
  a {
    @include undolink;
    @include bold;
    display: block;
    color: #fff;
    padding: vr(.8) 0 vr(.05);
    border-bottom: 2px solid transparent;
    @media #{$small-only} {
      font-size: rem-calc(14);
      padding: rem-calc(5 10);
      max-width: 150px;
      margin: 0 auto;
    }
    .convocatorias & {
      color: #000;
      @include medium;
      font-size: rem-calc(15);
      letter-spacing: 1.42px;
      padding: vr(.5) 0 vr(.5);
      @media #{$small-only} {
        padding: vr(.3) 0 vr(.3);
      }
      @media #{$large-only} {
        font-size: rem-calc(16);
      }
    }
    .innovacion & {
      color: #4a4a4a;
    }
    &:hover {
      border-bottom: 2px solid #fff;
      text-decoration: none;
      .convocatorias & {
        border-bottom: 1px solid #000;
        border-top: 1px solid #000;
      }
      .innovacion & {
        border-bottom: 2px solid #009b8b;
      }
    }
  }
}