.breadcrumb {
  @media #{$small-only} {
    display: none;
  }
  height: rem-calc(39);
  background-color: rgba(52, 192, 159, 0.2);
  padding: vr(.25) 0;
  color:#333;
  @media #{$medium-up} {
    //margin-bottom: vr(1.5);
    padding: vr(.25) 0;
  }
  a{
    @include undolink;
    color:$primary-color;
    line-height: 1;
    @include light;
    font-size: rem-calc(14);
    text-transform: uppercase;
    letter-spacing: 1.05px;
  }
  ol{
    font-size: rem-calc(14);
    margin: 0;
    @media #{$small-only} {
      padding-top: rem-calc(4);
    }
    li{
      display: inline-block;
      position: relative;
      margin-right: rem-calc(10);
      padding-top: rem-calc(3);
      &:after{
        content:'›';
        position: absolute;
        margin-left: rem-calc(5);
        top:3px;
      }
      &:first-child{
        display: inline-block;
      }
      &:last-child{
        > span{
          display: none;
          @media #{$medium-up} {
            display: inline-block;
          }
        }
        a span{
          display: inline-block;
        }
        a, span{
          @include semibold;
          line-height: 1;
          text-transform: uppercase;
          color: $primary-color;

        }
        span{
          padding-left: 3px;
        }
        &:after{
          content: none;
        }
      }
      a {
        color: $primary-color;
      }
    }
  }
}
.cercador {
  ol{
    font-size: rem-calc(14);
    margin: 0;
    @media #{$small-only} {
      padding-top: rem-calc(4);
    }
    li{
      display: inline-block;
      position: relative;
      margin-right: rem-calc(10);
      padding-top: rem-calc(3);
      &:after{
        content:'›';
        position: absolute;
        margin-left: rem-calc(5);
        top:3px;
      }
      &:first-child{
        display: inline-block;
        padding-left: 0px;
      }
      &:last-child{
        > span{
          display: none;
          @media #{$medium-up} {
            display: inline-block;
          }
        }
        a span{
          display: inline-block;
        }
        a, span{
          @include semibold;
          line-height: 1;
          text-transform: uppercase;
          color: $primary-color;

        }
        span{
          padding-left: 3px;
        }
        &:after{
          content: none !important;
        }
      }
      a {
        color: $primary-color;
      }
    }
  }
}

