.cookies {
    @include typi('small');
    background-color: #333;
    background-color: rgba(#333,.9);
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 10;
    padding-top: vr(1);
    padding-bottom: vr(1);
    // @media #{$medium-up} {
    //     padding-top: vr(2);
    //     padding-bottom: vr(2);
    // }
    p {
        margin-bottom: 0;
        color: #fff;
    }
    a {
        @include undolink;
        color: #fff;
        text-decoration: underline;
        white-space: nowrap;
        &:hover {
            text-decoration: none;
        }
    }

    .btn {
        @include typi('small');
        display: block;
        width: 10rem;
        margin: vr(1) auto 0;
        color: $primary-color;
        border: 0;
        background-color: #fff;
        text-decoration: none;
        &:focus,
        &:hover {
            color: #fff;
            background-color: $secondary-color;
        }
    }
}
