.news{
	.main-content{
		form{
			fieldset {
			    border: 0;
			    padding: 0;
			    margin: 0 0 vr(1.5);
			    &:focus {
			      outline: none;
			    }
			  }
			legend{
				display: none;
			}
			fieldset{
				border: none;
				padding: 0;
				input[type="text"],
				  input[type="email"],
				  input[type="search"],
				  input[type="number"],
				  select,
				  textarea {
				    // @include medium;
				    width: 100%;
				    padding: rem-calc(13) vr(.5);
				    background: #F4F4F4;
				    border: solid 1px #979797;
				    border-radius: 6px;
				    margin-bottom: vr(1.5);
				    &:focus {
				      background-color: darken(#f7f7f7,2%);
				    }
				    &.error {
				      border: 1px solid red;
				    }
				    margin: 0;
				  }
				  select {
				    padding: rem-calc(13) vr(.5);
					background: #F4F4F4;
					border-radius: 6px;
					height: rem-calc(50);
					margin: 0;
				  }
			}
		}
	}
	article{
		margin-top: vr(1);
		//border-bottom: solid 1px $primary-color;
		position: relative;
		padding-bottom: vr(2);
		min-height: 175px;
		time{
			padding-right: vr(.5);
		}
		p{
			margin-bottom: 0;
			&.metas{
				font-size: rem-calc(14);
			}

		}
		figure{
			margin: 0 0 vr(.45)!important;
			max-height: 150px;
			overflow: hidden;
			@media #{$medium-up} {
				margin: 0!important;
			}

		}
		h2{
			font-size: rem-calc(20);
			margin: vr(.1) 0 vr(.25);
			line-height: 1.3;
			a{
				color: $body-font-color;
				@include undolink;

			}
		}
	}

}

/*.noticia-dest {
	text-align: center;
	color: #fff;
	.noticies.fitxa & {
		color: #000;
	}
	time {
		@include typi('small');
		display: block;
		margin-bottom: vr(.5);
		.noticies.fitxa & {
			color: $primary-color;
		}
	}
	h1,h2 {
		@include black;
		margin-bottom: vr(1) !important;
		text-transform: none;
		letter-spacing: 0;
		a {
			@include undolink;
			color: #fff;
		}
	}
}
.news-item {
	border-bottom: 1px solid $light;
	padding: vr(1.5) vr(1);
	.home & {
		padding: 0;
		margin-bottom: vr(1);
		border-bottom: 0;
	}
	time {
		@include typi('small');
		display: block;
		margin-bottom: vr(.5);
		color: $primary-color;
	}
	h2,h3 {
		@include black;
		@include typi('big');
		margin-bottom: vr(.5);
		text-transform: none;
		a {
			@include undolink;
			color: #000;
		}
	}
	>*:last-child {
		margin-bottom: 0;
	}

	&--dest {
		text-align: center;
		padding-top: 0;
		p {
			@media #{$medium-up} {
				margin-left: vr(5);
				margin-right: vr(5);
			}
		}
	}
}*/