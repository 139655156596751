.galleries{
  margin-bottom:2em;
  li{
      padding-bottom:0;
      margin-bottom: rem-calc(5) !important;
    }
 a {
    display: block;
    text-shadow: none;
    box-shadow: none;
    
    .wrapper {
        position: relative;
        max-width: rem-calc(450);
        .info {
          h3 {
              font-size:2em;
            }
        }
        img {
          margin: 0;
        }
        .num-img {
          position: absolute;
          bottom:12px;
          right:12px;
          z-index:3;
          display:none;
          @media #{$medium-up} {
              display:block;
          }
          p {
            color:#fff;
            margin:0;
          }
        }
        .descr {
          height: 100%;
          left: 0;
          padding:18% 1em 1em;
          position: absolute;
          top: 0;
          width: 100%;
          //z-index: 2;
          text-align:center;
          display:block;
          background-color: rgba(0, 0, 0, 0.4);
          @media #{$medium-up} {
              padding-top: 24%;
          }
          span {
          display:inline-block;
          color:#fff;
          font-size:1em;
          padding:.3em .6em;
          border:solid 1px #fff;
          margin-top: rem-calc(50);
          @media #{$medium-up} {
              font-size:1em;
              padding:.3em .6em;
              
          }
        }
        h3 {
          color:#fff;
          margin-bottom:0;
          font-size:2em;
          font-family: latoregular,"Helvetica Neue",Helvetica,Roboto,Arial,sans-serif !important;
        }
        p {
          color:#fff;
          font-size:1em;
          display:none;
          @media #{$medium-up} {
                display:block;
          }
        }
      }
    }
  }
}

  ul.galleries > li:nth-child(odd){
    padding:0;
  }
  ul.galleries > li:nth-child(even){
    padding:0;
  }
  
  ul.galleries a:hover .wrapper .descr{
    background-color: rgba(0, 0, 0, 0.65)!important;
  }

.pswp__img {
  height: auto !important;
}
[class*=block-grid-]>li {
  padding-bottom: 0;
}
main .galleries li {
  margin-bottom: 0 !important;
  @media #{$large-up} {
  margin-bottom: rem-calc(8) !important;
  }
}
.popup-gallery {
  text-align: center;
  figure {
    display: inline-block;
    width: rem-calc(260);
    padding: rem-calc(0 10);
  }
}
.mfp-figure {
  figcaption {
    &:before {
      content: none;
    }
  }
}