.main-footer {
  color: $primary-color;
  margin-top: vr(2);
  text-align: center;
  border-top: 10px solid $primary-color;
  padding: rem-calc(30 0);
  .actualitat &, .oferta &, .noticies &, .noticies-fitxa &, .agenda &, .revista &, .esporga &, .sub-caixes &, .sub-caixes-4 &, .contacte &, .organigrama &, .tramits & {
    margin-top: 0;
  }
  &--collapse {
    margin-top: 0;
  }
  a {
    color: $primary-color;
    @include undolink;
  }
  .collapse & {
    margin-top: 0;
  }

}

.footer-1 {
  overflow: hidden;
  h3 {
    text-transform: none;
    font-size: rem-calc(16);
    @include medium;
  }
  p {
    @include light;
    font-size: rem-calc(14);
    margin-bottom: rem-calc(10);
    color: $primary-color;
  }
}
.footer-nav {
  text-transform: uppercase;
  //padding-bottom: vr(1);
  margin-top: rem-calc(20);
  @include bold;
  @media #{$medium-up} {
    margin-top: rem-calc(40);
  }
  li {
    position: relative;
    @media #{$small-only} {
      display: block;
      margin-bottom: rem-calc(15);
    }
    &:before {
      @media #{$medium-up} {
        content: '-';
        display: block;
        position: absolute;
        left: -10px;
      }
    }
    &.first {
      &:before {
        content: none;
      }
    }
    a {
      color: $primary-color;
      font-size: rem-calc(15);
      @media #{$medium-up} {
        font-size: rem-calc(16);
      }
    }
  }
}
.footer-nav-1 {
  @media #{$medium-up} {
      display: none !important;
  }

}

