.is-hidden {
  @include visually-hidden
}
.is-visible {
  display: block;
}
.js .s-desktop.js-hidden {
  display: none; 
}
@media #{$small-only} {
  .js .menu-wrapper.js-hidden {
    display: none; 
  }
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.rounded {
  border-radius: $global-rounded;
  border-radius: 50%;
}
// Miscellaneous useful HTML classes
.left { float: left !important; }
.right { float: right !important; }
.clearfix { @include clearfix; }

// Hide visually and from screen readers
.hide {
  display: none;
}

// Hide visually and from screen readers, but maintain layout
.invisible { visibility: hidden; }

/*https://www.paciellogroup.com/blog/2012/08/notes-on-accessible-css-image-sprites/*/
.vh {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

// screen reader only
.sr {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

// ample de punta a punta
.u-wider {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}
// .full-width {
//   width: 100vw;
      //   width: 99.999vw
//   position: relative;
//   left: 50%;
//   right: 50%;
//   margin-left: -50vw;
//   margin-right: -50vw;
// }

// imatge ample de punta a punta
.u-full {
  width: 100%;
}