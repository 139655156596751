// mapa google
.map {
  text-align: center;
  img {
    width: 100%;
    margin-bottom: 0;
  }
}

// img dreta/esq
.img-right {
  img {
    @media #{$medium-up} {
      float: right;
      margin-left: vr(1);
    }
  }
}
.img-left {
  img {
    @media #{$medium-up} {
      float: left;
      margin-right: vr(1);
    }
  }
}
.text-int {
  padding-bottom: rem-calc(40);
}
/*.back {
  @include typi('small');
  display: inline-block;
  margin-bottom: vr(1);
  @include undolink;
  @media #{$medium-up} {
    position: absolute;
    right: $column-gutter*.5;
    top: vr(.5);
    margin-bottom: 0;
  }
  &:before {
    content: '\2190';
  }
}*/


// imatge amb text overlay
/*.img-txt {
  @media #{$medium-up} {
    position: relative;
    margin-bottom: vr(2);
    &--last {
      margin-bottom: 0;
    }
    img {
      margin-bottom: 0;
    }
  }


}
.txt-overlay {
  @media #{$medium-up} {
    padding: vr(2) 0;
    background-color: #fff;
    background-color: rgba(#fff, .7);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    .info-block {
      margin-bottom: 0;
    }
  }
}


// imatge intro top
.intro-img {
  margin-bottom: 0;
}


.more {
  margin-top: vr(1.5);
}
*/
.fitxa-equip {
  h1 {
    color: #fff;
    position: absolute;
    font-size: rem-calc(23);
    @include semibold;
    top: 290px;
    .negocis & {
      margin-bottom: 40px;
      position: relative;
      top: 20px;
      text-align: center;
      margin-top: 0;
      color: $primary-color;
      @media #{$medium-up} {
        margin-bottom: 0;
        position: absolute;
        top: 220px;
         right: 30px;
         color: #fff;
         
      }
      @media #{$large-up} {
        top: 300px;
        font-size: rem-calc(26);
      }
      @media #{$xlarge-up} {
        top: 340px;
      }
      &.no-slide {
        margin-bottom: 40px;
        position: relative;
        top: 20px;
        text-align: center;
        margin-top: 0;
        color: $primary-color;
      }
    }
  }
  .equip-dades {
    background-color: $primary-color;
    padding: rem-calc(15);
    div#gmap-equip {
      width: 100%;
      height: 300px;
      text-align: center;
      font-size: .75em;
      line-height: 120%;
      padding: 0;
      overflow: hidden;
      }
    .maps {
      margin: rem-calc(15 0);
       @media #{$medium-up} {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
     
    img {
      margin-bottom: 0;
    }
    p {
      color: #fff;
      font-size: rem-calc(16);
      margin-bottom: rem-calc(14);
      @include medium;
      @media #{$large-up} {
        font-size: rem-calc(18);
      }
      span {
        @include bold;
      }
      &.loc {
        position: relative;
        padding-left: rem-calc(30);
         @media #{$small-only} {
            margin-top: rem-calc(15);
          }
        &:before {
          content: '';
          background-image: url('img/ico-loc-blanc.svg');
          background-repeat: no-repeat;
          display: block;
          width: 15px;
          height: 20px;
          position: absolute;
          left: 0px;
        }
      }
      &.telf {
        position: relative;
        padding-left: rem-calc(30);
        a {
          @include undolink;
          color: #fff;
          text-decoration: underline;
        }
        &:before {
          content: '';
          background-image: url('img/ico-phone-blanc.svg');
          background-repeat: no-repeat;
          display: block;
          width: 21px;
          height: 21px;
          position: absolute;
          left: 0px;
        }
      }
      &.mail {
        position: relative;
        padding-left: rem-calc(30);
        a {
          @include undolink;
          color: #fff;
          text-decoration: underline;
        }
        &:before {
          content: '';
          background-image: url('img/ico-mail.svg');
          background-repeat: no-repeat;
          display: block;
          width: 21px;
          height: 21px;
          position: absolute;
          left: 0px;
        }
      }
      &.horari {
        position: relative;
        padding-left: rem-calc(30);
        &:before {
          content: '';
          background-image: url('img/ico-horari.svg');
          background-repeat: no-repeat;
          display: block;
          width: 20px;
          height: 20px;
          position: absolute;
          left: 0px;
        }
      }
      &.calendar {
        position: relative;
        padding-left: rem-calc(30);
        &:before {
          content: '';
          background-image: url('img/ico-calendar.svg');
          background-repeat: no-repeat;
          display: block;
          width: 20px;
          height: 20px;
          position: absolute;
          left: 0px;
        }
      }
    }
  }
  .equip-text {
    padding: rem-calc(20);
    margin-top: rem-calc(15);
    img {
      display: block;
      margin: 0 auto;
      padding: rem-calc(0 20 20);
    }
  }

}
.fitxa-area {
  text-align: left;
  h1 {
    font-size: rem-calc(30);
    letter-spacing: 1.05px;
    @include semibold;
    text-align: left;
    color: $primary-color;
    margin-bottom: rem-calc(15);
    margin-top: rem-calc(20);
    @media #{$large-up} {
      font-size: rem-calc(35);
      margin-top: rem-calc(40);
    }
  }
  .area-dades {
    border: 2px solid $primary-color;
    margin-bottom: rem-calc(30);
    min-height: rem-calc(169);
    position: relative;
    overflow: hidden;
    word-break: break-word;
    img {
      display: none;
      @media #{$medium-up} {
        display: block;
        margin-bottom: 0;
        position: absolute;
      }
    }
    .dades-reg {
      display: inline-block;
      position: relative;
      top: 10px;
      @media #{$medium-up} {
        left: 200px;
      }
      @media #{$large-up} {
        top: 35px;
      }
      p {
        margin-left: rem-calc(15);
        @include bold;
        font-size: rem-calc(15);
        margin-bottom: rem-calc(5);
        @media #{$large-up} {
          font-size: rem-calc(18);
          letter-spacing: 1.26px;
          margin-left: rem-calc(30);
          margin-bottom: rem-calc(10);
        }
        span {
          @include medium;
          display: block;
          @media #{$large-up} {
            display: inline-block;
          }
          a {
            font-size: rem-calc(15);
            @media #{$large-up} {
              font-size: rem-calc(18);
              }
            }
        }
        &.regidor {
          //margin-top: rem-calc(34);
        }
      }
    }
  }
  .area-text {
    p {
      font-size: rem-calc(15);
      line-height: auto;
      @include light;
      @media #{$large-up} {
        font-size: rem-calc(16);
      }
      &.txtImgEsq {
        display: block;
        text-align: center;
        @media #{$large-up} {
          display: inline-block;
          float:left; 
          margin: 0 15px 10px 0;
        }
      }
      &.txtImgDr {
        display: block;
        text-align: center;
        @media #{$large-up} {
          display: inline-block;
          float:right; 
          margin: 0 0px 10px 15px;
        }
      }
    }
    h2 {
      @include semibold;
      color: $secondary-color;
      font-size: rem-calc(20);
      letter-spacing: 0;
      margin-bottom: rem-calc(15);
      letter-spacing: 0;
      @media #{$large-up} {
        font-size: rem-calc(23);
      }
    }
  }
  .accordion {
    margin-top: rem-calc(30);

    .accordion-navigation {
      padding-left: 0 !important;
      margin-bottom: rem-calc(20) !important;
    }
    a {
      @include undolink;
      height: auto;
      @media #{$medium-up} {
        height: rem-calc(84);
      }
      h2 {
        display: inline-block;
        @include bold;
        font-size: rem-calc(18);
        text-transform: uppercase;
        letter-spacing: 0;
        color: $primary-color;
        padding-left: rem-calc(15);
        padding-top: rem-calc(13);
        margin-bottom: rem-calc(5);
        max-width: 90%;
        @media #{$medium-up} {
          height: rem-calc(84);
          margin-bottom: rem-calc(15);
        }
        @media #{$large-up} {
          font-size: rem-calc(20);
          padding-top: rem-calc(10);
        }
      }
    }
    .accordion .accordion-navigation>a, .accordion dd>a { 
      background-color: #fff;
      border-bottom: 1px solid $primary-color;
      min-height: rem-calc(95);
      &:hover {
        background-color: $bg-color;
      }
    }
    li {
      &:before {
        content: none;
      }
      span {
        display: inline-block;
        background-image: url('img/ico-plus.svg');
        background-repeat: no-repeat;
        width: 22px;
        height: 22px;
        float: right;
        margin-top: 15px;
      }
      &.active {
        span {
          background-image: url('img/ico-minus.svg');
          width: 16px;
          height: 2px;
          margin-top: 25px;
        }
      }
    }
    .accordion-navigation>.content.active, .accordion dd>.content.active {
      background-color: rgba(230, 231, 234, 0.61);
      p {
        font-size: rem-calc(16);
        @include light;
        line-height: 23px;
        color: $primary-color;
      }
      img {
        margin: 0 auto;
        display: block;
        padding: rem-calc(15 0);
      }
      ul {
        margin: 0;
        li {
          margin-left: 0;
          @include light;
          font-size: rem-calc(16);
          &:before {
            content: '';
            //background-image: url('img/ico-llista.svg');
            background-repeat: no-repeat;
            display: block;
            width: 6px;
            height: 6px;
          }
        }
      }
    }
  }
}
main ul.accordion li.accordion-navigation {
  padding-left: 0 !important;
}
.llista-vincles{
  margin: vr(2.5) 0;
  ul{
    @include undolist;
    li{
      border-bottom: solid 1px #979797;
      padding-bottom: vr(1.5);
      margin-bottom: vr(1.5);
      &:last-child{
        border: none;
      }
      h2{
        font-size: rem-calc(18);
        margin-bottom: vr(.3);
      }

      a.btn{
        font-size: rem-calc(14);
        text-transform: none;
        color: #9d6813;
        border-color: #9d6813;
        background-color: #fff;
        margin: 0;
        margin-right: vr(1);
        outline: 0;
        &:hover,&:focus{
          background-color: #9d6813;
          color: #fff;
        }
      }

    }
  }
}
ul.faqs{
  @include undolist;
  background-color: #f7f7f7;
  border-top: solid 3px $primary-color;
  padding: vr(1);
  li{
    //border-bottom: solid 1px #ccc;
    padding: vr(1);
    background-color: #fff;

    &:last-child{
      border: none;
    }
    > strong:first-child{
      display: block;
      margin-bottom: vr(.5);
    }
  }

}
.sidenav--mobile{
  overflow: hidden;
  ul{
    text-align: left;
    border-top: solid 1px #fff;
    opacity: .95;
    margin: vr(.5) 0 vr(2);
    > li > label{
      margin-bottom: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      cursor: pointer;
      position: relative;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      z-index: 2;
      display: block;
      @include medium;
      &:after {
          content: '+';
          float: right;
          margin-top: 0;
          margin-right: 8px;
          -webkit-font-smoothing: antialiased;
          -webkit-transition: -webkit-transform .3s ease;
          transition: -webkit-transform .3s ease;
          transition: transform .3s ease;
          transition: transform .3s ease, -webkit-transform .3s ease;
      }
    }

    li{
       list-style: none;
       border-bottom: 1px solid $primary-color;
       a.simple{
        padding-top: 10px;
        padding-bottom: 10px;
        display: block;
        @include medium;
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: initial;
       }
       &:before{
        content: none;
       }

      ul{
        margin: 0;
        overflow: hidden;
        padding-top: 5px;
        padding-bottom: 16px;
        position: absolute;
        border: none;
        top: 100%;
        left: 0;
        width: 100%;
        visibility: hidden;
        z-index: -1;
        -webkit-transform: translateY(-100px);
        -ms-transform: translateY(-100px);
        transform: translateY(-100px);
        @include undolist;
        li{
          @include typi('small');
          border: none;
          margin: 0 vr(.5) vr(.5);
          a{
            @include undolink;
            color: $primary-color;
            @include light;
            font-size: rem-calc(14);
          }
        }
      }
      input[type=checkbox]{
        display: none;
      }

      input[type=checkbox]:checked ~ ul {
        //display: block
          position: static;
          visibility: visible;
          z-index: 1;
          -webkit-transform: none;
          -ms-transform: none;
          transform: none;
          -webkit-transition: -webkit-transform 300ms ease;
          transition: -webkit-transform 300ms ease;
          transition: transform 300ms ease;
          transition: transform 300ms ease, -webkit-transform 300ms ease;

      }
    }
    input[type=checkbox]:checked + label:after {
      -webkit-transform: perspective(1px) rotate(45deg) scale(1.08);
      -ms-transform: perspective(1px) rotate(45deg) scale(1.08);
      transform: perspective(1px) rotate(45deg) scale(1.08);
    }


  }
}

/* 4. Simple tab interface */
.js .tab-interface{
  opacity: 0;
  @include single-transition();
  height: 200px;

  &.init{
    opacity: 1;
    height: auto;
  }
}

.list-block-2{
  @include undolist;
  @media #{$medium-up} {
    @include block-grid(
      // This controls how many elements will be on each row of the block grid. Set this to whatever number you need, up to the max allowed in the variable.
      // Available options: 1-12 by default, and false.
      $per-row: 2,

      // This controls how much space is between each item in the block grid.
      // Use a variable or any pixel or em values.
      $spacing: 60px,

      // This controls whether or not base styles come through, set to false to leave out.
      $base-style: true
    );
  }

  li{
    >strong:first-child{
      display: block;
      font-size: rem-calc(20);
      margin-bottom: -10px;
    }
    padding-bottom: 24px;
    text-align: left;
  }
}

.list-block-3{
  @include undolist;
  @media #{$medium-up} {
    @include block-grid(
      // This controls how many elements will be on each row of the block grid. Set this to whatever number you need, up to the max allowed in the variable.
      // Available options: 1-12 by default, and false.
      $per-row: 3,

      // This controls how much space is between each item in the block grid.
      // Use a variable or any pixel or em values.
      $spacing: 60px,

      // This controls whether or not base styles come through, set to false to leave out.
      $base-style: true
    );
  }

  li{
    >strong{
      display: block;
      font-size: rem-calc(20);
      margin-bottom: -10px;
    }
    padding-bottom: 24px;
    text-align: left;
  }
}

.list-block-4{
  @include undolist;
  @media #{$medium-up} {
    @include block-grid(
      // This controls how many elements will be on each row of the block grid. Set this to whatever number you need, up to the max allowed in the variable.
      // Available options: 1-12 by default, and false.
      $per-row: 2,

      // This controls how much space is between each item in the block grid.
      // Use a variable or any pixel or em values.
      $spacing: 60px,

      // This controls whether or not base styles come through, set to false to leave out.
      $base-style: true
    );
  }
  @media #{$large-up} {
    @include block-grid(
      // This controls how many elements will be on each row of the block grid. Set this to whatever number you need, up to the max allowed in the variable.
      // Available options: 1-12 by default, and false.
      $per-row: 4,

      // This controls how much space is between each item in the block grid.
      // Use a variable or any pixel or em values.
      $spacing: 60px,

      // This controls whether or not base styles come through, set to false to leave out.
      $base-style: true
    );
  }
  li{
    >strong{
      display: block;
      font-size: rem-calc(20);
      margin-bottom: -10px;
    }
    padding-bottom: 24px;
    text-align: left;
  }
}
@supports (display: flex) {
  @media #{$medium-up} {
    .list-block-2{
        &.list-links-btn,&.llista_cards{
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: flex-start;
         justify-content: flex-start;
          flex-wrap: wrap;
          li{
            padding:rem-calc(10 30);
            flex: 1;
            flex-basis: 48%;
            max-width: 48%;
          }
        }
        &.llista_cards{
          margin:0 -2% 0 0;
          li{
            margin-right: 2%;
          }
        }
        &.list-links-btn{
          margin-left: 0;
          li{
            padding-left: 0;
            max-width: 50%;
          }
        }
    }
    .list-block-3{
        &.list-links-btn,&.llista_cards{
          display: -webkit-flex;
          display: flex;
         -webkit-justify-content: flex-start;
         justify-content: flex-start;
          flex-wrap: wrap;
          li{
            padding:rem-calc(10 30);
           flex: 1;
           flex-basis: 31%;
            max-width: 31%;
          }
        }
        &.llista_cards{
          margin:0 -2.33% 0 0;
          li{
            margin-right: 2.33%;
          }
        }
        &.list-links-btn{
          margin-left: 0;
          li{
            padding-left: 0;
            min-width: 33.33333%;
          }
        }
    }
    .list-block-4{
      &.list-links-btn,&.llista_cards{
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-start;
       justify-content: flex-start;
        flex-wrap: wrap;
        li{
          padding:rem-calc(10 30);
         flex: 1;
         flex-basis: 23%;
          max-width: 23%;
        }
        &.llista_cards{
          margin:0 -2% 0 0;
          li{
            margin-right: 2%;
          }
        }
        &.list-links-btn{
          margin-left: 0;

          li{
            padding-left: 0;
            min-width: 25%;
          }
        }
      }
    }
  }

}
.list-links {
  li{
    text-align: left;
    padding-left: vr(.75);
    @media #{$medium-up} {
      padding-left: vr(1.85);
    }
    &:before {
        content: "";
        display: block;
        width: .4375em;
        height: .4375em;
        left: auto;
        top: .5em;
        margin-left: -15px;
        position: absolute;
        background-color: #00528c;
    }
    a{
      @include undolink;
      border-bottom: solid 1px $primary-color;
      //color: $body-font-color;
      &:hover{
        text-decoration: none;
        border-color: $body-font-color;
        color: $body-font-color;
      }
    }

  }
}

.list-links-btn {
  margin-bottom: vr(1.5);
  @include undolist;
  li{
    text-align: center;
    margin-bottom: vr(1);

     a{
      @include undolink;
      //box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px 1px rgba(0,0,0,.07);
      box-shadow: 0 2px 2px 0 rgba(0,0,0,.15);
      border: 1px solid #f0f0f0;
      text-transform: none;
      color: $primary-color;
      letter-spacing: .01em;
      margin: 0;
      padding: vr(1);
      border-radius: 4px;
      display: block;
      text-decoration: none;
      @include single-transition();
      background: #fff;
      text-align: left;
      position: relative;
      height: 100%;
      width: 100%;


      &:hover{
        box-shadow: 0 4px 4px rgba($primary-color,.24), 0 0 4px rgba($primary-color,.12), 0 6px 18px rgba(43,133,231,.12);
        transform: translate3d(0,-2px,0);
        text-decoration: none;
      }
    }

  }
}

.llista_fons,.fons{
  padding: vr(1);
  background-color: #f7f7f7;
  a{
    @include undolink;
  }

}
ul.llista_fons{
  margin-bottom: vr(1.5);
}
.llista_cards{
  @include undolist;
  margin-top: vr(1.5);
  li{
    margin-bottom: vr(1.5);
    padding: vr(1.5);
    background-color: #e6e7ea;
    color: $primary-color;
    @media #{$medium-up} {
      display: inline-table;
      max-width: 48%;
    }
    &.first {
      @media #{$medium-up} {
        margin-right: rem-calc(15);
      }
    }
    a{
      display: block;
      @include undolink;
      @include medium;
      margin-bottom: vr(1);
      position: relative;
      text-transform: uppercase;
      color: $primary-color;
      &:hover{
        text-decoration: none;
        color: $secondary-color;
      }
      &.btn:hover{
        color: #fff;
      }
      &:after {
        bottom: -14px;
        content: "";
        height: 2px;
        left: 0;
        max-width: 60px;
        position: absolute;
        width: 100%;
        background-color: $primary-color;
      }

    }
    br + a{
      margin-top:vr(.4);
      &:after{
        content:none;
      }
    }
    &.block{
      padding: 0;
      a{
        display: block;
        padding: vr(1);
        color: $body-font-color;
        height: 100%;
        outline: 0;
        &:hover{
          color: $primary-color;
        }
        &:after{
          content: none;
        }
        &:focus{
          background-color: #f7f7f7;
        }

        svg{
          position: absolute;
          bottom: 15px;
          right: 15px;
        }
      }
    }
    h2,h3{
      font-size: rem-calc(20);
      text-transform: none;
    }
  }
  &--image{
    li{
      box-shadow: none;
      .text{
        padding: vr(1);
      }
      .image{
        height: 185px;
        width: 100%;
        overflow: hidden;
        &.b{
          height: 221px;
        }
        img{
          object-fit: cover;
          margin-bottom: 0;
          width:100%;
        }


      }
      &.block{
        a{
          padding: 0;
        }
      }

    }

  }
  .memories &{
    a{
      font-size: rem-calc(20);
    }
    h2{
      font-size: rem-calc(26);
    }
  }
}
.cards{
  li{
    text-align: left;
    a{

      position: relative;
      padding: vr(1) vr(1) vr(2.5);
      @media #{$medium-up} {
        min-height: 340px;
      }
    }
    img{
      max-height: 93px;
    }
    h3{
      @include typi('normal');
      color: $body-font-color;
      margin-bottom: vr(.25);
    }
    p{
      color: #767676;
      @include typi('small');
    }
    .icon-arrow{
      fill:$bg-color;
      position: absolute;
      right: vr(1);
      bottom: vr(1);
    }
  }
}
.list-links-btn2 {
  @include undolist;
  margin: vr(1);
  li{
    text-align: center;
    margin-bottom: vr(1);
     a{
      @include undolink;
      //box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px 1px rgba(0,0,0,.07);
      text-transform: none;
      color: $primary-color;
      letter-spacing: .01em;
      padding: vr(1);
      border-radius: 4px;
      display: block;
      text-decoration: none;
      @include single-transition();
      color: #fff;
      //min-height: 125px;
      @include bold;

      background-color: $primary-color;

      &:hover{

        box-shadow: 0 4px 4px rgba($secondary-color,.24), 0 0 4px rgba($secondary-color,.12), 0 6px 18px rgba(43,133,231,.12);
        background-color: $secondary-color;
        text-decoration: none;
      }
    }

  }
}
.llista_separadors{
  @include undolist;
  h2{
    font-size:rem-calc(18);
    margin-bottom: vr(.5);
  }
  p{
    font-size:rem-calc(14);
    max-width: 600px;
  }
  li{
    //border-bottom: solid 1px #efefef;
    margin-bottom: vr(1);
    padding-bottom: vr(1);
    position: relative;
    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      left:0;
      width: 100%;
      height: 1px;
      background-color: #efefef;
    }
    &:last-child{
      border-bottom: 0;
    }
  }
  &.medium-block-grid-2,&.list-block-2,&.list-block-3{
    li{
      &:after{
        width: 92%;
      }
    }
  }
}
ul.equip{
  li{
    a{
      @include undolink;
      color: $body-font-color;

    }
  }
}
.tab-interface{
  margin: 15px 0 0;
  position: relative;
  @media #{$medium-up} {
      margin: vr(2.5) 0 vr(2.5);
    }

  h3{
    text-transform: none;
  }
  ul.seccions{
    @include undolist;
    margin-bottom: vr(2);
    @media #{$medium-up} {
      margin-bottom: 0;
    }
    li{
      margin-bottom: vr(1);
      padding-bottom: 0;
      display: block;
      list-style: none;
      @media #{$medium-up} {
        display: inline-block;
        margin-bottom: 0;
        width: 19.5%;
      }
      a{
        background-color: #E6E6E6;
        //padding: vr(1);
        display: block;
        margin-right: 0.25em;
        @include undolink;
        width: 100%;
        height: 141px;
        text-align: right;
        text-transform: uppercase;
        padding-right: rem-calc(15);
        padding-left: rem-calc(10);
        @media #{$medium-up} {
          display: inline-block;
          margin-bottom: -6px;
        }
        @media #{$large-up} {
          font-size: rem-calc(21);
          max-width: 210px;
        }
        p {
          padding-top: rem-calc(50);
          font-size: rem-calc(21);
          @include bold;
          color: #4a4a4a;
          margin-bottom: 0;
          @media #{$medium-up} {
            position: absolute;
            right: 10px;
            left: 10px;
          }
          @media #{$large-up} {
            padding-top: rem-calc(60);
          }
        }
        span {
          @include medium;
          font-size: rem-calc(16);
          display: block;
          text-transform: none;
          line-height: rem-calc(20);
          @media #{$medium-up} {
            font-size: rem-calc(14);
            line-height: rem-calc(18);
          }
          @media #{$large-up} {
            font-size: rem-calc(16);
          }
        }
        &.b {
          font-size: rem-calc(16);
        }
        @media #{$medium-up} {
          &.a1 {
            background-image: url('img/img-tab-1.jpg');
          }
          &.a2 {
            background-image: url('img/img-tab-2.jpg');
          }
          &.a3 {
            background-image: url('img/img-tab-3.jpg');
          }
          &.a4 {
            background-image: url('img/img-tab-4.jpg');
          }
          &.a5 {
            background-image: url('img/img-tab-5.jpg');
          }
        }
      }
    }
  }
  .text{
    padding: vr(.5) 0 vr(1.5);
    @media #{$medium-up} {
      padding: vr(2) 0 vr(1.5);
    }
    .two-centre & {
      padding: 0;
    }
    h2{
      text-align: center;
      font-size: rem-calc(32);
      @include light;
      outline: 0;
      margin-bottom: vr(1);
    }
  }
  
  .bg{
    padding: vr(2.5) 0 vr(1.5);
    &--1{
      background: $bg-color;
    }
    &--2{
      background: #fff;
    }
    .row{
      max-width: rem-calc(980);
    }
  }

  .seccions a[aria-selected], .seccions  a[role="tab"]:focus, .seccions  a[role="tab"]:hover {
    border-bottom: 0;
    position: relative;
    color: #fff;
    outline: 0;
    text-decoration: none;
    p {
      color: #fff;
    }
    &.a1 {
      background-color: $area1-color;
      @media #{$medium-up} {
        background-image: url('img/img-tab-1-act.jpg');
      }
    }
    &.a2 {
      background-color: $area2-color;
      @media #{$medium-up} {
        background-image: url('img/img-tab-2-act.jpg');
      }
    }
    &.a3 {
      background-color: $area3-color;
      @media #{$medium-up} {
        background-image: url('img/img-tab-3-act.jpg');
      }
    }
    &.a4 {
      background-color: $area4-color;
      @media #{$medium-up} {
        background-image: url('img/img-tab-4-act.jpg');
      }
    }
    &.a5 {
      background-color: $area5-color;
      @media #{$medium-up} {
        background-image: url('img/img-tab-5-act.jpg');
      }
    }

  }
  a{
    @include undolink;
  }
  a[role="tab"]:focus {
    background: $bg-color;
  }



  [role="tabpanel"] {
    margin-top: 0;
    padding: 0 0 1.5em;
    .area-titol {
      h2 {
        color: #fff;
        text-transform: uppercase;
        font-size: rem-calc(25);
        letter-spacing: 0.75px;
        padding-top: rem-calc(16);
        margin-bottom: rem-calc(16);
      }
    }
    [class*="block-grid-"] {
      &.text-center {
        > li {
          //float: none;
          display: inline-block;
        }
      }
    }
    .responsables {
      li {
        position: relative;
        width: 300px;
        height: 300px;
        padding: 0;
        margin: rem-calc(0 15);
        @media #{$small-only} {
          margin-bottom: rem-calc(15);
        }
        .text-resp {
          position: absolute;
          background-color: rgba(255, 255, 255, 0.78);
          bottom: 0;
          width: 300px;
          text-align: center;
          padding: rem-calc(15);
          height: 90px;
          h4 {
            color: $area1-color;
            @include bold;
            font-size: rem-calc(18);
            text-transform: none;
            margin: 0;
          }
          p {
            font-size: rem-calc(16);
            line-height: 1.31;
          }
        }
      }
    }
    .grupos {
      background-color: rgba(216, 216, 216, 0.5);
      margin-top: rem-calc(40);
      padding: rem-calc(40 0);
      .box-grupos {
        li {
          @media #{$medium-up} {
            padding-left: 0;
          }
        }
        h4 {
          color: $area1-color;
          font-size: rem-calc(18);
          @include bold;
          text-align: left;
          margin-top: 0;
          text-transform: none;
          line-height: 20px;
        }
        p {
          text-align: left;
          font-size: rem-calc(16);
          color: #4a4a4a; 
          line-height: 18px;
          position: relative;
          margin-bottom: 0;
          bottom: 0;
        }
        svg {
          position: absolute;
          right: 20px;
          bottom: 15px;
        }
        a {
          padding: rem-calc(20);
          background-color: #fff;
          min-height: 174px;
          width: 100%;
          @include undolink;
          position: relative;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    &#section1 {
      .area-titol {
        background-color: $area1-color;
      }
    }
    &#section2 {
      .area-titol {
        background-color: $area2-color;
      }
    }
     &#section3 {
      .area-titol {
        background-color: $area3-color;
      }
    }
    &#section4 {
      .area-titol {
        background-color: $area4-color;
      }
    }
    p {
      text-align: center;
      font-size: rem-calc(16);
      color: #333;
    }
    h3 {
      text-align: center;
      text-transform: uppercase;
      font-size: rem-calc(25);
      @include bold;
    }
    &#section5 {
      .area-titol {
        background-color: $area5-color;
      }
    }
    @media #{$medium-up} {
      margin-top: 0;
    }
    
  }

  [role="tabpanel"][aria-hidden="true"] {
    display: none;
  }

  [role="tabpanel"]:focus {
    background: #eee;
    outline: thin dotted;
  }
}
div#gmap {
  width: 100%;
  height: 385px;
  text-align:center;
  font-size: .75em;
  line-height:120%;
  padding:0;
  overflow:hidden;
}
div#gmap img{max-width:none;}

.message{
  background-color: $bg-color;
  padding: vr(.5);
 max-height: 300px;
  margin: vr(4) 0 vr(3);
  @media #{$medium-up} {
    padding: vr(2);

  }
  > div{
    min-height: 300px;
    background-color: #fff;
    margin:vr(-3) auto 0;
    padding: vr(.5);
    box-shadow: 0 2px 2px rgba(0,0,0,.24), 0 0 2px rgba(0,0,0,.12);
    @media #{$medium-up} {
       max-width: 70%;
      padding: vr(2);
      margin:vr(-5) auto 0;
    }
    h1{
      font-size: rem-calc(18);
      padding-bottom: vr(.5);
      margin: 0 0 vr(.5);
       white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
      &:after{
        content: '';
        width:60px;
        background-color: $primary-color;
        height: 3px;
        display: block;
        margin:vr(.5) auto 0;
      }
    }
    p{
      font-size: rem-calc(20);
    }
    .btn{
      margin-bottom: 0;
    }
  }
}
.hg{
  @include undolist;
  li{
    img{
      display: block;
      margin-bottom: vr(.8);
    }
    span{
      display: block;
      padding-left: vr(1.5);
    }
    h2{
      @include typi('normal');
    }
    a{
      display: block;
      @include undolink;
      border-bottom: solid 1px #979797;
      padding-bottom: vr(1);

      position: relative;
      &:before {
          position: absolute;
          top: 50%;
          margin-top: -14px;
          right: 0;
          float: right;
          content: "\203A";
          color: $primary-color;
          opacity: 0;
          @include single-transition();
          font-size: rem-calc(24);
      }
      &:after{
        content: '';
        width: 0;
        height: 3px;
        background-color: transparent;
        position: absolute;
        bottom: -2px;
        left: 50%;
        @include single-transition();
      }
      &:hover{
        color: $primary-color;
        text-decoration: none;
        &:after{
          width: 100%;
          left: 0;
          background-color: $primary-color;
        }
        &:before {
            opacity: 1;
        }
      }

    }
    margin-bottom: vr(2);
    @media #{$large-up} {
      padding-right: vr(2.5);
    }
  }
}
.peu-foto{
  @include typi('smallest');
  margin-top: vr(-1.5);
}

.missatge{
  max-width: 550px;
  margin: 0 auto;
  .colabora & {
    text-align: center;
  }
  a{
    text-decoration: underline;
    .colabora & {
      text-decoration: none;
    }
  }
}
form + .missatge{
  margin-top: vr(3);
}

.dates-destacades{
  margin: 0 0 vr(2);
  @media #{$medium-up} {
    margin: vr(1) 0 vr(4);
  }
  .data-destacada{
    @media #{$medium-up} {
      position: relative;
      &:after{
        content: '';
        position: absolute;
        height: 100%;
        width: 3px;
        background-color: $primary-color;
        left: 50%;
        top:0;
      }
    }
    &:nth-child(even){
      background-color: $bg-color;
    }
    .columns{
      padding-top: vr(1);
      padding-bottom: 0;
      @media #{$medium-up} {
        padding-bottom: vr(1);
      }
    }
    .title{
      h3{
        @include light;
        @include typi('bigger');

        margin: 0;

        @media #{$medium-up} {
          &:after{
            content: '';
            top: 50%;
            margin-top: -12px;
            width: 17px;
            height: 15px;
            position: absolute;
            border-style: solid;
          }
          padding:0 vr(1.25);
        }
      }
    }


    .odd{
      @media #{$medium-up} {
        .title{
          text-align: right;
          h3{
            &:after{
              right: 10px;
              border-width: 8.5px 0 8.5px 14.7px;
              border-color: transparent transparent transparent $primary-color;
            }
          }
        }
      }
    }
    .even{
      @media #{$medium-up} {
        text-align: right;
        .title{
          text-align: left;
          h3{
            &:after{
              left: 10px;
              border-width: 8.5px 14.7px 8.5px 0;
              border-color: transparent $primary-color transparent transparent;
            }
          }
        }
        .text{
          text-align: right;
        }
      }
    }
  }
}
svg.fletxa{
  fill:$primary-color;
  width:18px;
  height: 18px;
}
.destacat {
  background-color: #c4e4f0;
  padding: rem-calc(30);
  font-size: rem-calc(18);
  a {
    text-shadow: none;
  }
  .events &, .campanyes & {
    p {
      margin-bottom: rem-calc(5);
    }
    margin-bottom: rem-calc(50);
    .data {
      margin-top: 0;
      font-size: rem-calc(20);
      @include bold;
    }
  }
}
.num-destacat {
    font-size: rem-calc(40)!important;
  }

.js{
  .llista-vincles .prova{
    display: none;
    p:last-child{
      margin-bottom: 0;
    }
  }
}

.colaboradors {
  margin-bottom: rem-calc(50);

  @media #{$medium-up} {
    //column-count: 2;

  }
  @media #{$large-up} {
    margin-top: rem-calc(30)!important;
  }
  .colab {
    text-align: left;
    &:before {
      content: none;
    }
    &.first {
       @media #{$large-up} {
        //padding-top: rem-calc(90);
      }
    }
    a {
      color: #000;
      box-shadow: none;
      text-shadow: none;
      border-bottom: solid 1px #979797;
      display: block;
      margin: rem-calc(0 0 15);
      padding-bottom: rem-calc(10);
      h4 {
        margin-top: 0;
        text-transform: none;
        font-size: rem-calc(16);
        @include bold;
        margin-bottom: 0;
      }
      p {
        font-size: rem-calc(14);
        margin-bottom: 0;
      }
    }

  .effect-sub {
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      left: 0;
      right: 100%;
      bottom: 0;
      background: #8cac17;
      height: 4px;
      -webkit-transition-property: right;
      transition-property: right;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
      }
  }
  .effect-sub:hover:before, .effect-sub:focus:before, .effect-sub:active:before {
    right: 0;
  }
  }
}
.fitxa-news {
  //@include light;
  //font-size: rem-calc(18);
  text-align: left;
}
.share {
  //display: none;

    display: block;
    text-align: left;
    margin-left: 0;
    margin-bottom: vr(2);
    //margin-top: rem-calc(30);
    padding-top: rem-calc(20);
    h2 {
      text-transform: uppercase;
      font-size: rem-calc(20);
      @include light;

    }
    ul {
      list-style: none;
      margin: 0;
    }
    li {
      padding-left: 0;
      display: inline-block;
      margin-right: rem-calc(15);
      vertical-align: middle;
      &:before {
        display: none;
      }
    }
    a {
      display: inline-block;
      opacity: 1;
      transition: opacity .1s;
      border: 0;
      text-shadow: none;
      box-shadow: none;
      &:hover {
        opacity: .8;
      }
    }

}
.intro {
  font-size: rem-calc(18);
}
.fitxa-news {
  .data {
    margin-top: rem-calc(50);
  }

}
.rel-docs {
    h2 {
      @include light;
      text-transform: uppercase;
      font-size: rem-calc(18);
      border-bottom: 1px solid $primary-color;
      padding-bottom: rem-calc(10);
      .campanyes & {
        border-bottom: 1px solid #c05633 !important;
      }
    }
    ul {
      li {
        background-color: #c4e4f0;
        padding: rem-calc(10 30);
        position: relative;
        .campanyes & {
          background-color: rgba(254, 192, 103, 0.4);
          text-align: left;
          padding: rem-calc(10 35);
        }
        &:before {
          content: '';
          background-image: url('img/ico-link.svg');
          display: block;
          width: 20px;
          height: 20px;
          position: absolute;
          background-color: transparent;
          top: 10px;
          left: 7px;
          .campanyes & {
            background-image: url('img/ico-link-taronja.svg');
            left: 10px;
          }
        }
        a {
          @include undolink;
          color: #00528C;
          .campanyes & {
            color: #c05633;
            @include bold;
          }
        }
      }
    }
  }
.no-js {
  #form-proves-mediques,#sort-news{
    display: none;
  }
}
.agenda {
  .alert-box {
    background-color: #fff;
    text-align: center;
    font-size: rem-calc(18);
    padding: rem-calc(20);
    margin-top: rem-calc(20);
    border-radius: 8px;
  }
}
.alert-box {
  @include medium;
  font-size: rem-calc(16);
  color: #000;
  padding: rem-calc(50);
}
#list{
  min-height: 350px;
}
.loading{
  position: absolute;
  top:2%;
  left:50%;
  margin-left:-19px;
}
.filter-bar{
  background-color: #f7f7f7;
  margin-bottom: vr(1);
  padding: vr(1) 0;
}
.professional{
  .fons{
    margin-bottom: vr(2);
    h1{
      margin-bottom: vr(.5);
    }
    p{
      margin-bottom: vr(.25);
    }
  }
  p.centre{
    margin-bottom: 0;
    @include bold;
  }
}

div.separacio{
  margin-top: vr(1.5);
  padding-top: vr(1.5);
  position: relative;
  p{
    margin-bottom: vr(.25);
  }
  &:before{
    content: '';
    position: absolute;
    top:0;
    height: 4px;
    background-color: $primary-color;
    width: 80px;
    left:50%;
    margin-left: -40px;
  }
}

.mfp-figure{
  figcaption{
    padding: 0;
  }
}
.com-contactar{
  .block-list.abs ul li:first-child a{
    font-size: rem-calc(20);
  }
  .llistat{
    margin-bottom: vr(2);
    li{
      .item-noimg{
        min-height: 100px;
      }
      a{
        color: $secondary-color;
        &:hover{
          color: #fff;
          h2{
            color: #fff;
          }
        }
      }
      h2{
        color: $secondary-color;
        text-transform: none;
        font-size: rem-calc(20);
        @include bold;
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: vr(.5);
      }
    }

  }
  .block-list.destacada{
    margin-bottom: vr(2);
    a{
      background-color: $secondary-color;
      display: block;

      @include undolink;
      color: #fff;
      @include single-transition();
      .text{
        padding: 0 vr(1);
        min-height: 70px;
        h2{
          font-size: rem-calc(20);
        }
      }
      &:hover{
        text-decoration: none;
        background-color: $primary-color;


      }

    }

  }
}
.table-responsive {
    overflow-x: auto;
    min-height: 0.01%;
    @media #{$small-only} {
      width: 100%;
      margin-bottom: 15px;
      overflow-y: hidden;
     -ms-overflow-style: -ms-autohiding-scrollbar;
      border: 1px solid #ddd;
    }
}
.fitxa-equip {
  .columns {
    padding: 0;
  }
}
.dl {
  background-color: #e6e7ea;
  margin: rem-calc(15 0);
  position: relative;
  &:before {
    content: '';
    background-image: url('img/ico-down-blau.svg');
    display: block;
    width: 19px;
    height: 20px;
    position: absolute;
    top: 17px;
    left: 25px;
  }
  a {
    @include undolink;
    color: $primary-color;
  }
}

.icon-forward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
}
.icon-forward .icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.icon-forward:hover .icon, .icon-forward:focus .icon, .icon-forward:active .icon {
  -webkit-transform: translateX(4px);
  transform: translateX(4px);
}

.sink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.sink:hover, .sink:focus, .sink:active {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}
.grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.grow:hover, .grow:focus, .grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.sweep {
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.sweep:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(52, 192, 159, 0.85);;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.sweep:hover, .sweep:focus, .sweep:active {
  color: white;
}
.sweep:hover:before, .sweep:focus:before, .sweep:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.sink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.sink:hover, .sink:focus, .sink:active {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}


[class*="block-grid-"] {
      &.text-center {
        > li {
          //float: none;
          display: inline-block;
        }
      }
    }