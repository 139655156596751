.top {
  transition: transform .2s;
  z-index: 2;
  border: 0;
  width: auto;
  display: block;
  margin: vr(1) auto;
  background-color: rgba(0,0,0,.5);
  padding: vr(1);
  @media #{$medium-up} {
    display: none;
    width: auto;
    height: auto;
    padding: vr(1) vr(4) vr(1) vr(1);
    position: fixed;
    right: $column-gutter*.5;
      right: 0;
    bottom: vr(1);
    background-color: #000;
    background-color: rgba(0,0,0,.5);
  }
  &:hover,
  &:focus {
    border: 0 !important;
  }
  img {
    margin-bottom: 0;
  }
}
