  .main-nav {
    background-color: $secondary-color;
    height: rem-calc(57);
    z-index: 10;
    @media #{$medium-up} {
      background-color: $secondary-color;
      .home & , .coneix &, .actualitat &, .sub-caixes-4 & {
        background-color: rgba(52, 192, 159, 0.85);
        position: relative;
      }
    }
    &.sticky {
      position: fixed;
      z-index:20;
      top: 0;
      width: 100%;
    }
  }
  .menu-button {
    background-color: $secondary-color;
    outline: 0;
    height: rem-calc(56);
    width: 100%;
    position: relative;
    right: 0;
    top: 0;
    .hamburger-box{
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -7px;
      margin-left: -13px;
    }
    // .js-menu-open & {
    //   top: rem-calc(60);
    //   @media #{$medium-up} {
    //     top: rem-calc(90);
    //   }
    // }
    @media #{$medium-up} {
      display: none;
    }
  }
  .site-menu {
    list-style: none;
    text-align: center;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    &:before,
    &:after {
      content: "";
      height: 1px;
      width: vr(10);
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.5);
      display: block;
      margin: 0 auto;
      @media #{$small-up} {
        display: none;
      }
    }
    &:before {
      margin-bottom: vr(0.75);
    }
    &:after {
      margin-top: vr(0.75);
    }

    margin-bottom: 0;
    padding: 0;
    

    @media #{$medium-up} {
      padding: 0;
    }
    li {
      @include single-transition();
      padding: rem-calc(5 0);
      margin: rem-calc(10 0);
      @include bold;
      font-size: rem-calc(16);
      letter-spacing: .18;
      @media #{$medium-up} {
        display: inline-block;
        padding: rem-calc(12 12);
        margin: 0;
      }
      @media #{$large-up} {
        display: inline-block;
        padding: rem-calc(11 28);
      }
      @media #{$xlarge-up} {
         padding: rem-calc(11 30);
      }
      &.active {
        a {
          border-bottom: 2px solid #fff;
        }
      }
    }
    a {
      @include undolink;
      padding-bottom: rem-calc(5);
      padding-top: rem-calc(7);
      color: #fff;
      outline: 0;
      font-size: rem-calc(13);
      border-bottom: 2px solid transparent;
      &:focus{
        border-bottom: 2px solid #fff;
      }
      &:hover {
        border-bottom: 2px solid #fff;
        text-decoration: none;
      }

      @media #{$medium-up} {
        display: block;
        &[aria-haspopup="true"]:hover {
          text-decoration: none;
        }
        &[aria-current="page"] {
          color: #fff;
        }
        .sticky-header & {
          padding-top: vr(0.25);
          padding-bottom: vr(0.25);
        }
      }
       @media #{$large-up} {
        font-size: rem-calc(15);
      }
      @media #{$xlarge-up} {
        font-size: rem-calc(16);
      }
      span {
        display: none;
        @media #{$medium-up} {
          display: inline;
        }
      }
    }
    // submenú
    div[aria-label="submenu"], div.submenu {
      display: none;
    }
  }
  // submenús
  // http://heydonworks.com/practical_aria_examples/#submenus
  @media #{$small-up} {
    [aria-haspopup="true"] {
      position: relative;
    }

    [aria-haspopup="true"] span {
      font-size: 0.75em;
      padding-left: 0.25em;
    }

    div[aria-hidden="true"] {
      display: none;
    }

    [aria-haspopup="true"] + div[aria-hidden="false"] {
      @media #{$medium-up} {
        margin-top: 0;
        display: block;
        position: absolute;
        min-width: 200px;
        left: 0;
        width: 100%;
      }
    }

    .site-menu {
      // submenu
      div[aria-label="submenu"], div.submenu {
        width: 22em;
        text-align: left;
        z-index: 10;
        background-color: $bg-color;
        padding: vr(1);
        text-transform: none;
        @include typi("small");
        width: 100%;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        @media #{$medium-up} {
          left: 0;
          position: absolute;
          top: 57px;
        }
        
        ul {
          column-count: 3;
          -webkit-column-count: 3; 
          -moz-column-count: 3; 
          @media #{$large-up} {
                column-count: 4;
                -webkit-column-count: 4; 
                -moz-column-count: 4; 
            }
        }
        li {
          margin-bottom: vr(0.5);
          padding: 0;
          display: block;
          -webkit-column-break-inside: avoid;
          -webkit-backface-visibility: hidden;
          -moz-page-break-inside: avoid;
          break-inside: avoid;
          -o-column-break-inside: avoid;
          -ms-column-break-inside: avoid;
          column-break-inside: avoid;
          page-break-inside: avoid;
          &.last {
            margin-bottom: 0;
          }

          a {
            @include undolink;
            color: $primary-color;
            padding: 0;
            border-right: 0;
            text-transform: uppercase;
            font-size: rem-calc(12);
            letter-spacing: .98px;
            border-bottom: 1px solid rgba(56, 66, 84, 0.15);
            display: block;
            padding-bottom: rem-calc(10);
            @media #{$large-up} {
                font-size: rem-calc(15);
            }
            &:hover{
              text-decoration: none;
              //border-bottom: 2px solid transparent;
              &:after{
                background-color: #fff;
                opacity: .7;
              }
            }

          }
          &.active {
            a.active {
              border-bottom: 1px solid rgba(56, 66, 84, 1);
            }
          }
        }
      }
    }
    .sticky-header .site-menu ul li {
      margin-bottom: vr(0.25);
    }
  }



@media #{$small-only} {
  .menu-wrapper {
      animation: .05s fade-in .1s linear forwards;
      background:$bg-color;
      bottom: 0;
      left: 0;
      opacity: 0;
      overflow: auto;
      margin-top: 70px;
      position: fixed;
      right: 0;
      top: 0;
      min-height: auto;
      overflow: hidden;
      display: none;
      z-index: 10;
      a {
        color: $primary-color;
      }
  }
  .mobile-tools {
    display: none;
    position: relative;
    z-index: 10;
    width: 100%;
  }
  .js-menu-open .menu-wrapper {
    display: block;
    position: relative;
     margin-top: -7px;
     z-index: 10;
  }
  .js-menu-open .mobile-tools {
    display: block;
  }
  .main-header{
    //position: absolute;
    width: 100%;
    //z-index: 20;
    background-color: #fff;
    top:0;
    max-width: 100%;

  }
  .main-content{
    //margin-top: rem-calc(30);
    @media #{$medium-up} {
      margin-top:70px;
    }
    .home & {
      margin-top: 0;
    }
  }
  /*body.js-menu-open{
    overflow: hidden;
  }*/

}
@keyframes fade-in{0%{opacity:0}100%{opacity:1}}


