
  .main-content > .row{
    position: relative;
    .sidenav{
      background-color: rgba(56, 66, 84, 0.13);
      display: block;
      @media #{$medium-up} {
        position: relative;
        //top: vr(-1.5);
        left: 0;
        max-width: 300px;
        display: block;
      }
      ul{
      	@include undolist;
      	//border-right: solid 1px #f7f7f7;
      	padding: vr(1);
      	li{
      		//@include typi('small');
      		margin-top: vr(.5);
      		padding-bottom:  vr(.5);
      		border-bottom: solid 1px rgba(56, 66, 84, 0.2);
           @media #{$large-up} {
                 margin-top: vr(1);
                 padding-bottom:  vr(1);
              }
          a{
            color: $primary-color;
            @include medium;
            font-size: rem-calc(14);
            @media #{$large-up} {
              font-size: rem-calc(16);
            }
          }
      		&:last-child{
      			border: none;
      		}
          ul.dropdown {
            display: none;
            border-right: none;
            padding: rem-calc(0 10 0);
            margin-bottom: 0;
            li {
              margin-top: vr(.3);
              padding-bottom:  vr(.3);
              border-bottom: none;
              &:last-child{
                margin-bottom: 0;
              }
              a {
                color: $body-font-color;
                @include medium;
              }
              ul{
                padding: 0 vr(.25) 0 vr(1);
                display: none;
                a{
                  @include medium;
                }
              }
              &.active{
                ul{
                  display: block;
                }
              }
            }
          }
      		&.current, &.active {
            > a:before{
              content: '';
              background: rgba(56, 66, 84, 0.2);
              position: absolute;
              height: 100%;
              left:-1.5rem;
              top:-.75rem;
              width: 4px;
              display: block;
            }
            ul.dropdown {
              display: block;
              li {
                &.active {
                  a.active {
                    color: $secondary-color;
                    &:before{
                      content: none;
                    }
                  }
                }
              }
            }
      		}
      		a{
      			@include undolink;
      		}

      	}
      }

    }
    @media #{$medium-up} {
	    .content-central{
	    	padding-left: 250px;
	    }
	}
  }
