.pantalles{
  .wrapper{
    width:1440px;height: 1140px;margin:0 auto;padding: 20px 0;
    .row{
      max-width: 100%;
    }
    h1{
      text-align:left;margin:0 0 10px;text-transform: none;font-size:40px;
      line-height: 1.1;
      span{
        font-size:50px;
      }
    }
    .telf{
      font-size:30px;

    }
    .header{
      margin-bottom: vr(.25);
    }
    .servei{
      .block{

        .columns{
          background-color: #E9EDF4;min-height: 80px;
          padding: 8px 15px;
          p{
            margin: 0;
            font-size: rem-calc(15);
          }
          &.col1{
            line-height: 62px;
            text-transform: uppercase;
          }
          &.col2{
            position: relative;
            p{
              position: absolute;
              top:50%;
              transform: translateY(-50%);
              line-height: 1.3;
            }
          }
        }
        &.cap{
           .columns{
            min-height: 24px;
          }
        }
      }
    }
  }
}