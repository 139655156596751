.btn, p.boto a {
  @include typi("btn");
  @include undolink;
  @include semibold;
  @include single-transition;
  display: inline-block;
  text-decoration: none;
  margin-bottom: vr(1);
  padding: vr(0.5) vr(1);
  text-transform: uppercase;
  text-align: center;
  background-color: transparent;
  border: 2px solid #fff;
  letter-spacing: .68px;
  color: #fff;
  position: relative;

  .slider & {
    color: #fff;
  }
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    border-color: transparent;
    //outline: 2px solid;
    background-color:#fff;
    color:$primary-color;
  }

  &.icon{
    padding-left: 38px;
    svg{
      fill: $primary-color;
      position: absolute;
      top:50%;
      margin-top: -12px;
      left:6px;
    }
    &:hover{
      svg{
        fill: #fff;
        animation: MoveUpDown 1s linear 1;
      }
    }
  }

  &--agenda {
    color: #ec6e24;
    border-color: #f39a00;
  }

  &--neg {
    color: #fff;
    border-color: #fff;
  }
  &--blau {
    background-color: transparent;
    color: $primary-color;
    border: 2px solid $primary-color;
    .actualitat & {
      @media #{$small-only} {
        margin-top: rem-calc(20)!important;
      }
    }
    &:hover {
      background-color: $primary-color;
      color: #fff;
    }
  }
  &--white {
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff;
    &:hover {
      background-color: $primary-color;
      color: #fff;
    }
  }
  &--solid {
    background-color: $primary-color;
    color: #fff;
    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
  }

  &--small {
    @include bold;
    @include typi("smaller");
    padding: vr(0.25) vr(0.75);
    background-color: #000;
    color: #fff;
    text-transform: none;
    letter-spacing: 0;
    border-radius: 0;
    &:hover,
    &:focus,
    &:active {
      background-color: #fff;
      color: #000;
    }
    .agenda-item & {
      margin-bottom: 0;
      border: 0;
    }
  }

  &--large {
    @media #{$large-up} {
      @include typi("big");
    }
  }
  &--destacat{
    background-color: #8F100F;
    border-color:  #8F100F;
    color:#fff;
    text-decoration: none;
    font-size: rem-calc(14);
    &:hover{
      background-color: darken(#8F100F,10%);
      text-decoration: none!important;
    }
  }
  .actualitat & {
    margin: 0 auto;
    display: block;
    max-width: 220px;
  }
}
.btn-cercar {
  margin: 0 auto;
  margin-top: rem-calc(20);
}
.colabora .main-content{
  .btn, p.boto a {
    color: #fff;
    background-color: #677f0e;
    &:hover{
      background-color: darken(#677f0e,15%);
    }
  }
}

@keyframes MoveUpDown {
  0%, 100% {
    top: 50%;
  }
  25% {
    top: 40%;
  }
  50% {
    top: 50%;
  }
  75% {
    top: 60%;
  }
}