.slider__overlay {
	background-color: rgba(#000,.425);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
.slick-dotted.slick-slider {
    margin-bottom: 0;
}
.slider {
	text-align: center;
	overflow: hidden;
	background-size: cover;
	background-position: 0 0;
	position:relative;
	color: $body-font-color;
	height: 290px;
	
	&.sticky-slider {
		margin-top: 0;
	}
	@media #{$medium-up} {
		height: 590px;
	}
	@media #{$large-up} {
			height: 760px;
		}
    div:not(:first-child) {
        display:none;
    }
    &.slick-initialized {
        div {
            display:block;
        }
    }

	// contenidor imatge
	.slick-slide {
		position: relative;
		color: $body-font-color;
		background-size: cover;
		background-position: 50% 0;
		height: 290px;
		@media #{$medium-up} {
			height: 590px;
		}
		@media #{$large-up} {
			height: 790px;
		}
		@media #{$xlarge-up} {

			
		}
		.overlay{
			height: 100%;
			width: 100%;
			position: absolute;
			background-color: rgba(#000,.5);
    		z-index: 1;
		}
	}

	// caption
	&__caption {
		width:auto;
		padding:rem-calc(20);
		position: relative;
		top: 125px;
		text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
		@media #{$medium-up} {
			text-align: right;
			color: #fff;
			background-image: url('img/back-slider.svg');
			background-repeat: no-repeat;
			position: absolute;
			width: 780px;
			height: 509px;
			padding:.5em 1em;
			//z-index: 1;
			right: 0;
			bottom: 0;	
			text-shadow: none;
			top: auto;
		}
		p.titol {
			@include light;
			font-size: rem-calc(35);
			margin-bottom: 0;
			margin-top: vr(.25);
			text-align: center;
			text-transform: uppercase;
			color: #fff;
			line-height: 38px;
			span {
				@include bold;
				display: block;
			}
			@media #{$medium-up} {
				text-align: right;
				line-height: 75px;
				font-size: rem-calc(70);
				margin-right: 20%;
				position: relative;
				top: 150px;
			}
		}
		.btn{
			max-width: 242px;
			color: #fff;
			border-color: #fff;
			position: relative;
			top: 5px;
			@media #{$medium-up} {
				color: #fff;
				border-color: #fff;
				margin-right: 20%;
				font-size: rem-calc(16);
				position: relative;
				top: 180px;
				&:after {
					content: '';
					background-image: url('img/ico-arrow.svg');
					background-repeat: no-repeat;
					width: 20px;
					height: 12px;
					display: inline-block;
					margin-left: rem-calc(8); 
				}
			}
			&:hover{
				background-color: #fff;
				color: $primary-color;
			}
		}

	}
}

// fletxes
.slick-prev,
.slick-next {
	margin-top: 0;
	width: 24px;
	height: 40px;
	z-index: 1000;
	display: none!important;
	@media #{$medium-up} {
		display: block!important;
	}
	&:before {
		content: '';
		display: block;
		font-size: 1rem;
	}
	&:hover,
	&:focus {
		&:before {
			opacity: .8;
		}
	}
}
.slick-prev {
    left: vr(1);
    z-index: 1;
    .js-menu-open & {
        z-index: 0;
    }
    &:before {
        //@include sprite('fletxa-esq-blanc');
    }
}
.slick-next {
	right: vr(1);
	&:before {
		//@include sprite('fletxa-dreta-blanc');
	}
}
.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
    opacity: .25 !important;
}


// botons navegació
.slick-dots {
	bottom: vr(.2);
	right: 0;
	//transform: translateX(-50%);
	//background-color: #000;
	//background-color: rgba(0,0,0,.4);
	display: block;
	width: auto;
	padding: vr(.25);
	li {
		vertical-align: middle;
		margin-left: vr(.25);
		margin-right: vr(.25);
		width: vr(.5);
		height: vr(.5);
		&:before {
			display: none;
		}
		button {
			padding: 0;
			width: vr(1);
			height: vr(1);
			opacity: .75;
			color:#000;
			&:before {
				width: vr(.5);
				height: vr(.5);
				left: 0;
				top: 0;
				@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
					/* IE10+ CSS styles go here */
					top: 11px;
				}
				.ie9 & {
					top: 11px;
				}
			}
		}
		&.slick-active button:before {
			color: $secondary-color;
		}
	}
}
.slider-equip {
	display: none;
	@media #{$medium-up} {
		display: block;
	}
	img {
		margin: 0;
	}
	
}
.slider-news {
	@media #{$large-up} {
		margin-bottom: vr(1) !important;
	}
	.slick-arrow {
		display: none !important;
		@media #{$large-up} {
			display: block !important;
			top: auto;
			bottom: -20px;
			z-index: 1;
		}
	}
	.slick-prev {
		left: 270px;
		&:after {
			content: '';
			width: 2px;
			height: vr(1.5);
			background-color: #000;
			display: block;
			right: -24px;
			bottom: -5px;
			position: absolute;
		}
		&:before {
		    //@include sprite('fletxa-esq');
		}
	}
	.slick-next {
		right: 270px;
		&:before {
			//@include sprite('fletxa-dreta');
		}
	}
	// .slick-dots {
	// 	border: 1px solid red;
	// 	bottom: -1.5rem;
	// }
}