.esporga {
  a {
    @include undolink;
    font-size: rem-calc(16)!important;
  }
  .passos {
    margin-left: 0;
    li {
      font-weight: 500;
      line-height: 32px;
      padding-left: 0;
      padding: rem-calc(3 20);
      display: block;
      border: 2px solid $primary-color;
      border-radius: 8px;
      position: relative;
      margin: rem-calc(20);
      opacity: .5;
      text-align: center;
      @media #{$medium-up} {
        margin: rem-calc(35);
        padding: rem-calc(3 14);
        display: inline-block;
      }
      @media #{$large-up} {
        margin: rem-calc(40);
        padding: rem-calc(3 16);
      }
      @media #{$xlarge-up} {
        margin: rem-calc(45);
        padding: rem-calc(3 20);
      }
      &:before {
        content: none;
      }
      &.vincle:hover{
        opacity: 1;
      }
      &:not(.pas3){
        &:after {
          @media #{$medium-up} {
            content: '';
            background-image: url('img/ico-arrow-esp.svg');
            background-repeat: no-repeat;
            width: 55px;
            height: 16px;
            display: block;
            position: absolute;
            top: 15px;
            right: -68px;
          }
          @media #{$large-up} {
            right: -73px;
          }
          @media #{$xlarge-up} {
            right: -80px;
          }
        }
      }
      span {
        font-size: rem-calc(23);
         @media #{$large-up} {
          font-size: rem-calc(25);
        }
        @include bold;
        margin-right: rem-calc(5);
        @media #{$large-up} {
          margin-right: rem-calc(10);
        }
      }
      &.last {
        &:after {
          content: none;
        }
      }
      a {
        @include undolink;
        color: $primary-color;
        font-size: rem-calc(16);
        @include medium;
        @media #{$large-up} {
          font-size: rem-calc(18);
        }
        &:hover {
          text-decoration: none;
        }
        span {
          font-size: rem-calc(23);
           @media #{$large-up} {
            font-size: rem-calc(25);
          }
          @include bold;
          margin-right: rem-calc(5);
          @media #{$large-up} {
            margin-right: rem-calc(10);
          }
        }
      }
      &.active {
        opacity: 1;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  h2 {
    @include light;
    text-align: center;
    font-size: rem-calc(25);
    letter-spacing: 1.65px;
  }
  .bread-zona {
    margin: 0 auto;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: -ms-fit-content;
    width: -o-fit-content;
    width: fit-content;
    color: $primary-color;
    li {
      display: inline-block;
      font-size: rem-calc(16);
      padding-left: 0;
      @include medium;
      @media #{$large-up} {
        font-size: rem-calc(18);
      }
      &:before {
        content: none;
      }
      &:after {
        content: '>';
        margin-left: rem-calc(10);
      }
      &.last {
        &:after {
          content: none;
        }
      }
    }
  }
  .zones {
    margin-left: 0;
    margin-bottom: rem-calc(100)!important;
    li {
      padding: rem-calc(20 0 5);
      border-bottom: 1px solid $primary-color;
      &:before {
        content: none;
      }
      h3 {
        @include bold;
        font-size: rem-calc(20);
        letter-spacing: 1.32;
        margin-bottom: 0;
      }
      p {
        text-align: left;
        font-size: rem-calc(16);
        line-height: 25px;
        @include light;
        
        @media #{$medium-up} {
          max-width: 85%;
        }
        span {
          @include bold;
        }
      }
    }
    .btn-verd {
      margin-bottom: rem-calc(10);
      display: inline-block;
        @media #{$medium-up} {
          top: -50px;
          float: right;
        }
      }
  }
  .zonesOK-err {
    padding: rem-calc(50);
    background-color: #fff;
    margin-top: rem-calc(20);
    margin-bottom: rem-calc(40) !important;
    border-radius: 8px;
    display: inline-block;
    p {
      font-size: rem-calc(20);
      @include semibold;
    }
  }
  form.formVerd .button,
  .btn-verd {
        @include undolink;
        border: 0;
        position: relative;
        color: #fff;
        background-color: $secondary-color;
        text-transform: uppercase;
        font-size: rem-calc(15);
        letter-spacing: 1px;
        border-radius: 8px;
        padding: rem-calc(5 15);
        &:hover {
          text-decoration: none;
          background-color: darken(#34c09f, 10%);
        }
      }
      .disabled {
        background-color: #9b9b9b;
         &:hover {
          background-color: #9b9b9b;
        }
      }
}