@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local('Montserrat Light'), local('Montserrat-Light'), url(../fonts/Montserrat/Montserrat-Light.ttf) format('truetype'), url(../fonts/Montserrat/Montserrat-Light.woff) format('woff'), url(../fonts/Montserrat/Montserrat-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Montserrat/Montserrat.eot);
  src: local('Montserrat'), local('Montserrat Regular'), local('Montserrat-Regular'), url(../fonts/Montserrat/Montserrat.eot?#iefix) format('embedded-opentype'), url(../fonts/Montserrat/Montserrat.ttf) format('truetype'), url(../fonts/Montserrat/Montserrat.svg#Montserrat) format('svg'), url(../fonts/Montserrat/Montserrat.woff) format('woff'), url(../fonts/Montserrat/Montserrat.woff2) format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url(../fonts/Montserrat/Montserrat-Medium.ttf) format('truetype'), url(../fonts/Montserrat/Montserrat-Medium.woff) format('woff'), url(../fonts/Montserrat/Montserrat-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(../fonts/Montserrat/Montserrat-Bold.ttf) format('truetype'), url(../fonts/Montserrat/Montserrat-Bold.woff) format('woff'), url(../fonts/Montserrat/Montserrat-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: local('Montserrat Black'), local('Montserrat-Black'), url(../fonts/Montserrat/Montserrat-Black.ttf) format('truetype'), url(../fonts/Montserrat/Montserrat-Black.woff) format('woff'), url(../fonts/Montserrat/Montserrat-Black.woff2) format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'), url(../fonts/Montserrat/Montserrat-Light-Italic.ttf) format('truetype'), url(../fonts/Montserrat/Montserrat-Light-Italic.woff) format('woff'), url(../fonts/Montserrat/Montserrat-Light-Italic.woff2) format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: local('Montserrat Italic'), local('Montserrat-Italic'), url(../fonts/Montserrat/Montserrat-Italic.ttf) format('truetype'), url(../fonts/Montserrat/Montserrat-Italic.woff) format('woff'), url(../fonts/Montserrat/Montserrat-Italic.woff2) format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'), url(../fonts/Montserrat/Montserrat-Medium-Italic.ttf) format('truetype'), url(../fonts/Montserrat/Montserrat-Medium-Italic.woff) format('woff'), url(../fonts/Montserrat/Montserrat-Medium-Italic.woff2) format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url(../fonts/Montserrat/Montserrat-Bold-Italic.ttf) format('truetype'), url(../fonts/Montserrat/Montserrat-Bold-Italic.woff) format('woff'), url(../fonts/Montserrat/Montserrat-Bold-Italic.woff2) format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'), url(../fonts/Montserrat/Montserrat-Black-Italic.ttf) format('truetype'), url(../fonts/Montserrat/Montserrat-Black-Italic.woff) format('woff'), url(../fonts/Montserrat/Montserrat-Black-Italic.woff2) format('woff2');
}

